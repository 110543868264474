import { formatDate } from "@angular/common";
export const DateTimeColumnFormatter = "yyyy/MM/dd HH:mm";
export const DateColumnFormatter = "yyyy/MM/dd";

export function PreprocessDateTime(dateTime: string | number | Date, formatter?: string, locale?: string): string {
  if (dateTime) {
    try {
      return formatDate(dateTime, formatter || DateTimeColumnFormatter, locale || "en-US");
    } catch {
      return dateTime.toString();
    }
  }
  return "";
}
