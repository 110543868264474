import { Component, OnInit, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ColumnApi, ExcelStyle, GridApi, GridOptions } from "ag-grid-community";
import { IAdminViewListRow, IEditModalOption, IStatusResponse, TableName, allowedCommonRegionStatuses } from "../../npr-request.model";
import { ApiService } from "../../services";
import { ModalService } from "../../shared/modal.service";
import { DefaultColumnDefinitionForAdmin } from "./ag-grid-column-definition";
import { CustomPageSizeSettingBarComponent } from "../../shared/auxillary-components/custom-pagesize-setting-bar.component";
import { CustomToolingPanel } from "../../shared/auxillary-components/custom-tool-panel.component";
import { CustomTooltipComponent } from "../../shared/auxillary-components/custom-tooltip.component";
import { DataTypes } from "./admin-view-data-types";
import { forkJoin } from "rxjs";
import { Region, Team, VmSku } from "src/app/generated-models";
import { GridViewBaseComponent } from "../../utility/grid-view-base.component";
import { SharedDataService } from "../../services/sharedDataService";
import { exportExcel, getExportedExcelFileNameSuffix } from "src/app/utility/common-helper";
import { map } from "rxjs/operators";

@Component({
  selector: "app-admin-view",
  templateUrl: "./admin-view.component.html",
  styleUrls: ["../../styles.scss"],
})
export class AdminViewComponent<T extends IAdminViewListRow> extends GridViewBaseComponent implements OnInit {
  @Input() options: T;
  @Input() mainTitle: string;
  @Input() subTitle: string;
  @Input() tableName: string;
  @Input() addRowFunc: (T) => Promise<IStatusResponse>;
  @Input() getDefaultValueFunc: () => any = () => [];
  @Input() updateRowFunc: (T) => Promise<IStatusResponse>;
  @Input() columnDef;
  @Input() deleteRowFunc: (T) => Promise<IStatusResponse>;

  type = "";
  gridOptions: GridOptions;
  rowData: T[] = [];
  newRow: T[] = [];
  initPageSize: 15;
  toggledGrouping = false;
  gridTheme = "Default";
  selectAllFlag = false;
  selectedRow: T;
  selectedRowsCount = 0;
  message = "";
  keyFilterStats: string;
  addRowEnable = false;
  deleteRowEnable = false;
  additionalMessage = "";
  isTeamConfigTable = false;

  public gridApi: GridApi;
  public columnApi: ColumnApi;
  headerStyle: ExcelStyle[] = [
    {
      id: "header",
      font: {
        bold: true,
      },
    },
  ];

  constructor(
    protected apiService: ApiService,
    protected modalService: ModalService,
    protected notificationService: ToastrService,
    protected sharedDataService: SharedDataService,
    protected dataTypes: DataTypes
  ) {
    super(apiService, sharedDataService);
  }

  ngOnInit(): void {
    this.isTeamConfigTable = this.tableName === TableName.TeamConfig;
    this.gridOptions = {
      context: this, // passed context for customized component callback
      // ag-grid.com/javascript-grid-context/
      frameworkComponents: {
        // register angular component for customized column header
        // https://www.ag-grid.com/javascript-grid-header-rendering/#example-header-component
        customTooltip: CustomTooltipComponent,
        customGridPageSizeSettingBar: CustomPageSizeSettingBarComponent,
        customToolingPanel: CustomToolingPanel,
      },
      rowSelection: "single",
      defaultColDef: DefaultColumnDefinitionForAdmin,
      columnDefs: this.columnDef,
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressSideButtons: true,
              suppressColumnFilter: true,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        position: "left", // not working in this version of ag-grid
      },
      suppressPropertyNamesCheck: true,
      suppressDragLeaveHidesColumns: true,
      suppressMakeColumnVisibleAfterUnGroup: true,
      // set this to true to remove single children
      groupRemoveSingleChildren: false,
      // set this to true to remove leaf level single children
      groupRemoveLowestSingleChildren: true,
      groupDisplayType: "groupRows",
      animateRows: true,
      // expand everything by default
      groupDefaultExpanded: 0,
      paginationPageSize: this.initPageSize,
      groupRowRendererParams: {
        innerRenderer: "groupRowInnerRenderer",
      },
    };
  }

  refreshData(): void {
    //
  }

  onGridReady(params: GridOptions): void {
    super.onGridReady(params);
    // Should load teams, regions and sku before get row data
    forkJoin([
      this.apiService.getTeamList(),
      this.apiService.getRegionList(true).pipe(map(regions => regions.filter(region => allowedCommonRegionStatuses.includes(region.Status)))),
      this.apiService.getVmSkus()]).subscribe(
        ([teams, regions, vmsku]: [Team[], Region[], VmSku[]]) => {
          this.dataTypes.initTeamRegion(teams, regions);
          this.dataTypes.initVmSku(vmsku);
          new Promise(() => this.refreshData()).then(() => this.gridApi?.hideOverlay());
        },
        (error: unknown) => {
          this.notificationService.error(error as string);
          this.gridApi?.hideOverlay();
        }
      );
  }

  onSelectionChanged(event: GridOptions): void {
    const nodes = event.api.getSelectedNodes();
    if (nodes && nodes.length > 0) {
      this.selectedRowsCount = nodes.length;
      this.selectedRow = nodes[0].data;
    } else {
      this.selectedRowsCount = 0;
      this.selectedRow = null;
    }
  }

  async addRow(): Promise<void> {
    await this.addRowCommonProcess(this.columnDef);
  }

  editRow(): void {
    if (!this.selectedRow) {
      this.message = "Please select one row to edit";
      return;
    }

    this.message = "";
    const editableList = this.columnDef.filter((col) => col.isEditable).map((d) => d.field);
    const data: IEditModalOption[] = this.columnDef.map((col) => {
      const key = col.field;
      let value = this.selectedRow[key];
      let type: string = col.valueType || typeof value;
      if (type === "object") {
        type = "string";
        value = "";
      }
      if (type === "requestor" && !col.isEditable) {
        // Convert requestor oid to name if uneditable
        value = this.dataTypes.teamNameOidMap[value];
      }
      if (type === "region" && !col.isEditable) {
        value = this.dataTypes.regionMap[value];
      }
      if (type === "sku" && !col.isEditable) {
        // Convert SKU CRP name to ADO name if uneditable
        value = this.dataTypes.skuMap[value];
      }

      const enableWildcard = col.wildcard;
      let currentOptions = this.dataTypes.getOptions(type);
      if (enableWildcard) {
        if (currentOptions) {
          currentOptions = [..."*", ...currentOptions];
        } else {
          currentOptions = ["*"];
        }
      }
      return {
        key: key,
        name: col.headerName,
        value: value,
        type: type,
        options: col.isEditable && currentOptions,
        isEditable: col.isEditable,
        hide: col.hide,
        isNullable: col.isNullable || false,
        displayName: type === "Teams" ? `${this.dataTypes.teamNameOidMap[value]}(${value})` : null,
      };
    });

    const p = this.modalService.editModal(this.type, data, this.tableName, editableList, this.updateRowFunc);
    p.then((response) => {
      if (response) {
        this.rowData = [];
        this.refreshData();
        console.log("Add new row for table", this.tableName);
      } else {
        console.log("Cancelled to add new row for table", this.tableName);
      }
    }).catch((error) => {
      if (error) {
        this.message = error;
      }
    });
  }

  async deleteRow(): Promise<void> {
    if (!this.selectedRow) {
      this.message = "Please select one row to delete";
      return;
    }

    if (this.deleteRowEnable) {
      console.log("delete row for: ", this.tableName, "with", this.selectedRow);
      this.message = "";

      try {
        await this.modalService.confirmationModal("Are you sure delete the selected row?");
      } catch {
        // For the model dialog dimiss
        return;
      }

      const p = this.deleteRowFunc(this.selectedRow);
      p.then(() => {
        const msg = `Delete ${this.tableName} data successfully.`;
        console.log(msg);
      })
        .catch((error) => {
          this.message = `Failed to delete the ${this.tableName} data. Error: ${error}`;
          this.notificationService.error(this.message);
        })
        .finally(() => {
          this.refreshData();
        });
    }
  }

  setupFilterByLocalData(): void {
    super.setupFilterByLocalData(false);
  }

  exportExcel(): void {
    const fileNamePrefix = this.mainTitle ? this.mainTitle?.split(" ").join("") : this.tableName?.split(" ").join("") + "DataManagement";
    const fileName = fileNamePrefix + "-" + getExportedExcelFileNameSuffix();
    const sheetName = `${this.mainTitle || this.tableName}`;

    exportExcel(this.gridApi, fileName, sheetName);
  }

  private async addRowCommonProcess(columnDef: any): Promise<void> {
    if (this.addRowEnable) {
      console.log("add new row for: ", this.tableName);
      this.message = "";
      let data: IEditModalOption[] = [];
      var defaultValue = await this.getDefaultValueFunc();
      console.log(defaultValue);
      data = columnDef.map((property) => {
        const enableWildcard = property.wildcard;
        const type = property.valueType;
        let currentOptions = this.dataTypes.getOptions(type);
        if (enableWildcard) {
          if (currentOptions) {
            currentOptions = [..."*", ...currentOptions];
          } else {
            currentOptions = ["*"];
          }
        }
        const defaultVal = type === "submitter" ? ApiService.userProfile?.Email : defaultValue[property.field];
        const value = type === "boolean" ? defaultVal : defaultVal || "";
        return {
          key: property.field,
          name: property.headerName,
          value: value,
          type: type || "string",
          options: currentOptions,
          isEditable: !property.disabled,
          hide: property.hide,
          isNullable: property.isNullable || false,
        };
      });
  
      const p = this.modalService.newRowModal(this.type, data, this.tableName, this.addRowFunc);
      p.then((response) => {
        if (response) {
          this.rowData = [];
          this.refreshData();
          console.log("Add new row for table", this.tableName);
        } else {
          console.log("Cancelled to add new row for table", this.tableName);
        }
      }).catch((error) => {
        if (error) {
          this.message = error;
        }
      });
    } else {
      console.log("add row not enabled for: ", this.tableName);
    }
  }
}
