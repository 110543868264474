import { Component, HostListener, OnInit } from "@angular/core";
import { ColDef, ColumnApi, FilterChangedEvent, GridApi, GridOptions, RowNode } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { ColParams } from "src/app/shared/grid/ag-grid";
import { ModalService } from "src/app/shared/modal.service";
import * as CDs from "src/app/utility/commonColDef";
import { extendDefaultOptions, setupFilterByLocalData, cacheFilterStatsLocal } from "../../utility/gridHelper";
import { BaseComponent } from "../../shared/base.component";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ApiService } from "../../services";
import { IUserProfile, FfpPlanListRecord } from "src/app/npr-request.model";
import { FfpPlanRegion } from "../../generated-models/FfpPlanRegion";
import { FfpPlanSubmission } from "../../generated-models/FfpPlanSubmission";
import { RegionBuildout } from "../../generated-models/RegionBuildout";

interface RowDataForRegion {
  RegionName: string;
  RegionStatus: string;
  AssignedVersion?: number;
  AlreadyAssigned?: string;
  AssignedBy?: string;
  AssignedTime?: Date | null;
  RegionalPlanStatus?: string;
}

@Component({
  templateUrl: "./ffp-plans-assignment-service-owner.component.html",
  styleUrls: ["../../styles.scss", "./ffp.scss"],
})
export class FfpPlansAssignmentServiceOwnerComponent extends BaseComponent implements OnInit {
  regions: RegionBuildout[] = [];
  selectedRegion: RegionBuildout;
  userProfile: IUserProfile = null;

  rowDataForRegion: RowDataForRegion[] = [];

  gridOptionsForRegion: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize = 15;

  ffpPlans: FfpPlanListRecord[] = [];
  selectedPlan: FfpPlanListRecord;

  ffpPlanRegions: FfpPlanRegion[] = [];

  hideAlreadyAssigned = false;
  hasRowSelected = false;

  keyFilterStatusMultiRegions = "filter:one-to-multiple";
  canAssign = false;

  constructor(
    private apiService: ApiService,
    private modalService: ModalService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService
  ) {
    super();
  }

  async ngOnInit() {
    this.hasRowSelected = false;
    this.canAssign = false;

    const RegionsColumnDefinition: ColDef[] = [
      {
        colId: "customFilter",
        valueGetter: (params: ColParams<RowDataForRegion, void>) => {
          if (this.hideAlreadyAssigned) {
            return this.isAssignable(params.data);
          }

          return true;
        },
        filterParams: {
          values: ["true", "false"],
          newRowsAction: "keep",
        },
        hide: true,
      },
      {
        ...CDs.RegionColDef("RegionName"),
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      CDs.AlreadyAssigned,
      CDs.AssignedVersion,
      CDs.AssignedBy,
      CDs.AssignedTime,
      CDs.RegionalPlanStatus,
    ];

    this.gridOptionsForRegion = extendDefaultOptions({
      columnDefs: RegionsColumnDefinition,
      suppressPaginationPanel: true,
      isRowSelectable: (node: RowNode) => this.isAssignable(node.data),
      enableRangeSelection: false,
      rowSelection: "multiple",
      animateRows: true,
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent' },
          { statusPanel: 'agTotalRowCountComponent' },
          { statusPanel: 'agFilteredRowCountComponent' },
          { statusPanel: 'agSelectedRowCountComponent' },
          { statusPanel: 'agAggregationComponent' }
        ]
      }
    });

    this.apiService.getUserProfile().then(async (response) => {
      this.userProfile = response;
      if (response) {
        this.gridApi?.showLoadingOverlay();

        // Region List
        const regionListResponse = await this.apiService.getFfpRegionBuildouts().toPromise();
        if (!regionListResponse) {
          console.warn("No region obtained from server.");
        } else {
          this.regions = regionListResponse
        }
      }
    });


    this.loadingService.setLoading(true);

    // FfpPlan List
    const ffpPlanListResponse = await this.apiService.getMyFfpPlans().toPromise();
    if (!ffpPlanListResponse) {
      console.warn("No plan obtained from server.");
    } else {
      this.ffpPlans = ffpPlanListResponse
        .filter((p) => p.Version > 0)
        .map((p) => ({ ...p, Label: `${p.ServiceTeam} (${p.ServiceTreeId})` } as FfpPlanListRecord));
    }

    await this.getLatestFfpRegionalPlans();

    this.loadingService.setLoading(false);
  }

  onGridReady(params: GridOptions): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setFilterModel({
      customFilter: ["true"],
    });
  }

  async getLatestFfpRegionalPlans(): Promise<void> {
    return this.apiService
      .getLatestFfpRegionalPlans()
      .toPromise()
      .then(
        (response) => {
          this.ffpPlanRegions = response;
          setupFilterByLocalData(this.gridApi, this.gridColumnApi, this.keyFilterStatusMultiRegions);
        },
        (e: any) => {
          this.ffpPlanRegions = [];
          this.notificationService.error(e);
        }
      );
  }

  onPlanChanged(): void {
    this.rowDataForRegion = [];

    if (this.selectedPlan) {
      const rowData: RowDataForRegion[] = [];
      this.regions.forEach((region) => {
        const row: RowDataForRegion = {
          RegionName: region.Region,
          RegionStatus: region.Status,
        };
        rowData.push(row);
      });

      rowData.forEach((row) => {
        row.AlreadyAssigned = "No";

        const ffpPlanRegion = this.ffpPlanRegions.find(
          (p) => p.Region === row.RegionName && p.ServiceTreeId === this.selectedPlan.ServiceTreeId
        );
        if (ffpPlanRegion) {
          if (ffpPlanRegion.Version === this.selectedPlan.Version) {
            row.AlreadyAssigned = "Yes";
          }

          row.AssignedVersion = ffpPlanRegion.Version;
          row.AssignedBy = ffpPlanRegion.Submitter;
          row.AssignedTime = ffpPlanRegion.SubmitTime;
          row.RegionalPlanStatus = ffpPlanRegion.Status;
        }
      });
      this.rowDataForRegion = rowData;
      this.hasRowSelected = false;
      this.canAssign = false;
      setTimeout(() => {
        this.gridColumnApi.autoSizeAllColumns();
      }, 100);
    }
    setupFilterByLocalData(this.gridApi, this.gridColumnApi, this.keyFilterStatusMultiRegions);
  }

  // Plan is not able to be assigned if the latest version is already assigned to the region
  isAssignable(row: RowDataForRegion): boolean {
    return row.AlreadyAssigned === "No";
  }

  onHideAlreadyAssignedChanged(): void {
    this.gridApi.onFilterChanged();
  }

  async showFfpPlanAssignmentDialog(): Promise<void> {
    try {
      await this.assignFfpPlans();
    } catch {
      // For the model dialog dimiss
      return;
    }
  }

  async assignFfpPlans(): Promise<void> {
    const selectedRows = this.gridApi.getSelectedRows() as RowDataForRegion[];
    const ffpPlanSubmissions = selectedRows.map((r) => {
      return {
        ServiceTreeId: this.selectedPlan.ServiceTreeId,
        ServiceTeam: this.selectedPlan.ServiceTeam,
        Version: this.selectedPlan.Version,
        Region: r.RegionName,
      } as FfpPlanSubmission;
    });

    await this.modalService.ffpPlanAssignmentResponseModal(ffpPlanSubmissions);
    await this.getLatestFfpRegionalPlans();

    // reload grid
    this.onPlanChanged();
  }

  onSelectionChanged(params: GridOptions): void {
    var rows = params.api.getSelectedRows().length;
    this.hasRowSelected = rows > 0;
    if (!this.hasRowSelected) {
      this.canAssign = false;
    }
    else {
      this.canAssign = true;
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    setTimeout(() => {
      this.gridColumnApi.autoSizeAllColumns();
    }, 100);
  }

  onFilterChangedPlan(event: FilterChangedEvent): void {
    cacheFilterStatsLocal(event, this.keyFilterStatusMultiRegions);
  }
}
