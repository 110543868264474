import { Component, HostListener } from "@angular/core";
import { ColDef, ColumnApi, ExcelStyle, GridApi, GridOptions } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ModalService } from "src/app/shared/modal.service";
import { GridViewBaseComponent } from "../../utility/grid-view-base.component";
import { SharedDataService } from "../../services/sharedDataService";
import { exportExcel, getExportedExcelFileNameSuffix } from "src/app/utility/common-helper";
import { ColParams } from "src/app/shared/grid/ag-grid";
import { FfpPlansColumnDefinition } from "./ag-grid-column-definition";
import { PlanOnboardingModalOptions } from "../plans/modals/plan-onboarding-modal/plan-onboarding-modal.component";
import { FfpPlan } from "src/app/generated-models/FfpPlan";

@Component({
  templateUrl: "./ffp-plans.component.html",
  styleUrls: ["../../styles.scss", "./ffp.scss"],
})
export class FfpPlansComponent extends GridViewBaseComponent {
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize = 15;
  showMyServiceTeamPlansOnly: boolean = true;
  myFfpPlanServiceTreeIds: string[] = [];
  rowData: FfpPlan[] = [];
  headerStyle: ExcelStyle[] = [
    {
      id: "header",
      font: {
        bold: true,
      },
    },
  ];

  keyFilterStats = "filter:region-agnostic-plans";

  message: string;

  constructor(
    protected apiService: ApiService,
    private modalService: ModalService,
    private loadingService: LoadingScreenService,
    protected sharedDataService: SharedDataService,
    private notificationService: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(apiService, sharedDataService);
  }

  async ngOnInit() {
    this.routeToFfpPlanEditPage();
    super.InitAgGrid(FfpPlansColumnDefinition);
    
    this.myFfpPlanServiceTreeIds = (await this.apiService.getMyFfpPlans().toPromise()).map(plan => plan.ServiceTreeId);
    if (this.userProfile?.IsApprover) {
      this.showMyServiceTeamPlansOnly = false;
    }
    FfpPlansColumnDefinition.forEach( (colDef: ColDef) => {
      if (colDef.headerName === 'Custom Filter') {
        colDef.valueGetter = (params: ColParams<FfpPlan, void>) => {
          if (!this.showMyServiceTeamPlansOnly) {
            return true;
          }
          const row = params.data as FfpPlan;
          return this.myFfpPlanServiceTreeIds.some(service => row.ServiceTreeId === service);
        }
      }
    });

    this.gridOptions.api?.setColumnDefs(FfpPlansColumnDefinition);
  }

  onGridReady(params: GridOptions) {
    super.onGridReady(params);
    this.gridApi.setFilterModel({
      customFilter: ["true"],
    });
  }

  refreshData() {
    this.loadingService.setLoading(true);
    this.apiService.getAllFfpPlans().subscribe(
      (response) => {
        this.rowData = response;
        this.loadingService.setLoading(false);
        super.setupFilterByLocalData();
      },
      (e: any) => {
        this.rowData = [];
        this.loadingService.setLoading(false);
        this.notificationService.error(e);
      }
    );
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    setTimeout(() => {
      //this.gridApi.sizeColumnsToFit();
      this.gridColumnApi.autoSizeAllColumns();
    }, 100);
  }

  onShowMyServiceTeamPlansOnlyChanged() {
    this.gridApi.onFilterChanged();
  }

  // reuse Plan Onboarding Modal with save function updated only
  async showOnboardingDialog() {
    const options: PlanOnboardingModalOptions = {
      save: this.onboardNewFfpPlan.bind(this),
    };
    await this.modalService.planOnboardingModal(options);
  }

  onboardNewFfpPlan(serviceTreeId: string) {
    this.loadingService.setLoading(true);

    this.apiService.onboardNewFfpPlan(serviceTreeId).subscribe(
      () => {
        this.message = `New Ffp Plan has been onboarded successfully.`;
        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);
      },
      (err: unknown) => {
        this.message = `Failed to onboard new ffp plan. ${err}`;
        this.notificationService.error(this.message);
        this.loadingService.setLoading(false);
      },
      () => {
        this.refreshData();
      }
    );
  }

  exportExcel() {
    const fileName = "RegionAgnosticFfpPlans-" + getExportedExcelFileNameSuffix();
    const sheetName = "Region Agnostic FFP Plans";

    this.loadingService.setLoading(true);
    exportExcel(this.gridApi, fileName, sheetName);
    this.loadingService.setLoading(false);
  }

  routeToFfpPlanEditPage() {
    // deep link to edit ffp plan page if service query param is provided
    this.route.queryParams.subscribe((params) => {
      if (params.service && params.service.trim() != "") {
        this.router.navigate([`/ffp/services/${params.service}/editor`]);
      }
    });
  }
}
