<ags-header routerLinkHome="/" [hideLiveNotification]="true">
  <li class="nav-item">
    <a class="nav-link" routerLink="/plans/all">Planned Quota</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/create-request">On Demand Quota</a>
  </li>
  <li class="nav-item" *ngIf="userProfile?.IsAdmin || userProfile?.IsApproverAdmin">
    <a class="nav-link" routerLink="ffp/all">FFP</a>
  </li>
  <li class="nav-item" *ngIf="userProfile?.IsAdmin || userProfile?.IsPfAdmin">
    <a class="nav-link" routerLink="pf/all">PF</a>
  </li>
  <li *ngIf="betEnabled" class="nav-item">
    <a class="nav-link" routerLink="/prep/home">Billing Meter</a>
  </li>
  <li class="nav-item" *ngIf="userProfile?.IsAdmin || userProfile?.IsApproverAdmin">
    <a class="nav-link" routerLink="/approver-admin">Administration</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/self-service/region-information">Self Service</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [attr.href]="getFAQ()" target="_blank">FAQ</a>
  </li>
  <ags-header-menu [title]="'Support'">
    <li class="nav-item">
      <a href="https://aka.ms/lionrockdocs#get-supports" target="_blank"> Contact Us </a>
    </li>
    <li class="nav-item">
      <a
        href="https://forms.office.com/Pages/ResponsePage.aspx?id=v4j5cvGGr0GRqy180BHbR6qnDbwX3-VPoofVY69iG2hUMDE1OU1GSU02NlZGN0ZCSkxIS0kyTU9JMS4u"
        target="_blank">
        Feedback
      </a>
    </li>
    <li class="nav-item">
      <a href="https://aka.ms/lionrocktsg" target="_blank"> TSG </a>
    </li>
  </ags-header-menu>
</ags-header>
<main class="d-flex" style="height: calc(100% - 50px); padding-bottom: 37px">
  <div class="sidebar">
    <ags-side-navigation [navItems]="getModuleNavItems()"></ags-side-navigation>
  </div>
  <div class="flex-grow-1" style="height: 100%; overflow-y: auto">
    <div *ngIf="isTestEnv" class="alert alert-danger" role="alert" style="margin-bottom: 0; text-align: center">
      Quota or access is not really granted in this TEST environment.
    </div>
    <router-outlet></router-outlet>
  </div>
</main>

<em-loading-screen></em-loading-screen>
<ags-footer [fixedToBottom]="true">
  <div style="white-space: nowrap; text-align: center">
    <span style="padding: 0.25rem; color: red">Microsoft Confidential</span>
  </div>
</ags-footer>
