import { ColDef } from "ag-grid-community";
import { ColParams } from "src/app/shared/grid/ag-grid";
import { LinkCellComponent } from "src/app/shared/grid/link-cell.component";
import * as CDs from "src/app/utility/commonColDef";
import { FfpPlanRegion } from "../../generated-models/FfpPlanRegion";

export const ffpPlanEditorCellComponentParams = {
  target: "_blank",
  url: (params: ColParams<FfpPlanRegion, string>) => `/quota/ffp/plans/services/${params.data.ServiceTreeId}/editor`,
};

export const FfpPlansColumnDefinition: ColDef[] = [
  {
    ...CDs.ServiceTreeId,
    cellRendererFramework: LinkCellComponent,
    cellRendererParams: ffpPlanEditorCellComponentParams,
  },
  CDs.ServiceTeam,
  CDs.Ring,
  CDs.LatestCommitVersion,
  CDs.SubmitTime("Submit Time", "CreatedTime"),
  CDs.CreatedBy,
  CDs.CustomFilter
];

export const ffpPlanVersionCellComponentParams = {
  target: "_blank",
  url: (params: ColParams<FfpPlanRegion, string>) =>
    `/quota/ffp/plans/services/${params.data.ServiceTreeId}/regions/${params.data.Region}/versions/${params.data.Version}/review`,
};

export const viewFfpPlanCellComponentParams = {
  value: "Review",
  target: "_blank",
  url: (params: ColParams<FfpPlanRegion, string>) =>
    `/quota/ffp/plans/services/${params.data.ServiceTreeId}/regions/${params.data.Region}/versions/${params.data.Version}/review`,
};

export const FfpMyRegionalPlanColumnDefinition: ColDef[] = [
  CDs.ServiceTreeId,
  CDs.ServiceTeam,
  CDs.RegionColDef("Region"),
  CDs.Ring,
  {
    ...CDs.Version,
    cellRendererFramework: LinkCellComponent,
    cellRendererParams: ffpPlanVersionCellComponentParams,
  },
  CDs.Submitter,
  CDs.SubmitTime(),
  CDs.RegionalPlanStatus,
  CDs.ServiceOwnerApproved,
  CDs.FfpAdminApproved,
];

export const FfpRegionBuildoutCellComponentParams = {
  target: "_blank",
  url: (params: ColParams<FfpPlanRegion, string>) =>
    `/quota/ffp/buildout/regions/${params.data.Region}/plans`,
}
export const FfpRegionBuildoutColumnDefinition: ColDef[] = [
  {
    ...CDs.RegionColDef("Region"),
    cellRendererFramework: LinkCellComponent,
    cellRendererParams: FfpRegionBuildoutCellComponentParams,
  },
  CDs.Status,
];

export const BuildoutPlansColumnDefinition: ColDef[] = [
  {
    headerName: "Action",
    colId: "action",
    sortable: false,
    suppressMenu: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    cellRendererFramework: LinkCellComponent,
    cellRendererParams: viewFfpPlanCellComponentParams,
  },
  CDs.RegionColDef("Region"),
  CDs.Ring,
  CDs.ServiceTeam,
  CDs.ServiceTreeId,
  CDs.Submitter,
  CDs.SubmitTime("SubmitTime"),
  CDs.LatestVersion,
  CDs.Status,
];
