/// <reference path="../../../../node_modules/monaco-editor/monaco.d.ts" />
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { BaseComponent } from "../../shared/base.component";
import { ApiService } from "src/app/services";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalService } from "src/app/shared/modal.service";
import { CellClickedEvent, ColDef, GridApi, GridOptions, ICellRendererParams } from "ag-grid-community";
import { FfpPlanFile } from "src/app/generated-models/FfpPlanFile";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import { EditDeleteCellComponent, EditDeleteCellComponentParams } from "src/app/shared/grid/edit-delete-cell.component";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";
import { PlanEditorModalOptions, PlanJsonSchema } from "./modals/ffp-plan-editor-modal/ffp-plan-editor-modal.component";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ViewCellComponent, ViewCellComponentParams } from "src/app/shared/grid/view-cell.component";
import { IsServiceUser } from "src/app/utility/userprofile-helper";
import { PlanCommit } from "src/app/generated-models/PlanCommit";
import { PlanFileTypeEnum } from "src/app/shared/enums/plan-enums";
import { ActionCellComponent, ActionCellComponentParams } from "src/app/shared/grid/action-cell.component";
import { PlanDiffModalOptions } from "./modals/plan-diff-modal/plan-diff-modal.component";
import { PlanNewFileModalOptions } from "../plans/modals/plan-new-file-modal/plan-new-file-modal.component";

@Component({
  templateUrl: "./ffp-plan-editor.component.html",
  styleUrls: ["../../styles.scss", "./ffp.scss"],
})
export class FfpPlanEditorComponent extends BaseComponent implements OnInit {
  editorModal: NgbModalRef;
  serviceTreeId: string;
  serviceTeam: string;
  latestVersion: number;
  allPlanFiles: FfpPlanFile[] = [];
  rowSourceData: FfpPlanFile[] = [];
  gridOptions: GridOptions;
  gridApi: GridApi;
  initPageSize: number = 15;
  isReadonly: boolean;
  ifFilesChanged: boolean = false;
  canUndoOnboarding = false;
  rowMergedData: FfpPlanFile[] = [];
  gridOptionsMerged: GridOptions;

  message: string;

  jsonSchema: PlanJsonSchema = { FullSchema: "", MergeSchema: "" };

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private apiService: ApiService,
    protected modalService: ModalService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService,
    private title: Title
  ) {
    super();
  }

  async ngOnInit() {
    this.serviceTreeId = this.route.snapshot.params["serviceTreeId"];

    this.apiService
      .getUserProfile()
      .then((userProfile) => {
        if (userProfile) {
          this.isReadonly = !IsServiceUser(userProfile, this.serviceTreeId);
          if (this.isReadonly) {
            const viewCellComponentParams: ViewCellComponentParams<FfpPlanFile> = {
              viewFunc: this.viewFfpPlanFile.bind(this),
            };
            const columnDefs = [];
            this.gridOptions.columnDefs.map((map) => {
              if (map.headerName === "Action") {
                columnDefs.push({
                  headerName: map.headerName,
                  sortable: false,
                  suppressMenu: true,
                  cellRendererFramework: ViewCellComponent,
                  cellRendererParams: viewCellComponentParams,
                });
              } else {
                columnDefs.push(map);
              }
            });

            this.gridOptions.api.setColumnDefs(columnDefs);
          }
          // If user is admin, then undo onboarding button is visible.
          if (userProfile.IsAdmin) {
            this.canUndoOnboarding = true;
          }
        } else {
          this.notificationService.error("user profile returns null, no data was loaded.");
        }
      })
      .catch((error) => {
        this.notificationService.error(`unable to get user profile, error: ${error.message}`);
      });

    this.apiService.getFfpPlan(this.serviceTreeId).subscribe(
      (response) => {
        this.latestVersion = response.Version;
        this.serviceTeam = response.ServiceTeam;
        this.title.setTitle(`${this.route.snapshot.data.title} - ${this.serviceTeam}`);
      },
      (e: unknown) => {
        this.notificationService.error(`Failed to find ffp plan for ServiceTree Id ${this.serviceTreeId}. Error: ${e.toString()}`);
      }
    );

    this.apiService.getFfpPlanJsonSchema().subscribe(
      (response) => {
        this.jsonSchema.FullSchema = response.FullSchema;
        this.jsonSchema.MergeSchema = response.MergeSchema;
        setTimeout(function () {
          if (!response) {
            this.notificationService.error("Editor json schema returns null, no data was loaded.");
          }
        }, 200);
      },
      (e: unknown) => {
        this.notificationService.error(`Editor json schema returns null, no data was loaded. Error: ${e.toString()}`);
      }
    );

    const planFilesColumnDefinition: ColDef[] = [
      {
        headerName: "Action",
        sortable: false,
        suppressMenu: true,
        cellRendererSelector: (params: ICellRendererParams) => {
          if (params.data?.PlanFileStatus !== "Deleted") {
            return {
              frameworkComponent: EditDeleteCellComponent,
              params: {
                editFunc: this.editFfpPlanFile.bind(this),
                deleteFunc: this.deleteFfpPlanFile.bind(this),
              } as EditDeleteCellComponentParams<FfpPlanFile>,
            };
          }

          return undefined;
        },
      },
      {
        headerName: "Name",
        field: "FileName",
      },
      {
        headerName: "Last Modified",
        field: "LastModified",
        cellRenderer: (params) => PreprocessDateTime(params.value),
      },
      {
        headerName: "File Status",
        field: "PlanFileStatus",
        cellRendererFramework: ActionCellComponent,
        cellRendererParams: {
          actionFunc: this.diffFfpPlanFile.bind(this),
        } as ActionCellComponentParams<FfpPlanFile>,
      },
      {
        headerName: "Last Modified By",
        field: "LastModifiedBy",
      },
    ];

    const planFilesMergedColumnDefinition: ColDef[] = [
      {
        headerName: "Action",
        colId: "action",
        cellRendererSelector: (params: ICellRendererParams) => {
          if (params.data?.PlanFileStatus !== "Deleted") {
            return {
              component: () => `
                <button type="button" class="btn btn-link">
                  <i class="fas fa-sm fa-code"></i>
                </button>`,
            };
          }

          return undefined;
        },
      },
      {
        headerName: "Name",
        field: "FileName",
      },
      {
        headerName: "Last Modified",
        field: "LastModified",
        cellRenderer: (params) => PreprocessDateTime(params.value),
      },
      {
        headerName: "File Status",
        field: "PlanFileStatus",
        cellRendererFramework: ActionCellComponent,
        cellRendererParams: {
          actionFunc: this.diffFfpPlanFile.bind(this),
        } as ActionCellComponentParams<FfpPlanFile>,
      },
      {
        headerName: "File Type",
        field: "PlanFileType",
      },
    ];

    this.gridOptions = extendDefaultOptions({
      columnDefs: planFilesColumnDefinition,
      enableRangeSelection: false,
      rowSelection: "single",
      animateRows: true,
      sideBar: null,
      rowClassRules: {
        "ag-row-added": (params) => {
          return params.data.PlanFileStatus === "Added";
        },
        "ag-row-modified": (params) => {
          return params.data.PlanFileStatus === "Modified";
        },
        "ag-row-deleted": (params) => {
          return params.data.PlanFileStatus === "Deleted";
        },
      },
    });

    this.gridOptionsMerged = extendDefaultOptions({
      onCellClicked: this.onCellClicked.bind(this),
      columnDefs: planFilesMergedColumnDefinition,
      enableRangeSelection: false,
      rowSelection: "single",
      animateRows: true,
      sideBar: null,
      rowClassRules: {
        "ag-row-added": (params) => {
          return params.data.PlanFileStatus === "Added";
        },
        "ag-row-modified": (params) => {
          return params.data.PlanFileStatus === "Modified";
        },
        "ag-row-deleted": (params) => {
          return params.data.PlanFileStatus === "Deleted";
        },
      },
    });

    this.getFfpPlanFiles();
  }

  onGridReady(params: GridOptions) {
    this.gridApi = params.api;
  }

  protected async onCellClicked(event: CellClickedEvent) {
    if (event.colDef.colId === "action") {
      this.viewFfpPlanFile(event.data as FfpPlanFile);
    }
  }

  getFfpPlanFiles() {
    this.loadingService.setLoading(true);

    this.apiService.getLatestFfpPlanFiles(this.serviceTreeId).subscribe(
      (response) => {
        const original: FfpPlanFile[] = [];
        const generated: FfpPlanFile[] = [];
        response.forEach((plan) => {
          if (plan.FileName === "default.json") {
            original.push(plan);
            generated.push(plan);
          } else if (plan.FileName.includes("override")) {
            original.push(plan);
          } else {
            if (plan.PlanFileType === PlanFileTypeEnum.Generated) {
              generated.push(plan);
            } else {
              original.push(plan);
              generated.push(plan);
            }
          }
        });
        this.rowSourceData = original;
        this.rowMergedData = generated;
        this.allPlanFiles = response;
        this.ifFilesChanged = this.rowSourceData.filter((row) => row.PlanFileStatus != "NoChange").length > 0;
        this.loadingService.setLoading(false);
        setTimeout(() => {
          this.gridOptions.columnApi.autoSizeAllColumns();
          this.gridOptionsMerged.columnApi.autoSizeAllColumns();
        }, 100);
      },
      (e: unknown) => {
        this.rowSourceData = [];
        this.rowMergedData = [];
        this.allPlanFiles = [];
        this.loadingService.setLoading(false);
        this.notificationService.error(e.toString());
      }
    );
  }

  async viewFfpPlanFile(ffpPlanFile: FfpPlanFile) {
    this.showFfpPlanEditorDialog(ffpPlanFile, true);
  }

  editFfpPlanFile(ffpPlanFile: FfpPlanFile) {
    this.showFfpPlanEditorDialog(ffpPlanFile);
  }

  async diffFfpPlanFile(ffpPlanFile: FfpPlanFile) {
    await this.showPlanDiffDialog(ffpPlanFile);
  }

  async deleteFfpPlanFile(ffpPlanFile: FfpPlanFile) {
    try {
      await this.modalService.confirmationModal(`Are you sure to delete ${ffpPlanFile.FileName}?`);
    } catch {
      // For the model dialog dimiss
      return;
    }

    this.loadingService.setLoading(true);

    this.apiService.deleteFfpPlanFile(this.serviceTreeId, ffpPlanFile.FileName).subscribe(
      () => {
        this.message = `Plan file has been deleted successfully.`;
        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);
      },
      (error) => {
        this.notificationService.error(error);
        this.loadingService.setLoading(false);
      },
      () => {
        this.getFfpPlanFiles();
      }
    );
  }

  async showFfpPlanNewFileDialog() {
    const cloudOverrideExist = this.rowSourceData.some((file) => file.FileName.includes("cloud"));
    const regionTypeOverrideExist = this.rowSourceData.some((file) => file.FileName.includes("type"));
    const overrideNameList = this.rowSourceData.map((file) => file.FileName.split(".")[0]);
    const options: PlanNewFileModalOptions = {
      cloudOverrideExist: cloudOverrideExist,
      regionTypeOverrideExist: regionTypeOverrideExist,
      overrideNameList: overrideNameList,
    };
    const fileName: string = await this.modalService.planNewFileModal(options);

    if (fileName) {
      const ffpPlanFile: FfpPlanFile = this.rowSourceData.find((p) => p.FileName === `${fileName}.json`);
      if (ffpPlanFile) {
        this.showFfpPlanEditorDialog(ffpPlanFile);
      } else {
        const emptyFfpPlanFile = {
          FileName: `${fileName}.json`,
          Content: "{}",
          Format: "json",
        } as FfpPlanFile;

        if (fileName.includes("override")) {
          // partial mode
          const cloudOrRegionName = fileName.split(".")[0];
          this.checkOverrideExist(cloudOrRegionName, emptyFfpPlanFile);
        } else {
          // fully mode
          const planTemplate = await this.apiService.getFfpPlanFileTemplate(this.serviceTreeId).toPromise();
          const cloudOrRegionName = fileName;
          emptyFfpPlanFile.Content = planTemplate.Content;
          this.checkOverrideExist(cloudOrRegionName, emptyFfpPlanFile);
        }
      }
    }
  }

  checkOverrideExist(cloudOrRegionName: string, emptyFfpPlanFile: FfpPlanFile) {
    const ffpPlanFile: FfpPlanFile = this.allPlanFiles.find((p) => p.FileName === `${cloudOrRegionName}.json`);
    if (ffpPlanFile) {
      this.notificationService.error(`An override plan file for ${cloudOrRegionName} alread exists.`);
    } else {
      this.showFfpPlanEditorDialog(emptyFfpPlanFile);
    }
  }

  showFfpPlanEditorDialog(ffpPlanFile: FfpPlanFile, isReadonly: boolean = false) {
    const options: PlanEditorModalOptions = {
      model: ffpPlanFile,
      save: this.saveFfpPlanFile.bind(this),
      isReadonly: isReadonly,
      schema: ffpPlanFile.FileName.includes("override") ? this.jsonSchema.MergeSchema : this.jsonSchema.FullSchema,
    };
    this.editorModal = this.modalService.ffpPlanEditorModal(options);
  }

  async showPlanDiffDialog(ffpPlanFile: FfpPlanFile) {
    const options: PlanDiffModalOptions = {
      model: ffpPlanFile,
      isReadonly: true,
    };
    this.editorModal = this.modalService.planDiffModal(options);
  }

  saveFfpPlanFile(ffpPlanFile: FfpPlanFile) {
    this.loadingService.setLoading(true);

    this.apiService.saveFfpPlanFile(this.serviceTreeId, ffpPlanFile).subscribe(
      () => {
        this.message = `Ffp plan file has been saved successfully.`;
        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);
        this.editorModal.close();
      },
      (error) => {
        this.notificationService.error(error);
        this.loadingService.setLoading(false);
      },
      () => {
        this.getFfpPlanFiles();
      }
    );
  }

  async undoOnboarding() {
    try {
      await this.modalService.confirmationModal(
        `Are you sure to undo onboarding for service ${this.serviceTreeId}? This will delete all plan files and service owner need to re-onboard again.`
      );
    } catch {
      // For the model dialog dimiss
      return;
    }

    this.loadingService.setLoading(true);

    this.apiService.undoOnboardFfpPlan(this.serviceTreeId).subscribe(
      () => {
        this.message = `All ffp plan files for service ${this.serviceTreeId} are removed.`;
        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);

        // delay and redirect to ffp plan list page
        setTimeout(() => {
          this.router.navigateByUrl("/ffp/all");
        }, 1000);
      },
      (error) => {
        this.notificationService.error(error);
        this.loadingService.setLoading(false);
      }
    );
  }

  async showPlanHistoryDialog() {
    await this.modalService.ffpPlanHistoryModal(this.serviceTreeId);
  }

  async showCommitPlanDialog() {
    try {
      const comment = await this.modalService.planCommitModal();
      await this.commitFfpPlan(comment);
    } catch {
      // For the model dialog dismiss
    }
  }

  async commitFfpPlan(comment: string) {
    this.loadingService.setLoading(true);

    const payload: PlanCommit = {
      Comment: comment,
    };

    this.apiService.commitFfpPlan(this.serviceTreeId, payload).subscribe(
      async (result) => {
        this.message = `Successfully committed the ffp plan version(<b>${result.Version}</b>)</br>`;

        this.loadingService.setLoading(false);
        await this.modalService.planCommitResponseModal(this.message); // todo
        this.getFfpPlanFiles();
      },
      (error) => {
        if (!error) {
          error = `Failed to commit ffp plan.`;
        }
        this.notificationService.error(error);
        this.loadingService.setLoading(false);
      }
    );
  }
}
