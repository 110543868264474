import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import {
  BatchQuotaType,
  BatchRequestType,
  DeploymentScope,
  IAppServiceQuotaUI,
  IBasicInfo,
  IBatchSku,
  IDetailsInfo,
  IJustification,
  IRequestRegion,
  IRequestVmSku,
  ISummaryInfo,
  ITeamListRecord,
  IUIRequest,
  IVmDiskQuotaInfo,
  SkuType,
  SqlMiType,
  allowedCommonRegionStatuses,
} from "src/app/npr-request.model";
import { ApiService } from "src/app/services";
import { Region } from "src/app/generated-models";
import { HdiSku } from "src/app/generated-models/HdiSku";
import { namespacesList } from "./components/details-info.component.namespaces";

@Injectable({
  providedIn: "root",
})
export class CreateRequestService {
  basicInfo: IBasicInfo;
  detailsInfo: IDetailsInfo;
  summaryInfo: ISummaryInfo = {
    utilizeDate: null,
    justification: [
      {
        Question: "",
        Answer: "",
        IsRequired: false,
      } as IJustification,
    ],
    subIds: "",
    newSubToCreate: null,
    subDisplayName: "",
    subTenantId: environment.tenantId,
    subOwnerObjId: "",
    subServiceTreeId: "",
    subServiceTree2ndLevelId: "",
    subPcCode: "",
    pamApprovalState: null,
    isSubmitBtnDisabled: () => true,
  };
  uiRequest: IUIRequest;
  csvFileName = "";
  isFlighting = false;
  teamList: ITeamListRecord[] = [];
  regionList: IRequestRegion[] = [];
  vmSkuList: IRequestVmSku[] = [];
  batchSkuList: IBatchSku[] = [];
  hdiSkusInAllRegions: HdiSku[] = [];
  appServiceSkuList: IAppServiceQuotaUI[] = [];
  restrictedRegionsForCosmosDB: string[] = [];
  namespaces: string[] = namespacesList;
  armResourceTypes: string[] = [];
  vmDisks: IVmDiskQuotaInfo[] = [];
  private allRegion: IRequestRegion[] = [];

  constructor(private apiService: ApiService) {
    this.initDetailsInfo();
  }

  isCustomerRequest(): boolean {
    return this.basicInfo.customerRequest && this.basicInfo.isExternal;
  }

  initBasicInfo(): void {
    this.basicInfo = {
      isExternal: false,
      region: null,
      team: null,
      otherTeam: "",
      otherTeamOid: "",
      customer: "",
      email: "",
      subId: "",
      isRegionChanged: false,
      isNextBtnDisabled: () => true,
      isFlighting: false,
      subscriptions: "",
      customerRequest: false,
      subscriptionsByCSV: false,
    };
  }

  initDetailsInfo(): void {
    this.detailsInfo = {
      azEnablement: false,
      regionEnablement: false,
      regionEnablementARM: false,
      regionEnablementRDFE: false,
      sql: false,
      sqlPurchaseModel: null,
      sqlQuota: null,
      sqlDtu: false,
      sqlServerName: "",
      sqlDtuQuota: null,
      sqlServer: false,
      sqlServerQuota: null,
      sqlMI: false,
      sqlMiType: SqlMiType.Default,
      sqlMiGPvCores: null,
      sqlMiGPCount: null,
      sqlMiBCvCores: null,
      sqlMiBCCount: null,
      sqlMiTotalvCores: null,
      sqlMiSubNet: null,
      hdi: false,
      hdiQuota: null,
      hdiSku: null,
      dns: {
        IsSubsriptionQuota: false,
        IsZoneQuota: false
      },
      requireComputeArmVmQuota: false,
      vmQuota: [],
      deploymentScope: DeploymentScope.Regional,
      requireComputeArmSharedQuota: false,
      computeArmSharedQuota: null,
      requireComputeArmLowPriorityQuota: false,
      computeArmLowPriorityQuota: null,
      requireComputeRdfeVmQuota: false,
      computeRdfeVmQuota: null,
      rdfeVmSku: null,
      additionalStorage: false,
      storageQuotaARM: null,
      storageQuotaRDFE: null,
      batchQuotaType: BatchQuotaType.Account,
      batchTotalAccounts: null,
      batchAccountName: null,
      batchComputeQuota: [],
      requireBatchAccounts: false,
      requireBatchComputeQuota: false,
      azEntities: [],
      cosmosDB: {
        IsAccess: false,
        IsSubscriptionAccounts: false,
        IsAccountQuota: false,
      },
      kusto: false,
      requireAppServiceQuota: false,
      appServiceQuota: this.detailsInfo?.appServiceQuota || [],
      serviceStatus: this.detailsInfo?.serviceStatus || {},
      rpFrontload: false,
      enableArmWithNoQuota: false,
      isValidateBtnDisabled: () => true,
      serviceTreeId: null,
      namespaces: null,
      requireArmResourceType: false,
      armResourceTypes: null,
      requireVmDisks: null,
      vmDisksSku: null,
      vmDisksQuota: null,
    };
  }

  getTeamList(): void {
    // Team List
    this.apiService?.getTeamList().subscribe((response) => {
      if (response) {
        const regionlessTeam = [];
        this.teamList = [];
        response.map((r) => {
          if (r.Ring) {
            this.teamList.push({
              label: `${r.Name} (${r.Oid})`,
              value: 0,
              ring: r.Ring,
              oid: r.Oid,
            } as ITeamListRecord);
          } else {
            regionlessTeam.push({
              label: `${r.Name} (${r.Oid})`,
              value: 0,
              ring: "No Ring",
              oid: r.Oid,
            } as ITeamListRecord);
          }
        });
        this.teamList = this.teamList
          .sort((a, b) => {
            return a.ring?.localeCompare(b.ring) || a.label?.localeCompare(b.label);
          })
          .concat(regionlessTeam.sort((a, b) => a.label?.localeCompare(b.label)));
        this.teamList.push({
          value: 0,
          label: "Other",
          ring: "Other",
          oid: "",
        } as ITeamListRecord);
      }
    });
  }

  getRegionList(): void {
    // Region List
    this.apiService?.getRegionList(false, true).subscribe((response: Region[]) => {
      if (!response) {
        console.warn("No region is obtained from api server.");
      } else {
        this.allRegion = response.filter(region => allowedCommonRegionStatuses.includes(region.Status)).map((r, i) => {
          return ApiService.createRequestRegionFromRegion(r, i + 1);
        });
        this.changeFlighting();
      }
    });
  }

  getSKUList(): void {
    this.vmSkuList = [];
    // VM Skus
    this.apiService?.getVmSkus().subscribe((response) => {
      if (!response) {
        console.warn("No vm sku returned from api");
      } else {
        response.map((r) => {
          if (r.PortalVmSku && r.CrpVmSku) {
            this.vmSkuList.push({
              label: r.PortalVmSku,
              value: r.CrpVmSku,
              checked: false,
              disabled: false,
              skuType: SkuType.Compute,
            } as IRequestVmSku);
          }
          else {
            this.vmSkuList.push({
              label: r.CrpVmSku,
              value: r.CrpVmSku,
              checked: false,
              disabled: false,
              skuType: SkuType.Compute,
            } as IRequestVmSku);
          }
        });

        // Remove duplicated elements
        this.vmSkuList = this.vmSkuList.filter((thing, index, self) => self.findIndex(t => t.label === thing.label && t.value === thing.value) === index);
        this.vmSkuList.sort((a, b) => a.label?.localeCompare(b.label));
      }
    });

    // Hdi skus in all regions
    this.apiService?.getHdiSkus().subscribe((response) => {
      if (!response) {
        console.warn("No Hdi sku is obtained from api server.");
      } else {
        this.hdiSkusInAllRegions = response;
      }
    });

    // Batch skus in all regions
    this.apiService?.getBatchSkus().subscribe((response) => {
      this.batchSkuList = [];
      if (!response) {
        console.warn("No Batch sku is obtained from api server.");
      } else {
        response.map((r) => {
          if (r.PortalVmSku && r.CrpVmSku) {
            this.batchSkuList.push({
              region: r.RegionName,
              requestType: BatchRequestType.Dedicated,
              label: r.PortalVmSku,
              disabled: false,
              sku: r.CrpVmSku,
            } as IBatchSku);
          }
        });

        // Batch Low priority SKU
        this.batchSkuList.push({
          region: "all",
          requestType: BatchRequestType.LowPriority,
          label: "Low Priority Quota",
          disabled: false,
          sku: "Low Priority Quota",
        } as IBatchSku);

        // Batch account pool quota
        this.batchSkuList.push({
          region: "all",
          requestType: BatchRequestType.PoolQuota,
          label: "Pools per Batch Account",
          disabled: false,
          sku: "Pools per Batch Account",
        } as IBatchSku);

        // Batch account job quota
        this.batchSkuList.push({
          region: "all",
          requestType: BatchRequestType.JobQuota,
          label: "Active Jobs Schedule Per Batch Account",
          disabled: false,
          sku: "Active Jobs Schedule Per Batch Account",
        } as IBatchSku);
      }
    });

    this.apiService?.getAppServiceSkus().subscribe((response) => {
      if (!response) {
        console.warn("No app service sku is obtained from api server.");
      } else {
        this.appServiceSkuList = [];
        response.forEach((sku) => {
          this.appServiceSkuList.push({
            offering: sku,
          } as IAppServiceQuotaUI);
        });
      }
    });
  }

  GetCosmosDBAzRestrictedRegions(): void {
    this.apiService?.getRestrictedRegionListForCosmosDB().subscribe((regions) => {
      if (regions && regions.length > 0) {
        this.restrictedRegionsForCosmosDB = regions;
      }
    });
  }

  changeFlighting(): void {
    this.regionList = this.allRegion.filter((r) => r.isEnabled || (this.isFlighting && r.isFlighting));
    if (!this.regionList.includes(this.basicInfo.region)) {
      this.basicInfo.region = null;
    }
  }
}
