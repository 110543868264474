import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AgsAuthService } from "@ags/core";

import { LoadingScreenService } from "../shared/loading-screen.service";
import { ApiService } from "../services";
import { Roles } from "../npr-request.model";
import { Observable } from "rxjs/internal/Observable";
import { SharedDataService } from "../services/sharedDataService";
import { Subscription } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class LandingGuard implements CanActivate {
  constructor(private readonly authService: AgsAuthService, private readonly loadingScreenService: LoadingScreenService) {}

  async canActivate() {
    if (this.authService.getAccount()) {
      this.authService.acquireToken();
      return true;
    }

    this.loadingScreenService.setLoading(true);
    this.authService.acquireToken();
    return false;
  }
}

/** TODO(lingc): refactor this file.
 * 1. Here are multiple classes named like IsXXXRole. The code logic is highly duplicated in those classes
 * 2. The logic to retrieve the userProfile is very tricky. We use to have a problem that our UI will initiate duplicated api calls to get the user profile. 
 *    The sharedDataService is to resolve the duplication, but the fix make the code hard to read 
 * */ 


@Injectable({
  providedIn: "root",
})
export class IsAdmin implements CanActivate {
  private subscription: Subscription;
  constructor(private apiService: ApiService, private router: Router, private sharedDataService: SharedDataService) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      if (!ApiService.userProfile) {
        this.subscription = this.sharedDataService.getUserProfile().subscribe((data) => {
          const userProfile = data;
          if (userProfile?.IsAdmin) {
            resolve(true);
          } else {
            // Just return to new request page.
            this.router.navigate(["create-request"]);
            resolve(false);
          }
        });
      } else {
        this.apiService.getUserProfile().then((response) => {
          const userProfile = response;
          if (userProfile?.IsAdmin) {
            resolve(true);
          } else {
            // Just return to new request page.
            this.router.navigate(["create-request"]);
            resolve(false);
          }
        });
      }
    });
  }
}

@Injectable({
  providedIn: "root",
})
export class IsApproverAdmin implements CanActivate {
  private subscription: Subscription;
  constructor(private apiService: ApiService, private router: Router, private sharedDataService: SharedDataService) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      if (!ApiService.userProfile) {
        this.subscription = this.sharedDataService.getUserProfile().subscribe((data) => {
          const userProfile = data;
          if (userProfile && userProfile.UserRoles.some((role) => role.Role === Roles.Administrator || role.Role === Roles.ApproverAdmin)) {
            resolve(true);
          } else {
            // Just return to new request page.
            this.router.navigate(["create-request"]);
            resolve(false);
          }
        });
      } else {
        this.apiService.getUserProfile().then((response) => {
          const userProfile = response;
          if (userProfile && userProfile.UserRoles.some((role) => role.Role === Roles.Administrator || role.Role === Roles.ApproverAdmin)) {
            resolve(true);
          } else {
            // Just return to new request page.
            this.router.navigate(["create-request"]);
            resolve(false);
          }
        });
      }
    });
  }
}

@Injectable({
  providedIn: "root",
})
export class IsApprover implements CanActivate {
  private subscription: Subscription;
  constructor(private apiService: ApiService, private router: Router, private sharedDataService: SharedDataService) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      if (!ApiService.userProfile) {
        this.subscription = this.sharedDataService.getUserProfile().subscribe((data) => {
          const userProfile = data;
          if (
            userProfile &&
            userProfile.UserRoles.some(
              (role) => role.Role === Roles.Administrator || role.Role === Roles.ApproverAdmin || role.Role === Roles.Approver
            )
          ) {
            resolve(true);
          } else {
            // Just return to new request page.
            this.router.navigate(["create-request"]);
            resolve(false);
          }
        });
      } else {
        this.apiService.getUserProfile().then((response) => {
          const userProfile = response;
          if (
            userProfile &&
            userProfile.UserRoles.some(
              (role) => role.Role === Roles.Administrator || role.Role === Roles.ApproverAdmin || role.Role === Roles.Approver
            )
          ) {
            resolve(true);
          } else {
            // Just return to new request page.
            this.router.navigate(["create-request"]);
            resolve(false);
          }
        });
      }
    });
  }
}

@Injectable({
  providedIn: "root",
})
export class IsGETApprover implements CanActivate {
  private subscription: Subscription;
  constructor(private apiService: ApiService, private router: Router, private sharedDataService: SharedDataService) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      if (!ApiService.userProfile) {
        this.subscription = this.sharedDataService.getUserProfile().subscribe((data) => {
          const userProfile = data;
          if (userProfile && userProfile.IsGETApprover) {
            resolve(true);
          } else {
            // Just return to new request page.
            this.router.navigate(["create-request"]);
            resolve(false);
          }
        });
      } else {
        this.apiService.getUserProfile().then((response) => {
          const userProfile = response;
          if (userProfile && userProfile.IsGETApprover) {
            resolve(true);
          } else {
            // Just return to new request page.
            this.router.navigate(["create-request"]);
            resolve(false);
          }
        });
      }
    });
  }
}

@Injectable({
  providedIn: "root",
})
export class IsGenevaActionsReader implements CanActivate {
  private subscription: Subscription;
  constructor(private apiService: ApiService, private router: Router, private sharedDataService: SharedDataService) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      if (!ApiService.userProfile) {
        this.subscription = this.sharedDataService.getUserProfile().subscribe((data) => {
          const userProfile = data;
          if (userProfile && userProfile.IsGenevaActionsReader) {
            resolve(true);
          } else {
            // Just return to new request page.
            this.router.navigate(["create-request"]);
            resolve(false);
          }
        });
      } else {
        this.apiService.getUserProfile().then((response) => {
          const userProfile = response;
          if (userProfile && userProfile.IsGenevaActionsReader) {
            resolve(true);
          } else {
            // Just return to new request page.
            this.router.navigate(["create-request"]);
            resolve(false);
          }
        });
      }
    });
  }
}

@Injectable({
  providedIn: "root",
})
export class IsSelfService implements CanActivate {
  private subscription: Subscription;
  constructor(private apiService: ApiService, private router: Router, private sharedDataService: SharedDataService) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      if (!ApiService.userProfile) {
        this.subscription = this.sharedDataService.getUserProfile().subscribe((data) => {
          const userProfile = data;
          if (userProfile && userProfile.IsSelfService) {
            resolve(true);
          } else {
            // Just return to new request page.
            this.router.navigate(["create-request"]);
            resolve(false);
          }
        });
      } else {
        this.apiService.getUserProfile().then((response) => {
          const userProfile = response;
          if (userProfile && userProfile.IsSelfService) {
            resolve(true);
          } else {
            // Just return to new request page.
            this.router.navigate(["create-request"]);
            resolve(false);
          }
        });
      }
    });
  }
}

@Injectable({
  providedIn: "root",
})
export class IsPfAdmin implements CanActivate {
  private subscription: Subscription;
  constructor(private apiService: ApiService, private router: Router, private sharedDataService: SharedDataService) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      if (!ApiService.userProfile) {
        this.subscription = this.sharedDataService.getUserProfile().subscribe((data) => {
          const userProfile = data;
          /**
           * Can't check IsPfAdmin and IsAdmin in 2 separate implementations of CanActive.
           * If so, it will be routed to the "create-request" page any any of the 2 checks failed, which is unexpected.
           */
          if (userProfile && (userProfile.IsPfAdmin || userProfile.IsAdmin)) {
            resolve(true);
          } else {
            // Just return to new request page.
            this.router.navigate(["create-request"]);
            resolve(false);
          }
        });
      } else {
        this.apiService.getUserProfile().then((response) => {
          const userProfile = response;
          if (userProfile && (userProfile.IsPfAdmin || userProfile.IsAdmin)) {
            resolve(true);
          } else {
            // Just return to new request page.
            this.router.navigate(["create-request"]);
            resolve(false);
          }
        });
      }
    });
  }
}

@Injectable({
  providedIn: "root",
})
export class IsTestOrDevEnvironment implements CanActivate {
  constructor() {}

  async canActivate() {
    if (environment.production) {
      return false;
    }
    return true;
  }
}
