import { ColumnApi, FilterChangedEvent, GridApi, GridOptions } from "ag-grid-community";

export function extendDefaultOptions(options: GridOptions): GridOptions {
  return {
    defaultColDef: {
      headerCheckboxSelectionFilteredOnly: true,
      filter: "agSetColumnFilter",
      resizable: true,
      sortable: true,
    },
    enableCellTextSelection: true,
    overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    sideBar: {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressSideButtons: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
          },
        },
      ],
      position: "left", //not working in this version of ag-grid
    },
    ...options,
  };
}

export function agDateColumnFilter(filterLocalDateAtMidnight: Date, cellValue: string): number {
  // cellValue has two DateTime format, should handle both
  // 1: "2022/01/01 11:22:33"
  // 2: "2022-02-22T14:18:42.5172882+00:00"
  const year = Number(cellValue.substring(0, 4));
  const month = Number(cellValue.substring(5, 7)) - 1;
  const day = Number(cellValue.substring(8, 10));
  const cellDate = new Date(year, month, day);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  } else {
    return 0;
  }
}

/**
 * Store grids' filter stats at localStorage by a specified unique key.
 * This function should be invoked when filterChanged event is triggered.
 *
 * Example, bind it when configuring grid:
 *
 *   gridOptions {
 *     ...
 *     onFilterChanged: (event) => storeFilterStatsLocal(event, $UniqueFilterKey),
 *     ...
 *   }
 *
 * @param event currently triggered FilterChangedEvent.
 * @param key is paired with filter-status value, should be unique to avoid conflict.
 * @param filterDataChangeEvent data model changes sometimes produce FilterChangedEvent
 * and clear filter settings. Set true to filter this type of event.
 */
export function cacheFilterStatsLocal(event: FilterChangedEvent, key: string, filterDataChangeEvent = true) {
  if (filterDataChangeEvent && !event.afterDataChange) {
    // ensure that filter attributes are selected by user.
    event.api.deselectAll();
    const filterModel = event.api.getFilterModel();
    const savedFilters = JSON.stringify(filterModel);
    const keys = Object.keys(filterModel);
    if (keys.length > 0) {
      localStorage.setItem(key, savedFilters);
    } else {
      localStorage.removeItem(key);
    }
  }
}

/**
 * A default method to set up the filters' stats specified by gridApi and gridColumnApi.
 *
 * Before employing setupFilterByLocalData, a valid filter stats model should
 * cache at localStorage with given key.
 *
 * @param gridApi reference of grid to modify.
 * @param gridColumnApi reference of grid column to modify.
 * @param key is paired with filter-status value.
 * @param autoSizeColumns some grids look better with fixed column settings and parameters.
 */
export function setupFilterByLocalData(gridApi: GridApi, gridColumnApi: ColumnApi, key: string, autoSizeColumns = true): void {
  let filterInstance;
  setTimeout(() => {
    if (autoSizeColumns) {
      gridColumnApi?.autoSizeAllColumns();
    }
    const filters = JSON.parse(localStorage.getItem(key) || "{}");
    Object.entries(filters).forEach(([key, value]) => {
      // filter empty model, this resulted in virtual list error of Angular-grid
      if (value != null && value != [] && Object.keys(value).length !== 0) {
        filterInstance = gridApi?.getFilterInstance(key);
        if (filterInstance != null) {
          filterInstance.setModel(value);
          gridApi?.onFilterChanged();
        }
      }
    });
  }, 1000);
}
