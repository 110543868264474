import { Component } from "@angular/core";
import { ColumnApi, ExcelExportParams, ExcelStyle, GridApi, GridOptions, SelectionChangedEvent } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ApprovablePlansColumnDefinition } from "./ag-grid-column-definition";
import { PlanRegion } from "src/app/generated-models/PlanRegion";
import { PlanRegionStatusEnum } from "src/app/shared/enums/plan-enums";
import { GridViewBaseComponent } from "../../utility/grid-view-base.component";
import { ColParams } from "src/app/shared/grid/ag-grid";
import { ModalService } from "../../shared/modal.service";
import { SharedDataService } from "../../services/sharedDataService";
import { getExportedExcelFileNameSuffix } from "src/app/utility/common-helper";

@Component({
  templateUrl: "./plans-approvable.component.html",
  styleUrls: ["../../styles.scss", "./plan.scss"],
})
export class PlansApprovableComponent extends GridViewBaseComponent {
  gridApi: GridApi;
  gridOptions: GridOptions;
  gridColumnApi: ColumnApi;
  initPageSize: number = 15;
  showRejected: boolean = false;
  keyFilterStats = "filter:approve-regional-plans";
  rowData: PlanRegion[] = [];
  headerStyle: ExcelStyle[] = [
    {
      id: "header",
      font: {
        bold: true,
      },
    },
  ];

  selectedRowCount = 0;
  loadCheckBox = false;

  constructor(
    protected apiService: ApiService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService,
    protected sharedDataService: SharedDataService,
    protected modalService: ModalService
  ) {
    super(apiService, sharedDataService);
  }

  ngOnInit() {
    ApprovablePlansColumnDefinition.push({
      colId: "customFilter",
      headerName: "Custom Filter",
      valueGetter: (params: ColParams<PlanRegion, void>) => {
        if (!this.showRejected) {
          const row = params.data as PlanRegion;
          return row.Status === PlanRegionStatusEnum.Approvable;
        }

        return true;
      },
      filterParams: {
        values: ["true", "false"],
        newRowsAction: "keep",
      },
      hide: true,
    });
    super.InitAgGrid(ApprovablePlansColumnDefinition);
  }

  onGridReady(params: GridOptions): void {
    super.onGridReady(params);
    this.gridApi.setFilterModel({
      customFilter: ["true"],
    });
  }

  onShowRejectedChanged(): void {
    this.gridApi.onFilterChanged();
  }

  refreshData() {
    this.selectedRowCount = 0;
    this.loadingService.setLoading(true);
    this.apiService.getApprovableRegionalPlans().subscribe(
      (response) => {
        this.rowData = response;
        this.loadingService.setLoading(false);
        super.setupFilterByLocalData();
      },
      (e: any) => {
        this.rowData = [];
        this.loadingService.setLoading(false);
        this.notificationService.error(e);
      }
    );
    this.setActionCheckBoxAttributes();
    // this.apiService.getRegionList().subscribe(
    //   (regions) => {
    //     this.supportedRegions = regions.filter(r => r.ArmRegionName == 'qatarcentral');
    //     this.region = this.supportedRegions[0].ArmRegionName;
    //   }
    // );
  }

  async batchApprovePlan(formValues?: PlanRegion[]) {
    const selectedPlanRegion: PlanRegion[] = formValues ? formValues : this.gridApi.getSelectedRows();
    if (!selectedPlanRegion && selectedPlanRegion.length === 0) {
      this.message = "Please select Plan Region(s) to approve";
      this.notificationService.warning(this.message);
      return;
    }
    const needRefresh: boolean = await this.modalService.planBatchApprovalModal("Approve Plan Region(s)", selectedPlanRegion);
    if (needRefresh) {
      this.refreshData();
    }
  }

  onSelectionChanged() {
    this.selectedRowCount = this.gridApi.getSelectedNodes().length;
  }

  showBatchApproval() {
    if (this.userProfile) {
      return this.userProfile.IsAdmin;
    }
    return false;
  }

  // setActionCheckBoxAttributes call only once by loadCheckBox flag
  setActionCheckBoxAttributes() {
    if (this.showBatchApproval() && ApprovablePlansColumnDefinition?.length && !this.loadCheckBox) {
      const columnDefs = [];
      this.gridOptions.columnDefs.map((map) => {
        if (map.headerName === "Action") {
          columnDefs.push({
            ...map,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
          });
        } else {
          columnDefs.push(map);
        }
      });

      this.gridOptions.api.setColumnDefs(columnDefs);
      this.loadCheckBox = true;
    }
  }

  exportExcel() {
    const params = {
      exportMode: "xlsx",
      skipHeader: false,
      columnGroups: false,
      skipGroups: false,
      skipFooters: false,
      skipPinnedTop: false,
      skipPinnedBottom: false,
      allColumns: false,
      onlySelected: false,
      onlySelectedAllPages: false,
      fileName: "ApprovableRegionalPlans-" + getExportedExcelFileNameSuffix(),
      sheetName: "Approvable Regional Plans",
      columnKeys: this.gridOptions.columnApi.getAllColumns().filter((c) => c.getColDef().colId !== "action"),
    } as ExcelExportParams;

    this.loadingService.setLoading(true);
    this.gridApi.exportDataAsExcel(params);
    this.loadingService.setLoading(false);
  }
}
