import { ColDef, ColGroupDef } from "ag-grid-community";
import { CapacityOrder } from "src/app/generated-models/CapacityOrder";
import { Plan } from "src/app/generated-models/Plan";
import { PlanRegion } from "src/app/generated-models/PlanRegion";
import { ApiService } from "src/app/services";
import { ColParams } from "src/app/shared/grid/ag-grid";
import { DynamicHeaderComponent } from "src/app/shared/grid/dynamic-header.component";
import { LinkCellComponent } from "src/app/shared/grid/link-cell.component";
import { TooltipHeaderComponent } from "src/app/shared/grid/tooltip-header.component";
import * as CDs from "src/app/utility/commonColDef";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";
import { RefreshRendererComponent } from "../admin-view/refresh-renderer.component";
import { PlannedQuotaRequest } from "src/app/generated-models/PlannedQuotaRequest";
import { getPlanRegionStatusDescription } from "src/app/utility/common-helper";
import { ISubOrderDetail } from "../../npr-request.model";

export const viewApprovalCellComponentParams = {
  value: "Review",
  target: "_blank",
  url: (params: ColParams<PlanRegion, string>) =>
    `/quota/plans/services/${params.data.ServiceTreeId}/regions/${params.data.Region}/versions/${params.data.Version}/stages/${params.data.Stage}/review`,
};

export const viewRegionalPlanDetailCellComponentParams = {
  target: "_blank",
  url: (params: ColParams<PlanRegion, number>) =>
    `/quota/plans/services/${params.data.ServiceTreeId}/regions/${params.data.Region}/versions/${params.data.Version}/stages/${params.data.Stage}/detail`,
};

export const viewRegionalPlanDetailFromQuotaRequestCellComponentParams = {
  target: "_blank",
  url: (params: ColParams<PlannedQuotaRequest, number>) =>
    `/quota/plans/services/${params.data.ServiceTreeId}/regions/${params.data.Region}/versions/${params.data.PlanVersion}/stages/${params.data.Stage}/detail`,
};

export const viewRegionAgnosticPlanHistoryCellComponentParams = {
  target: "_blank",
  url: (params: ColParams<Plan, number>) => `/quota/plans/services/${params.data.ServiceTreeId}/versions/${params.data.Version}`,
};

export const viewPlanStatusComponentParams = {
  target: "_blank",
  url: (params: ColParams<any, number>) =>
    `/quota/plans/regions/${params.data.Region}/services/${params.data.ServiceTreeId}/stages/${params.data.Stage}/status`,
};

export const viewCapacitySubOrderComponentParams = {
  value: "Test",
  target: "_blank",
  url: (params: ColParams<ISubOrderDetail, string>) =>
    `/quota/plans/capacityOrder/orders/${params.data.OrderId}/subOrders/${params.data.SubOrderId}`,
}

export const ApprovablePlansColumnDefinition: ColDef[] = [
  {
    headerName: "Action",
    colId: "action",
    sortable: false,
    suppressMenu: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    cellRendererFramework: LinkCellComponent,
    cellRendererParams: viewApprovalCellComponentParams,
  },
  CDs.RegionColDef("Region"),
  CDs.Ring,
  CDs.ServiceTeam,
  CDs.ServiceTreeId,
  {
    ...CDs.Version,
    cellRendererFramework: LinkCellComponent,
    cellRendererParams: viewRegionalPlanDetailCellComponentParams,
  },
  CDs.Stage,
  // {
  //   headerName: "Latest Version",
  //   field: "LatestVersion",
  //   cellRendererFramework: LinkCellComponent,
  //   cellRendererParams: viewDetailCellComponentParams,
  // },
  // {
  //   headerName: "Approved Version",
  //   field: "ApprovedVersion",
  //   cellRendererFramework: LinkCellComponent,
  //   cellRendererParams: viewDetailCellComponentParams,
  // },
  CDs.Submitter,
  CDs.SubmitTime("Assigned Time"),
  CDs.ApprovedBy,
  CDs.ApprovedTime,
  CDs.Purpose,
  CDs.ValidTo,
  CDs.Status,
  CDs.BuildoutStartDate,
];

export const planEditorCellComponentParams = {
  target: "_blank",
  url: (params: ColParams<PlanRegion, string>) => `/quota/plans/services/${params.data.ServiceTreeId}/editor`,
};

export const planVersionCellComponentParams = {
  target: "_blank",
  url: (params: ColParams<PlanRegion, string>) =>
    `/quota/plans/services/${params.data.ServiceTreeId}/regions/${params.data.Region}/versions/${params.data.Version}/stages/${params.data.Stage}/detail`,
};

export const capacityOrderCellComponentParams = {
  target: "_blank",
  url: (params: ColParams<CapacityOrder, string>) => `/quota/plans/capacityOrder/${params.data.CapacityOrderId}`,
};

export const PlansColumnDefinition: ColDef[] = [
  {
    ...CDs.ServiceTreeId,
    cellRendererFramework: LinkCellComponent,
    cellRendererParams: planEditorCellComponentParams,
  },
  CDs.ServiceTeam,
  CDs.Ring,
  CDs.LatestCommitVersion,
  CDs.SubmitTime("Submit Time", "CreatedTime"),
  CDs.CreatedBy,
  CDs.CustomFilter,
];

export const PlansNeedSignoffColumnDefinition: ColDef[] = [
  CDs.ServiceTreeId,
  CDs.ServiceTeam,
  CDs.RegionColDef("Region"),
  {
    ...CDs.Version,
    cellRendererFramework: LinkCellComponent,
    cellRendererParams: viewRegionalPlanDetailCellComponentParams,
  },
  CDs.Stage,
  CDs.Status,
  CDs.Purpose,
  CDs.Submitter,
  CDs.SubmitTime("Assigned Time"),
];

export const PlansMyRegionalPlanColumnDefinition: ColDef[] = [
  CDs.ServiceTreeId,
  CDs.ServiceTeam,
  CDs.RegionColDef("Region"),
  CDs.Ring,
  {
    ...CDs.Version,
    cellRendererFramework: LinkCellComponent,
    cellRendererParams: planVersionCellComponentParams,
  },
  CDs.Stage,
  {
    ...CDs.Status,
    tooltipValueGetter: (params) => {
      return getPlanRegionStatusDescription(params.data.Status)?.description || params.data.Status;
    },
  },
  CDs.Purpose,
  CDs.Submitter,
  CDs.SubmitTime(),
  CDs.ApprovedBy,
  CDs.ApprovedTime,
  CDs.ValidTo,
  CDs.ValidToDate,
  CDs.BuildoutStartDate,
];

export const PlansDiffColumnDefinition: ColDef[] = [
  {
    headerName: "Deployment Model",
    field: "DeploymentModel",
  },
  {
    headerName: "Service",
    field: "Service",
  },
  CDs.Sku,
  {
    headerName: "Unit",
    field: "Unit",
  },
  CDs.IsHobo,
  {
    headerName: "Available In Region",
    field: "IsAvailable",
  },
  {
    headerName: "New",
    field: "NewQuota",
  },
  {
    headerName: "Approved",
    field: "ApprovedQuota",
  },
  {
    headerName: "Pre-Approved in ProdM/PAM",
    field: "PreApprovedQuota",
    headerComponentFramework: DynamicHeaderComponent as new () => any,
    headerComponentParams: {
      component: TooltipHeaderComponent,
      tooltip:
        "This is the pre-approved quota for the service and the quota type in ProdM (https://aka.ms/prodm) and PAM (https://aka.ms/pamurl).",
    },
  },
  {
    headerName: "Diff",
    field: "Diff",
  },
];

export const CapacityOrderColumnDefinition: ColDef[] = [
  {
    headerName: "Order Id",
    field: "CapacityOrderId",
    cellRendererFramework: LinkCellComponent,
    cellRendererParams: capacityOrderCellComponentParams,
  },
  {
    headerName: "Status",
    field: "Status",
  },
  CDs.IsHobo,
];

export const PlanRegionApprovalsColumnDefinition: ColDef[] = [
  {
    headerName: "Resource",
    field: "Resource",
  },
  CDs.CreatedTime,
  CDs.ApprovedTime,
  {
    headerName: "ApprovedBy",
    field: "ApprovedBy",
  },
  {
    headerName: "Status",
    field: "Status",
  },
];

export const ServiceTeamUserColumnDefinition: ColDef[] = [
  {
    headerName: "Service",
    field: "ServiceName",
  },
  CDs.Ring,
  CDs.ServiceTreeId,
  {
    headerName: "User Email",
    field: "Upn",
  },
  {
    headerName: "User Role",
    field: "Role",
  },
  CDs.CreatedBy,
  {
    ...CDs.CreatedTime,
    cellRenderer: (params) => PreprocessDateTime(params.value),
  },
];

export const PlansRegionalQuotaInfoColumnDefinition: (ColDef | ColGroupDef)[] = [
  {
    children: [
      {
        headerName: "Deployment Model",
        field: "DeploymentModel",
      },
      {
        headerName: "Service",
        field: "Service",
      },
      CDs.Sku,
      {
        headerName: "Unit",
        field: "Unit",
      },
    ],
  },
  {
    headerName: "Planned",
    children: [
      {
        headerName: "Approved",
        field: "ApprovedPlannedQuotaTotal",
      },
      {
        headerName: "Pending",
        field: "PendingPlannedQuotaTotal",
      },
    ],
  },
  {
    headerName: "Ad hoc",
    children: [
      {
        headerName: "Approved",
        field: "ApprovedAdHocQuotaTotal",
      },
      {
        headerName: "Pending",
        field: "PendingAdHocQuotaTotal",
      },
    ],
  },
];

export const PlansAllRegionalPlanColumnDefinition: ColDef[] = [
  {
    ...CDs.ServiceTreeId,
    checkboxSelection: false,
    headerCheckboxSelection: false,
  },
  CDs.ServiceTeam,
  CDs.RegionColDef("Region"),
  CDs.Ring,
  {
    ...CDs.Version,
    cellRendererFramework: LinkCellComponent,
    cellRendererParams: viewRegionalPlanDetailCellComponentParams,
  },
  CDs.Stage,
  {
    ...CDs.Status,
    tooltipValueGetter: (params) => {
      return getPlanRegionStatusDescription(params.data.Status)?.description || params.data.Status;
    },
  },
  CDs.Purpose,
  CDs.Submitter,
  CDs.SubmitTime("Assigned Time"),
  CDs.ApprovedBy,
  CDs.ApprovedTime,
  CDs.ValidTo,
  CDs.ValidToDate,
  CDs.BuildoutStartDate,
];

export const PlannedQuotaPropertyBindingColumnDefinition: ColDef[] = [
  { ...CDs.RegionColDef("Region") },
  CDs.ServiceTreeId,
  { ...CDs.SubscriptionKey },
  { ...CDs.BindingKey },
  { ...CDs.BindingType },
  { ...CDs.BindingValue },
];

export const PlannedQuotaSubscriptionBindingColumnDefinition: ColDef[] = [
  { ...CDs.RegionColDef("Region") },
  CDs.ServiceTreeId,
  { ...CDs.SubscriptionKey },
  { ...CDs.SubscriptionId("SubscriptionId") },
];

export const PlannedQuotaStatus: ColDef[] = [
  CDs.RegionColDef("Region"),
  CDs.ServiceTeam,
  CDs.Ring,
  {
    ...CDs.ServiceTreeId,
    cellRendererFramework: LinkCellComponent,
    cellRendererParams: viewPlanStatusComponentParams,
  },
  CDs.Stage,
  CDs.SubscriptionBindings,
  CDs.PropertyBindings,
  CDs.Status,
];

export const RegionalPlanStatus: { [key: string]: ColDef[] } = {
  PropertyBindingColumnDefinition: [CDs.SubscriptionKey, CDs.BindingKey, CDs.BindingType, CDs.BindingValue],

  SubscriptionBindingColumnDefinition: [CDs.SubscriptionKey, CDs.SubscriptionId("SubscriptionId")],

  PlanRegionColumnDefinition: [
    {
      ...CDs.Version,
      cellRendererFramework: LinkCellComponent,
      cellRendererParams: viewRegionalPlanDetailCellComponentParams,
    },
    CDs.Status,
    CDs.Stage,
    CDs.Submitter,
    CDs.SubmitTime("Assigned Time"),
    CDs.ApprovedBy,
    CDs.ApprovedTime,
    CDs.Purpose,
    CDs.ValidTo,
    CDs.ValidToDate,
  ],

  RequestColumnDefinition: [
    {
      ...CDs.RequestId,
      resizable: true,
      sortable: true,
      cellRenderer: (params) => ApiService.generatePlannedQuotaRequestHyperLink(params.value),
    },
    CDs.SubscriptionId("SubscriptionId"),
    CDs.SubscriptionKey,
    CDs.Stage,
    CDs.Status,
    CDs.RequestSource,
    {
      ...CDs.CreatedTime,
      cellRenderer: (params) => PreprocessDateTime(params.value),
    },
    {
      ...CDs.CompletedTime,
      cellRenderer: (params) => PreprocessDateTime(params.value),
    },
  ],
};

export const CapacityOrderResourceProviderColumnDefinition = [
  {
    headerName: "Resource Provider",
    field: "ResourceProvider",
    cellRenderer: "agGroupCellRenderer",
  },
  {
    headerName: "Resource Number",
    field: "ResourceNumber",
    resizable: true,
    sortable: true,
    filter: "agSetColumnFilter",
  },
];

export const CapacityOrderResourceColumnDefinition = [
  {
    headerName: "Location",
    field: "Location",
    cellRenderer: "agGroupCellRenderer",
  },
  {
    headerName: "Resource Detail Number",
    field: "ResourceDetailNumber",
    resizable: true,
    sortable: true,
    filter: "agSetColumnFilter",
  },
];

export const CapacityOrderResourceDetailColumnDefinition = [
  {
    headerName: "Request Resources",
    children: [
      {
        headerName: "Resource Name",
        field: "ResourceName",
      },
      {
        headerName: "Limits",
        field: "Limits",
        resizable: true,
        sortable: true,
        filter: "agSetColumnFilter",
      },
      {
        headerName: "Unit",
        field: "Unit",
        resizable: true,
        sortable: true,
        filter: "agSetColumnFilter",
      },
      {
        headerName: "Provider Specific Properties",
        field: "ProviderSpecificProperties",
      },
    ],
  },
  {
    headerName: "Alternate Resources Number",
    field: "AlternateResourcesNumber",
    cellRenderer: "agGroupCellRenderer",
  },
  {
    headerName: "Approved Resource",
    field: "ApprovedResource",
    resizable: true,
    sortable: true,
    filter: "agSetColumnFilter",
  },
  CDs.Status,
];

export const CapacityOrderResourceDetailAlternateResourceColumnDefinition = [
  {
    headerName: "Alternate Resources",
    children: [
      {
        headerName: "Resource Name",
        field: "ResourceName",
        resizable: true,
        sortable: true,
        filter: "agSetColumnFilter",
      },
      {
        headerName: "Limits",
        field: "Limits",
        resizable: true,
        sortable: true,
        filter: "agSetColumnFilter",
      },
      {
        headerName: "Unit",
        field: "Unit",
        resizable: true,
        sortable: true,
        filter: "agSetColumnFilter",
      },
      {
        headerName: "Provider Specific Properties",
        field: "ProviderSpecificProperties",
        resizable: true,
        sortable: true,
        filter: "agSetColumnFilter",
      },
    ],
  },
];

export const CapacityOrderMetadataColumnDefinition = [
  {
    headerName: "Metadata Key",
    field: "MetadataKey",
  },
  {
    headerName: "Metadata Value",
    field: "MetadataValue",
  },
];

export const CapacityOrderViewColumnDefinition: ColDef[] = [
  {
    headerName: "Order Id",
    field: "CapacityOrderId",
    sortable: true,
    cellRenderer: "agGroupCellRenderer",
    cellRendererParams: {
      suppressCount: true, // turn off the row count
      suppressDoubleClickExpand: true, // turn off double click for expand
      innerRenderer: (params) => ApiService.generateCapacityOrderHyperLink(params.data.CapacityOrderId),
    },
  },

  CDs.ServiceTreeId,
  CDs.ServiceTeam,
  CDs.Ring,
  CDs.RegionColDef("Region"),
  CDs.Version,
  CDs.Stage,
  {
    headerName: "Status",
    field: "Status",
  },
  CDs.CreatedTime,
  CDs.ExpiryDate,
  CDs.IsHobo,
  {
    headerName: "Action",
    colId: "action",
    cellRendererFramework: RefreshRendererComponent,
    pinned: "right",
  },
];

export const CapacityOrderRegionalPlanViewColumnDefinition: ColDef[] = [
  CDs.Status,
  CDs.Submitter,
  CDs.SubmitTime("Assigned Time"),
  CDs.ApprovedBy,
  CDs.ApprovedTime,
  CDs.Purpose,
  CDs.ValidTo,
  CDs.ValidToDate,
  CDs.BuildoutStartDate,
  {
    headerName: "Action",
    colId: "action",
    cellRendererFramework: LinkCellComponent,
    cellRendererParams: {
      ...viewRegionalPlanDetailCellComponentParams,
      value: "Plan Detail",
    },
    pinned: "right",
  },
];

export const CapacitySubOrdersColumnDefinition = [
  {
    headerName: "SubOrder Id",
    field: "SubOrderId",
  },
  {
    headerName: "Resource Provider",
    field: "ResourceProvider",
  },
  {
    headerName: "Location",
    field: "Location",
  },
  {
    headerName: "Resource Name",
    field: "ResourceName",
  },
  {
    headerName: "Limits",
    field: "Limits",
    resizable: true,
    sortable: true,
    filter: "agSetColumnFilter",
  },
  {
    headerName: "Unit",
    field: "Unit",
    resizable: true,
    sortable: true,
    filter: "agSetColumnFilter",
  },
  {
    headerName: "Provider Specific Properties",
    field: "ProviderSpecificProperties",
  },
  {
    headerName: "Approved Resource",
    field: "ApprovedResource",
    resizable: true,
    sortable: true,
    filter: "agSetColumnFilter",
  },
  {
    headerName: "Status",
    field: "Status",
    cellRendererFramework: LinkCellComponent,
    cellRendererParams: viewCapacitySubOrderComponentParams,
  }
];
