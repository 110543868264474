<a [routerLink]="commands" [queryParams]="queryParams" [replaceUrl]="replaceUrl" *ngIf="commands; else url_template" [title]="value">{{
  value
}}</a>
<ng-template #url_template>
  <a [href]="url" [target]="target" *ngIf="url; else other_content" [title]="value">{{ value }}</a>
</ng-template>

<ng-template #other_content
  ><span>{{ value }}</span></ng-template
>
