<div class="title" #title>
  <div class="container container-max-width">
    <div>
      <h4>Assign Plan to Region</h4>
      <p>Assign your service to one or more regions</p>
    </div>
    <div class="action-container">
      <div class="form-check form-check-inline ml-4 toggle">
        <input
          type="checkbox"
          class="form-check-input"
          id="hideAlreadyAssignedSwitch"
          [(ngModel)]="hideAlreadyAssigned"
          (change)="onHideAlreadyAssignedChanged()" />
        <label class="form-check-label" for="hideAlreadyAssignedSwitch"> Hide non-assignable </label>
      </div>
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="!canAssign"
        (click)="showPlanAssignmentDialog()"
        data-test="AssignPlan">
        Assign
      </button>
    </div>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main">
    <div class="top-panel" style="width: 100%">
      <ng-select
        name="Plan"
        bindLabel="Label"
        [(ngModel)]="selectedPlan"
        (ngModelChange)="onPlanChanged()"
        placeholder="Select your service"
        [items]="plans"
        [virtualScroll]="true"
        required>
      </ng-select>
    </div>
    <p *ngIf="selectedPlan">
      The latest plan version for {{ selectedPlan.ServiceTeam }} is <b>{{ selectedPlan.Version }}</b
      >.
    </p>
    <p *ngIf="selectedPlan && assignTooManyRegions && hasRowSelected">
        <strong style="color: red;">Assign plan to at most {{maxRegionNumOneTime}} regions at a time.</strong>
    </p>
    <p *ngIf="selectedPlan && assignMultiStages && hasRowSelected">
      <strong style="color: red;">Cannot assign plan with multi-stages at a time.</strong>
    </p>
    <div>
      <ag-grid-angular
        #agGrid
        id="ViewGridForRegion"
        style="width: 100%; height: 540px"
        class="ag-theme-balham"
        [masterDetail]="true"
        [gridOptions]="gridOptionsForRegion"
        [rowData]="rowDataForRegion"
        (gridReady)="onGridReady($event)"
        (selectionChanged)="onSelectionChanged($event)"
        (filterChanged)="onFilterChangedPlan($event)">
      </ag-grid-angular>
    </div>
  </div>
</div>
