import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AgRendererComponent } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

export interface LinkCellComponentParams {
    replaceUrl?: boolean;
    target?: string;
    showLinkForDeprecated?: boolean;
    commands?(params: ICellRendererParams & LinkCellComponentParams): any[] | null;
    queryParams?(params: ICellRendererParams & LinkCellComponentParams): { [key: string]: string } | null;
    url?(params: ICellRendererParams & LinkCellComponentParams): string | null;
    text?(params: ICellRendererParams & LinkCellComponentParams): string | null;
}

export interface LinkCellComponentColDef {
    cellRendererParams?: LinkCellComponentParams;
}

@Component({
    templateUrl: "./link-cell.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkCellComponent implements AgRendererComponent {
    value: string;
    commands: any[];
    queryParams: { [key: string]: string };
    replaceUrl: boolean;
    url: string;
    target: string;

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private activeRoute: ActivatedRoute
    ) {}

    agInit(params: ICellRendererParams & LinkCellComponentParams) {
        this.value = (params.text && params.text(params)) || params.value;
        this.commands = params.commands && params.commands(params);
        this.queryParams = params.queryParams && params.queryParams(params);
        this.replaceUrl = params.replaceUrl;
        this.url = params.url && params.url(params);
        this.target = params.target || "_self";
    }

    refresh(params: ICellRendererParams & LinkCellComponentParams) {
        this.value = params.value;
        this.commands = params.commands && params.commands(params);
        this.queryParams = params.queryParams && params.queryParams(params);
        this.replaceUrl = params.replaceUrl;
        this.url = params.url && params.url(params);
        this.target = params.target || "_self";
        this.changeDetectorRef.markForCheck();
        return true;
    }
}
