import { Component, OnInit } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import { ISubscriptionInfoInput, SubscriptionInfo, IRegionGroupListRecord, ISubscriptionInfoRowData, allowedCommonRegionStatuses } from "../../npr-request.model";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ModalService } from "../../shared/modal.service";
import { NprConfiguration } from "../../app.configuration";
import { SubscriptionInfoColumnDefinition } from "./ag-grid-column-definition";
import { BaseComponent } from "../../shared/base.component";
import { extendDefaultOptions } from "../../utility/gridHelper";
import { Region } from "../../generated-models";
import { PreprocessDateTime } from "../../utility/view-field-mapping";
import { exportExcel } from "src/app/utility/common-helper";

@Component({
  templateUrl: "./subscription-info.component.html",
  styleUrls: ["../../styles.scss"],
})
export class SubscriptionInfoComponent extends BaseComponent implements OnInit {
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize = 15;
  regionList: string[] = [];
  fullRegionList: IRegionGroupListRecord[] = [];
  regionDetails = new Map();
  regionNameDetails = new Map();
  subscriptionId = "";
  subValidationError = "";
  ZoneMappings: SubscriptionInfo[] = null;
  rowData: ISubscriptionInfoRowData[] = [];
  cloudTypes: string[] = [];
  cloudType: string = null;
  regions: string[] = [];
  subscriptionIds = "";

  constructor(private modalService: ModalService, private apiService: ApiService, private loadingService: LoadingScreenService) {
    super();
  }

  ngOnInit() {
    this.gridOptions = extendDefaultOptions({
      columnDefs: SubscriptionInfoColumnDefinition,
      paginationPageSize: this.initPageSize,
    });
    this.gridOptions.getRowHeight = function (params) {
      return params.data.ZoneMapping.length * 30;
    };
    this.loadingService.setLoading(true);
    this.apiService.getRegionList().subscribe((response: Region[]) => {
      if (response == null) {
        console.warn("No region is obtained from api server");
      } else {
        this.fullRegionList = [];
        this.regionDetails.clear();
        const aggregateCloudTypes: string[] = [];
        var filterRegions = response.filter(region => allowedCommonRegionStatuses.includes(region.Status));
        for (let i = 0; i < filterRegions.length; i++) {
          const rec: IRegionGroupListRecord = {} as IRegionGroupListRecord;
          const cloudAndName = [];
          cloudAndName.push(filterRegions[i].ArmRegionName);
          cloudAndName.push(filterRegions[i].CloudName);
          aggregateCloudTypes.push(filterRegions[i].CloudName);
          rec.item_id = i + 1;
          rec.region = filterRegions[i].RegionName;
          rec.cloud = filterRegions[i].CloudName;
          this.fullRegionList.push(rec);
          this.regionDetails.set(filterRegions[i].RegionName, cloudAndName);
          this.regionNameDetails.set(filterRegions[i].ArmRegionName.toLowerCase(), filterRegions[i].RegionName);
        }
        this.cloudTypes = aggregateCloudTypes.filter((item, pos) => aggregateCloudTypes.indexOf(item) === pos);
      }
    });
    this.loadingService.setLoading(false);
  }
  exportExcel() {
    const fileName = "subscription-info-" + PreprocessDateTime(Date.now(), "yyyyMMdd-HHmm");
    const sheetName = "Subscription Info";

    this.loadingService.setLoading(true);
    exportExcel(this.gridApi, fileName, sheetName);
    this.loadingService.setLoading(false);
  }

  onGridReady(params: GridOptions) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  handleSubscriptionInfoQueryError(e: any) {
    this.loadingService.setLoading(false);
    if (e instanceof HttpErrorResponse && e.status === 404) {
      this.modalService.informationModal("Not Found!");
    } else if (e instanceof HttpErrorResponse && e.error && e.error.message) {
      this.modalService.informationModal(e.error.message);
    } else if (e instanceof HttpErrorResponse && e.status === 403) {
      this.modalService.informationModal("Access Denied!");
    } else if (e instanceof String || typeof e === "string") {
      this.modalService.informationModal(e as string);
    }
  }

  onCloudTypeChanged() {
    this.regionList = this.fullRegionList.filter((val) => val.cloud === this.cloudType).map((a) => a.region);
  }

  submitRequest(form: any) {
    this.loadingService.setLoading(true);
    this.rowData = [];
    this.gridApi.setRowData(this.rowData);
    const newInput: ISubscriptionInfoInput = {} as ISubscriptionInfoInput;
    newInput.SubscriptionIds = this.subscriptionIds.split(/[,;]/).map((s: string) => s.trim());
    if (!form.value.regions || form.value.regions.length === 0) {
      newInput.Regions = this.regionList;
    } else {
      newInput.Regions = form.value.regions;
    }

    // Subscription id regular expression check
    this.subValidationError = "";
    const regexp = new RegExp(NprConfiguration.subsRegExpression);
    if (!regexp.test(newInput.SubscriptionIds.join(","))) {
      this.subValidationError = "You should enter valid subscription ID.";
      this.loadingService.setLoading(false);
      return;
    }
    this.apiService.getSubscriptionInfo(newInput, form.value.cloudType).subscribe(
      (response) => {
        this.loadingService.setLoading(false);
        response.forEach((mapping) => {
          const currentRow: ISubscriptionInfoRowData = {} as ISubscriptionInfoRowData;
          currentRow.RegionName = mapping.Location;
          currentRow.SubscriptionId = mapping.SubscriptionId;
          currentRow.HasRegionAccess = mapping.HasRegionAccess;
          currentRow.AZAFEC = mapping.AZAFECFlag;
          currentRow.OfferId = mapping.OfferId;
          currentRow.OfferType = mapping.OfferType;
          currentRow.OfferName = mapping.OfferName;
          currentRow.QuotaDetails = mapping.QuotaDetails;
          currentRow.ZoneMapping = [];
          currentRow.IsAZRegistered = mapping.IsAZRegistered;
          if (mapping.ZoneMappings.length === 0) {
            currentRow.ZoneMapping.push("");
          }

          mapping.ZoneMappings.forEach((zonemapping) => {
            const lineContent = `Logical Zone ${zonemapping.LogicalZone} : Physical Zone ${zonemapping.PhysicalZone}`;
            currentRow.ZoneMapping.push(lineContent);
          });
          this.rowData.push(currentRow);
        });
        this.gridApi.setRowData(this.rowData);
      },
      (e: any) => {
        this.handleSubscriptionInfoQueryError(e);
        this.loadingService.setLoading(false);
      }
    );
  }
}
