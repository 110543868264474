<div class="title" #title>
  <div class="container container-max-width">
    <div>
      <h4>Review Plan</h4>
      <p>Review the diff between approved and new plan versions</p>
    </div>
    <div class="action-container">
      <button type="submit" class="btn btn-success" (click)="showApprovePlanDialog()" [disabled]="!canApprove">Approve</button>&nbsp;
      <button type="submit" class="btn btn-danger" (click)="showRejectPlanDialog()" [disabled]="!canReject">Reject</button>
    </div>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main">
    <div class="top-panel" style="height: fit-content">
      <ul class="list-container col-md-12" *ngIf="planRegion">
        <li class="flex-box mb-3">
          <div class="col-md-2 label">Service Team</div>
          <div class="col-md-10 value">{{ planRegion.ServiceTeam }}&nbsp;({{ planRegion.ServiceTreeId }})</div>
        </li>
        <li class="flex-box mb-3">
          <div class="col-md-2 label">Region</div>
          <div class="col-md-10 value" *ngIf="region != null">{{ planRegion.Region }}&nbsp;({{ region.CloudName}}/{{ region.Status }})</div>
        </li>
        <li class="flex-box mb-3">
          <div class="col-md-2 label">Ring</div>
          <div class="col-md-10 value">{{ planRegion.Ring }}</div>
        </li>
        <li class="flex-box mb-3">
          <div class="col-md-2 label">Version</div>
          <div class="col-md-10 value">
            <a href="{{ planDetailLink }}"
                 class="topbar-item"
                 target="_blank">
              {{ planRegion.Version }} 
            </a>
            &nbsp;&nbsp;(
            <b *ngIf="planRegion.Version != latestVersion; else userTemplate">
              Latest version is
              <a href="{{ planLatestVersionReviewLink }}"
                 class="topbar-item"
                 target="_blank">
                {{ latestVersion }}
              </a>
            </b>
            <ng-template #userTemplate>Latest version</ng-template>)
          </div>
        </li>
        <li class="flex-box mb-3">
          <div class="col-md-2 label">Stage</div>
          <div class="col-md-10 value">{{ planRegion.Stage }}</div>
        </li>
        <li class="flex-box mb-3">
          <div class="col-md-2 label">Status</div>
          <div class="col-md-10 value">
            {{ showStatus(planRegion) }}
            <span *ngIf="showRejectComment" [ngbTooltip]="planRegion.RejectComment"><i class="fa fa-info-circle"></i></span>
          </div>
        </li>
        <li class="flex-box mb-3">
          <div class="col-md-2 label">Submitted</div>
          <div class="col-md-10 value">{{ showSubmit(planRegion) }}</div>
        </li>
        <li class="flex-box mb-3">
          <div class="col-md-2 label">Contact Email</div>
          <div class="col-md-10 value">{{ contactEmail }}</div>
        </li>
      </ul>
    </div>

    <div class="card mb-4">
      <div class="card-header">
        <div>
          <span class="bold-font">Approvals</span>
        </div>
      </div>
      <div class="card-block p-3">
        <ag-grid-angular #agGrid
                         id="planRegionApprovalsGrid"
                         style="width: 100%; height: 320px"
                         class="ag-theme-balham"
                         [masterDetail]="true"
                         [gridOptions]="planRegionApprovalsGridOptions"
                         [rowData]="planRegionApprovalsRowData"
                         pagination="true"
                         (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>

    <div class="card mb-4" style="clear: both">
      <div class="card-header flex-row">
        <div class="float-left">
          <span class="bold-font">Related Capacity Orders</span>
        </div>
      </div>
      <div class="card-block p-3">
        <ag-grid-angular #agGrid
                         id="capacityOrderGrid"
                         style="width: 100%; height: 120px"
                         class="ag-theme-balham"
                         [gridOptions]="capacityOrderGridOptions"
                         [rowData]="capacityOrderRowData"
                         (gridReady)="onCapacityOrderGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>

    <div class="card mb-4">
      <div class="card-header">
        <div>
          <span class="bold-font">Plan Details</span>
        </div>
      </div>
      <div class="card-block p-3">
        <ag-grid-angular #agGrid
                         id="planDiffGrid"
                         style="width: 100%; height: 320px"
                         class="ag-theme-balham"
                         [masterDetail]="true"
                         [gridOptions]="gridOptions"
                         [rowData]="planDiffRowData"
                         pagination="true"
                         (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
  </div>
</div>
