import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import { ApiService } from "../../services";
import { DataTypes } from "./admin-view-data-types";
import { forkJoin } from "rxjs";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import {
  ArmResourceTypeColumnDefinitionForAdmin,
  AzColumnDefinitionForAdmin,
  HdiSkuColumnDefinitionForAdmin,
  RegionTableColumnDefinitionForAdmin,
  ServiceReadinessColumnDefinitionForAdmin,
  ServiceReadinessStageColumnDefinitionForAdmin,
  SqlColumnDefinitionForAdmin,
  VmDiskColumnDefinitionForAdmin,
  VmSkuColumnDefinitionForAdmin
} from "./ag-grid-column-definition";
import { GridViewBaseComponent } from "../../utility/grid-view-base.component";
import { TableViewDataInfo } from "src/app/npr-request.model";
import { AZ, ArmResourceType, HdiSku, RegionTable, ServiceReadiness, ServiceReadinessStage, Sql, VmSku } from "src/app/generated-models";
import { SharedDataService } from "../../services/sharedDataService";
import { VmDisk } from "../../generated-models/VmDisk";
import { BatchSku } from "src/app/generated-models/BatchSku";

@Component({
  selector: "app-admin-view-table-view",
  templateUrl: "./admin-view-table-view.component.html",
  styleUrls: ["../../styles.scss"],
})
export class AdminViewTableViewComponent extends GridViewBaseComponent implements OnInit {
  type = "";
  gridOptions: GridOptions;
  rowData = [];
  columnDefs: (ColDef | ColGroupDef)[] = AzColumnDefinitionForAdmin;
  tableName = "AZ";
  initPageSize: 15;
  tableList: TableViewDataInfo[] = [];
  tableItem: TableViewDataInfo;
  toggledGrouping = false;
  gridTheme = "Default";
  selectAllFlag = false;
  message = "";
  keyFilterStats: string;
  vmDiskRowDataTemplate = [
    {
      DiskQuotaTypeName: "Premium SSD V2 managed Disk Capacity",
      DiskQuotaType: "PremiumV2DiskSizeInGB",
      AutoApprovalDiskQuota: 25000,
      Location: "",
    },
    {
      DiskQuotaTypeName: "Premium SSD V2 managed Disks",
      DiskQuotaType: "PremiumV2DiskCount",
      AutoApprovalDiskQuota: 2000,
      Location: "",
    },
    {
      DiskQuotaTypeName: "Ultra Disk Capacity",
      DiskQuotaType: "UltraSSDDiskSizeInGB",
      AutoApprovalDiskQuota: 50000,
      Location: "",
    },
    {
      DiskQuotaTypeName: "Ultra Disks",
      DiskQuotaType: "UltraSSDDiskCount",
      AutoApprovalDiskQuota: 2000,
      Location: "",
    }
  ];

  public gridApi: GridApi;
  public columnApi: ColumnApi;

  constructor(
    protected apiService: ApiService,
    protected notificationService: ToastrService,
    protected loadingService: LoadingScreenService,
    protected sharedDataService: SharedDataService,
    protected dataTypes: DataTypes
  ) {
    super(apiService, sharedDataService);
  }

  ngOnInit(): void {
    super.InitAgGrid(AzColumnDefinitionForAdmin);
    this.getTableList();
  }

  refreshData(): void {
    //
  }

  async getTableList() {
    forkJoin([
      this.apiService.getAzEntity(),
      this.apiService.getHdiSkus(),
      this.apiService.getServiceReadinesses(),
      this.apiService.getServiceReadinessStages(),
      this.apiService.getSqls(),
      this.apiService.getArmResourceTypes(),
      this.apiService.getVmDisk(),
      this.apiService.getRegions(),
      this.apiService.getBatchSkus(),
      this.apiService.getAllVmSkuEntities(),
    ]).subscribe(
      ([
        azEntities,
        hdiSkuEntities,
        serviceReadinessEntities,
        serviceReadinessStageEntities,
        sqlEntities,
        armResourceTypeEntities,
        supportedVmDisks,
        regionEntities,
        batchSkuEntities,
        vmSkuEntities,
      ]: [AZ[], HdiSku[], ServiceReadiness[], ServiceReadinessStage[], Sql[], ArmResourceType[], VmDisk[], RegionTable[], BatchSku[], VmSku[]]) => {
        // Default table item
        this.tableItem = {
          TableName: "AZ",
          TableTitle: "AZ",
          ColumnDefs: AzColumnDefinitionForAdmin,
          rowData: azEntities,
        } as TableViewDataInfo;
        this.onTableChanged();

        this.tableList = [
          this.tableItem,
          {
            TableName: "Hdi Sku",
            TableTitle: "HdiSku",
            ColumnDefs: HdiSkuColumnDefinitionForAdmin,
            rowData: hdiSkuEntities,
          },
          {
            TableName: "Service Readiness",
            TableTitle: "ServiceReadiness",
            ColumnDefs: ServiceReadinessColumnDefinitionForAdmin,
            rowData: serviceReadinessEntities,
          },
          {
            TableName: "Service Readiness Stage",
            TableTitle: "ServiceReadinessStage",
            ColumnDefs: ServiceReadinessStageColumnDefinitionForAdmin,
            rowData: serviceReadinessStageEntities,
          },
          {
            TableName: "Sql",
            TableTitle: "Sql",
            ColumnDefs: SqlColumnDefinitionForAdmin,
            rowData: sqlEntities,
          },
          {
            TableName: "Arm Resource Type",
            TableTitle: "ArmResourceType",
            ColumnDefs: ArmResourceTypeColumnDefinitionForAdmin,
            rowData: armResourceTypeEntities,
          },
          {
            TableName: "Virtual Machine Disk",
            TableTitle: "VmDisk",
            ColumnDefs: VmDiskColumnDefinitionForAdmin,
            rowData: supportedVmDisks,
          },
          {
            TableName: "Region",
            TableTitle: "Region",
            ColumnDefs: RegionTableColumnDefinitionForAdmin,
            rowData: regionEntities,
          },
          {
            TableName: "Batch Sku",
            TableTitle: "BatchSku",
            ColumnDefs: HdiSkuColumnDefinitionForAdmin,
            rowData: batchSkuEntities,
          },
          {
            TableName: "Vm Sku",
            TableTitle: "VmSku",
            ColumnDefs: VmSkuColumnDefinitionForAdmin,
            rowData: vmSkuEntities,
          },
        ];
      },
      (err: unknown) => {
        this.notificationService.error(err as string);
      }
    );
  }

  onGridReady(params: GridOptions): void {
    super.onGridReady(params);
  }

  onTableChanged(): void {
    if (this.tableItem != null) {
      this.tableName = this.tableItem.TableName;
      this.columnDefs = this.tableItem.ColumnDefs;
      this.rowData = this.tableItem.rowData;
    }
  }
}
