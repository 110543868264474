export const namespacesList = [
  "github.enterprise",
  "microsoft.advisor",
  "microsoft.alertsmanagement",
  "microsoft.apihubs",
  "microsoft.apimanagement",
  "microsoft.appconfiguration",
  "microsoft.appservice",
  "microsoft.archive",
  "microsoft.authorization",
  "microsoft.automation",
  "microsoft.avs",
  "microsoft.azconfig",
  "microsoft.azurearcdata",
  "microsoft.azuredata",
  "microsoft.azuresphere",
  "microsoft.batch",
  "microsoft.botservice",
  "microsoft.businessappdiscovery",
  "microsoft.businessapplatform",
  "microsoft.cache",
  "microsoft.cdn",
  "microsoft.certificateregistration",
  "microsoft.chaos",
  "microsoft.cognitiveservices",
  "microsoft.commondatamodel",
  "microsoft.communication",
  "microsoft.containerinstance",
  "microsoft.containerregistry",
  "microsoft.containerservice",
  "microsoft.contentmoderator",
  "microsoft.contoso",
  "microsoft.d365customerinsights",
  "microsoft.databox",
  "microsoft.databricks",
  "microsoft.datafactory",
  "microsoft.datamigration",
  "microsoft.dbformariadb",
  "microsoft.dbformysql",
  "microsoft.dbforpostgresql",
  "microsoft.desktopvirtualization",
  "microsoft.detonationservice",
  "microsoft.devices",
  "microsoft.devtestlab",
  "microsoft.digitaltwins",
  "microsoft.domainregistration",
  "microsoft.dynamics365fraudprotection",
  "microsoft.dynamicslcs",
  "microsoft.eventgrid",
  "microsoft.flow",
  "microsoft.guestconfiguration",
  "microsoft.hardwaresecuritymodules",
  "microsoft.hdinsight",
  "microsoft.healthcareapis",
  "microsoft.hpcworkbench",
  "microsoft.hybridcompute",
  "microsoft.importexport",
  "microsoft.insights",
  "microsoft.insights-ai",
  "microsoft.insights-aux",
  "microsoft.iotcentral",
  "microsoft.iotsecurity",
  "microsoft.keyvault",
  "microsoft.kusto",
  "microsoft.labservices",
  "microsoft.loadtestservice",
  "microsoft.locationbasedservices",
  "microsoft.loganalytics",
  "microsoft.logic",
  "microsoft.machinelearningservices",
  "microsoft.managedlab",
  "microsoft.managedservices",
  "microsoft.maps",
  "microsoft.media",
  "microsoft.migrate",
  "microsoft.mixedreality",
  "microsoft.monitor",
  "microsoft.netapp",
  "microsoft.network",
  "microsoft.notificationhubs",
  "microsoft.offazure",
  "microsoft.operationalinsights",
  "microsoft.operationsmanagement",
  "microsoft.policyinsights",
  "microsoft.powerapps",
  "microsoft.powerbi",
  "microsoft.powerbidedicated",
  "microsoft.powerplatform",
  "microsoft.processsimple",
  "microsoft.projectarcadia",
  "microsoft.projectbabylon",
  "microsoft.providerhub",
  "microsoft.purview",
  "microsoft.recommendationsservice",
  "microsoft.redhatopenshift",
  "microsoft.search",
  "microsoft.security",
  "microsoft.securitydetonation",
  "microsoft.securityinsights",
  "microsoft.servicefabricmesh",
  "microsoft.siena",
  "microsoft.signalrservice",
  "microsoft.spoolservice",
  "microsoft.sqlserver.migration.service",
  "microsoft.sqlvirtualmachine",
  "microsoft.storagepool",
  "microsoft.storagesync",
  "microsoft.storagesyncdev",
  "microsoft.storagesyncint",
  "microsoft.streamanalytics",
  "microsoft.synapse",
  "microsoft.videoindexer",
  "microsoft.virtualmachineimages",
  "microsoft.visualstudio",
  "microsoft.web",
  "microsoft.workloadmonitor",
  "powerbi.ext.azure.com",
  "powerbi.ext.microsoftazure.cn",
  "powerbi.ext.microsoftazure.de",
  "powerbigov.ext.azure.us",
];
