<div class="title" #title>
    <div class="container container-max-width">
      <div>
        <h4>FFP Plan Editor - {{ serviceTeam }}</h4>
        <p>Author region agnostic FFP plan(latest version is {{ latestVersion }})</p>
      </div>
      <div class="action-container">
        <button type="button" class="btn btn-danger" (click)="undoOnboarding()" *ngIf="canUndoOnboarding">Undo Onboarding</button> &nbsp;
        <button type="button" class="btn btn-primary" (click)="showPlanHistoryDialog()">History</button> &nbsp;
        <button
          type="button"
          class="btn btn-primary"
          (click)="showCommitPlanDialog()"
          [disabled]="isReadonly || !ifFilesChanged"
          data-test="Commit">
          Commit
        </button>
      </div>
    </div>
  </div>
  <div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
    <div class="landing-main" style="display: flex">
      <div class="card mb-2 mt-4 mr-1 col-md-6" style="float: left; padding: 0">
        <div class="card-header flex-row">
          <div class="float-left">
            <span>Source FFP plan files</span>
            <span class="font-weight-bold" *ngIf="ifFilesChanged">&nbsp;(Uncommitted changes exist)</span>
          </div>
  
          <div class="float-right" style="height: 24px">
            <button
              type="button"
              [disabled]="isReadonly"
              class="btn btn-link"
              style="height: 24px; padding: 0"
              (click)="showFfpPlanNewFileDialog()">
              <i class="fas fa-plus" ngbTooltip="Add&nbsp;a&nbsp;new&nbsp;file"></i>
            </button>
          </div>
        </div>
        <div class="card-block p-3">
          <ag-grid-angular
            #agGrid
            id="ViewGrid"
            style="width: 100%; height: 540px"
            class="ag-theme-balham"
            [gridOptions]="gridOptions"
            [rowData]="rowSourceData"
            (gridReady)="onGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
      <div class="card mb-2 mt-4 ml-1 col-md-6" style="float: right; padding: 0">
        <div class="card-header flex-row">
          <div class="float-left">
            <span>Effective FFP plan files</span>
          </div>
        </div>
        <div class="card-block p-3">
          <ag-grid-angular
            #agGrid
            id="ViewGrid"
            style="width: 100%; height: 540px"
            class="ag-theme-balham"
            [gridOptions]="gridOptionsMerged"
            [rowData]="rowMergedData"
            (gridReady)="onGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
  