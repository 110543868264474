<div class="title" #title>
  <div class="container container-max-width">
    <h4>Subscription Info</h4>
    <p style="line-height: 20px">
      Use this page to fetch subscription info
      <br />
    </p>
  </div>
</div>

<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main">
    <form #SubscriptionInfoRequestForm="ngForm" autocomplete="off" style="height: 800px" novalidate>
      <!--Subscription ID-->
      <div
        class="form-group"
        [ngClass]="{
          error: SubscriptionInfoRequestForm.controls.subIds?.invalid && SubscriptionInfoRequestForm.controls.subIds?.touched
        }"
      >
        <h6>Subscriptions list:</h6>
        <textarea
          class="form-control"
          name="subscriptionIds"
          title="subscriptionIds"
          id="subscriptionIds"
          rows="3"
          placeholder="subscription id"
          [(ngModel)]="subscriptionIds"
          placeholder="Enter in this format: 987e33bf-8713-45cf-93c5-7055c9cca413, 2a737b98-0f8d-4cc8-8a9d-3ea6d1192702, ..."
          required
        ></textarea>
        <em
          *ngIf="
            SubscriptionInfoRequestForm.controls.subscriptionIds?.invalid && SubscriptionInfoRequestForm.controls.subscriptionIds?.touched
          "
          >Required</em
        >
        <em *ngIf="!SubscriptionInfoRequestForm.controls.subscriptionIds?.invalid && this.subValidationError?.length != 0">{{
          this.subValidationError
        }}</em>
      </div>
      <!--Region-->
      <div class="form-group">
        <h6>Cloud:</h6>
        <label style="width: 100%">
          <ng-select
            name="cloudType"
            labelForId="cloudType"
            [items]="cloudTypes"
            bindLabel="cloudType"
            bindValue="currentCloudType"
            (change)="onCloudTypeChanged()"
            [(ngModel)]="cloudType"
            required>
          </ng-select>
        </label>
      </div>
      <!--Region-->
      <div class="form-group">
        <h6>Region(s):</h6>
        <label style="width: 100%">
          <ng-select
            name="regions"
            labelForId="regions"
            [items]="regionList"
            bindLabel="region"
            bindValue="region"
            groupBy="cloud"
            [multiple]="true"
            [(ngModel)]="regions"
            placeholder="Enter region name"
            [disabled]="cloudType == null">
          </ng-select>
        </label>
      </div>

      <!--Submit Button-->
      <div class="form-group nofloat">
        <button
          type="submit"
          class="btn btn-primary mr-1"
          [disabled]="SubscriptionInfoRequestForm.invalid"
          (click)="submitRequest(SubscriptionInfoRequestForm)"
        >
          Submit
        </button>
        <div class="float-right mt-3 mr-1">
          <a
            href="javascript:void(0)"
            class="topbar-item"
            (click)="exportExcel()"
            placement="top-right"
            container="body"
            ngbTooltip="Export to Excel"
            aria-label="Export to Excel"
          >
            <span class="far fa-file-excel fa-lg"></span>
          </a>
        </div>
      </div>
      <hr />

      <div>
        <ag-grid-angular
          style="height: 540px"
          class="ag-theme-balham"
          [gridOptions]="gridOptions"
          [rowData]="rowData"
          [masterDetail]="true"
          (gridReady)="onGridReady($event)"
          pagination="true"
        >
        </ag-grid-angular>
      </div>
      <br />
    </form>
  </div>
</div>
