/* eslint-disable rxjs/no-unbound-methods */
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable, of, throwError, combineLatest } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  CommentUpdate,
  IApprovalData,
  IPamApprovalState,
  IParentRequest,
  IQuotaImplicationsData,
  IRequestRegion,
  IStatusResponse,
  ISubRequest,
  ISubRequestUpdateData,
  ISubscriptionDetails,
  ISubscriptionInfoInput,
  IUIRequest,
  IUserProfile,
  IWorkItemResponse,
  MyRequestAndWorkItems,
  RegionStatus,
  RequestServiceType,
  SubscriptionInfo,
  Roles,
  ISelfServiceInfoInput,
  ISubRequestWithTicket,
  LionrockUserRoles,
  OkMessage,
} from "../npr-request.model";
import { FfpConfiguration, NprConfiguration, PlannedQuotaConfiguration, PfConfiguration } from "../app.configuration";
import { environment } from "src/environments/environment";
import { ParseRequestId } from "../utility/common-helper";
import { GlobalErrorHandler } from "./globalErrorHandler.service";
import {
  ArmResourceType,
  ApprovalList,
  AutoApprovalRule,
  AZ,
  CisJob,
  Config,
  HdiSku,
  HoboSubscriptionAutoApprovalRule,
  OperationLog,
  PlannedQuotaRequestDetail,
  Region,
  EdgeZone,
  RegionConfig,
  EdgeZoneConfig,
  RegionTable,
  ServiceReadiness,
  ServiceReadinessStage,
  Sql,
  SubRequestBatchApproval,
  Team,
  Ticket,
  UserRole,
  VmSize,
  VmSku,
} from "../generated-models";
import { RegionSupportInfo } from "../generated-models/RegionSupportInfo";
import { SubscriptionRegionSupportInfo } from "../generated-models/SubscriptionRegionSupportInfo";
import { SubscriptionSupportInfoARM } from "../generated-models/SubscriptionSupportInfoARM";
import { SubscriptionSupportInfoRDFE } from "../generated-models/SubscriptionSupportInfoRDFE";
import { Discussion } from "../generated-models/Discussion";
import { SubscriptionThreshold } from "../generated-models/SubscriptionThreshold";
import { RegionalQuotaLimit } from "../generated-models/RegionalQuotaLimit";
import { PlanRegion } from "../generated-models/PlanRegion";
import { PlannedQuotaRequest } from "../generated-models/PlannedQuotaRequest";
import { PlanRegionDetail } from "../generated-models/PlanRegionDetail";
import { PlanSubmission } from "../generated-models/PlanSubmission";
import { PlanFile } from "../generated-models/PlanFile";
import { Plan } from "../generated-models/Plan";
import { CommitFfpPlan } from "../generated-models/CommitFfpPlan";
import { PlanRegionOverallApproval } from "../generated-models/PlanRegionOverallApproval";
import { PlanRegionDiff } from "../generated-models/PlanRegionDiff";
import { PlanRegionUpdateData } from "../generated-models/PlanRegionUpdateData";
import { User } from "../generated-models/User";
import { PlanCommit } from "../generated-models/PlanCommit";
import { PlannedQuotaRequestPagination } from "../generated-models/PlannedQuotaRequestPagination";
import { RegionalQuotaStatistics } from "../generated-models/RegionalQuotaStatistics";
import { PlanRegionStatusEnum } from "../shared/enums/plan-enums";
import { SearchRequests } from "../generated-models/SearchRequests";
import { PlanJsonSchema } from "../quota/plans/modals/plan-editor-modal/plan-editor-modal.component";
import { PlannedQuotaSubscriptionBinding } from "../generated-models/PlannedQuotaSubscriptionBinding";
import { PlannedQuotaPropertyBinding } from "../generated-models/PlannedQuotaPropertyBinding";
import { PlanRejectData } from "../generated-models/PlanRejectData";
import { IcmNotification } from "../generated-models/IcmNotification";
import { PlanRegionStatus } from "../generated-models/PlanRegionStatus";
import { EmailsPagination } from "../generated-models/EmailsPagination";
import { CommitPlan } from "../generated-models/CommitPlan";
import { CapacityOrder } from "../generated-models/CapacityOrder";
import { TeamConfig } from "../generated-models/TeamConfig";
import { PlanDetail } from "../generated-models/PlanDetail";
import { VmDisk } from "../generated-models/VmDisk";
import { AssignPlanToRegion } from "../generated-models/AssignPlanToRegion";
import { PlansRisks } from "../generated-models/PlansRisks";
import { SubscriptionSupportInfo } from "../generated-models/SubscriptionSupportInfo";
import { BatchSku } from "../generated-models/BatchSku";
import { PlanRegionApproval } from "../generated-models/PlanRegionApproval";
import { FfpPlan } from "../generated-models/FfpPlan";
import { FfpPlanFile } from "../generated-models/FfpPlanFile";
import { PfPlan } from "../generated-models/PfPlan";
import { PfPlanFile } from "../generated-models/PfPlanFile";
import { CommitPfPlan } from "../generated-models/CommitPfPlan";
import { CapacityOrderRefreshResult } from "../generated-models/CapacityOrderRefreshResult";
import { PfPlanRejectData } from "../generated-models/PfPlanRejectData";
import { FfpPlanRegion } from "../generated-models/FfpPlanRegion";
import { FfpPlanSubmission } from "../generated-models/FfpPlanSubmission";
import { FfpAssignPlanToRegion } from "../generated-models/FfpAssignPlanToRegion";
import { FfpPlanRegionApproval } from "../generated-models/FfpPlanRegionApproval";
import { FfpPlanRegionOverallApproval } from "../generated-models/FfpPlanRegionOverallApproval";
import { FfpPlanRejectData } from "../generated-models/FfpPlanRejectData";
import { FfpPlanRegionDetail } from "../generated-models/FfpPlanRegionDetail";
import { RegionBuildout } from "../generated-models/RegionBuildout";
import { Message } from "../generated-models/Message";
import { CapacitySubOrderDetails } from "../generated-models/CapacitySubOrderDetails";
import { CapacityOrderDetails } from "../generated-models/CapacityOrderDetails";

@Injectable()
export class ApiService {
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "my-auth-token",
    }),
  };

  public static userProfile: IUserProfile = null;

  constructor(private http: HttpClient, private globalErrorHandler: GlobalErrorHandler) { }

  static createRequestRegionFromRegion(r: Region, val: number): IRequestRegion {
    return {
      value: val,
      label: r.RegionName,
      cloud: r.CloudName,
      regionSupportAz: !!r.AZFeatureFlag,
      isGA: r.Status === RegionStatus.GA,
      constraintNote: r.ConstraintNote || "",
      dcmtRegionId: r.DCMTRegionId,
      isManagedRegion: r.Status === RegionStatus.GA && !!r.RequiredFeatures,
      isEnabled: r.IsEnabled,
      isFlighting: r.IsFlighting,
      isEnabledInUI: r.IsEnabledInUI,
      isEnabledForCustomerEA: r.IsEnabledForCustomerEA,
      IsEnabledBetOnDemand: r.IsEnabledBetOnDemand,
      isManagedAZ: r.Status === RegionStatus.GA && !!r.AZFeatureFlag,
      IsCappedForCapacityOrder: r.IsCappedForCapacityOrder
    } as IRequestRegion;
  }

  static generatePlannedQuotaRequestHyperLink(requestId: string): string {
    const href = `${window.location.origin}${environment.siteRootPath}/plans/requests/${requestId}`;
    return `<a href="${href}" rel="noopener noreferrer" target="_blank" title="${requestId}">${requestId}</a>`;
  }

  static generateSubRequestHyperLink(parentRequestId: string, subRequestId: string): string {
    const href = `${window.location.origin}${environment.siteRootPath}/requests/${parentRequestId}/sub/${subRequestId}`;
    return `<a href="${href}" rel="noopener noreferrer" target="_blank" data-test="SubRequestLink" title="${parentRequestId}-${subRequestId}">${parentRequestId}-${subRequestId}</a>`;
  }

  static generateParentRequestHyperLink(parentRequestId: string, text = ""): string {
    let href = `${window.location.origin}${environment.siteRootPath}`;
    // Only Customer Request Id contains '_'
    if (parentRequestId.indexOf("_") > 0) {
      const [billingAccountId, region] = parentRequestId.split("_");
      href += `/customer-request/customers/${billingAccountId}/regions/${region}`;
    } else {
      href += `/requests/${parentRequestId}`;
    }

    // Default text is parent request id
    if (!text) {
      text = parentRequestId;
    }

    return `<a href="${href}" rel="noopener noreferrer" target="_blank" title="${parentRequestId}">${text}</a>`;
  }

  static generateCapacityOrderHyperLink(capacityOrderId: string): string {
    const href = `${window.location.origin}${environment.siteRootPath}/plans/capacityOrder/${capacityOrderId}`;
    return `<a href="${href}" rel="noopener noreferrer" target="_blank" title="${capacityOrderId}">${capacityOrderId}</a>`;
  }

  static fetchServiceTypeName(dbServiceType: string, sku: string): string {
    const serviceTypeMapping = {
      AZ: "AZ Enablement",
      CAS: "Compute - Region Enablement CAS",
      SQL: "SQL DB - Region Enablement",
      SQLMI: "SQL MI - Region Enablement",
      HDInsight: "HDInsight - Quota Increase",
      ARM: "ARM - Region Enablement",
      RDFE: "RDFE - Region Enablement",
      ArmVmQuota: "Compute ARM - Quota Increase",
      RdfeVmQuota: "Compute RDFE - Quota Increase",
      ArmStorageQuota: "Storage ARM - Quota Increase",
      RdfeStorageQuota: "Storage RDFE - Quota Increase",
      DNSEntry: "DNSEntry - Quota Increase",
      BatchQuota: "Batch - Quota Increase",
      CosmosDB: "Cosmos DB - Quota Increase",
      KustoQuota: "Kusto - Quota Increase",
      InternalVm: "VM SKU AFEC",
      RPFrontload: "RP Frontload - Region Enablement",
      ArmNoQuota: "ARM No Quota - Region Enablement",
      AppService: "App Service - Quota Increase",
      Kusto: "Kusto Enablement",
      ArmResourceType: "ARM Resource Type",
      VmDisk: "VM Disk - Quota Increase",
    };

    // Override by SKU
    switch (dbServiceType) {
      case RequestServiceType.CosmosDB:
        if (sku === "RegionEnable") {
          return "Cosmos DB - Region Enablement";
        } else if (sku === "Az Enablement") {
          return "Cosmos DB - AZ Enablement";
        } else if (sku == "Access") {
          return "Cosmos DB - Region Access";
        }
        return "Cosmos DB - Quota Increase";
      case RequestServiceType.SQL:
        if (sku === "DTU") {
          return "SQL DTU - Quota Increase";
        }
        return "SQL DB - Region Enablement";
      case RequestServiceType.HDInsight:
        if (sku === "Access") {
          return "HDInsight";
        }
        break;
      case RequestServiceType.BatchQuota:
        if (sku === "Access") {
          return "Batch";
        }
        break;
      case RequestServiceType.AppService:
        if (sku === "Access") {
          return "App Service";
        }
        break;
    }

    if (dbServiceType in serviceTypeMapping) {
      return serviceTypeMapping[dbServiceType];
    }
  }

  async getUserProfile(): Promise<IUserProfile> {
    if (!ApiService.userProfile) {
      try {
        const [response] = await combineLatest([this.http.get(NprConfiguration.getProfileUrl, this.httpOptions)]).toPromise();
        ApiService.userProfile = response as IUserProfile;
        if (ApiService.userProfile.UserRoles?.some((role) => role.Role === Roles.Administrator)) {
          ApiService.userProfile.IsAdmin = true;
        }
        if (ApiService.userProfile.UserRoles?.some((role) => role.Role === Roles.Administrator || role.Role === Roles.ApproverAdmin)) {
          ApiService.userProfile.IsApproverAdmin = true;
        }
        if (
          ApiService.userProfile.UserRoles?.some(
            (role) => role.Role === Roles.Administrator || role.Role === Roles.ApproverAdmin || role.Role === Roles.Approver
          )
        ) {
          ApiService.userProfile.IsApprover = true;
        }
        if (
          ApiService.userProfile.UserRoles?.some(
            (role) => role.Role === Roles.Administrator || (role.Role == Roles.Reader && role.Types.includes("GenevaActions"))
          )
        ) {
          ApiService.userProfile.IsGenevaActionsReader = true;
        }
        if (
          ApiService.userProfile.UserRoles?.some(
            (role) => role.Role === Roles.Administrator || (role.Role == Roles.SelfService && role.Types.includes("GenevaActions"))
          )
        ) {
          ApiService.userProfile.IsSelfService = true;
        }
        if (
          ApiService.userProfile.UserRoles?.some(
            (role) =>
              role.Role === Roles.Administrator ||
              ((role.Role == Roles.ApproverAdmin || role.Role == Roles.Approver) && role.Types.includes("GET"))
          )
        ) {
          ApiService.userProfile.IsGETApprover = true;
        }
        if (ApiService.userProfile.UserRoles?.some((role) => role.Role == Roles.BetAdmin)) {
          ApiService.userProfile.IsBetAdmin = true;
        }

        if (
          ApiService.userProfile.UserRoles?.some(
            (role) => role.Role == Roles.BetServiceOwner || role.Role == LionrockUserRoles.RolePrefix + LionrockUserRoles.Administrator
          )
        ) {
          ApiService.userProfile.IsBetServiceOwner = true;
        }

        if (ApiService.userProfile.UserRoles?.some((role) => role.Role == Roles.FfpAdmin)) {
          ApiService.userProfile.IsFfpAdmin = true;
        }

        if (ApiService.userProfile.UserRoles?.some((role) => role.Role == Roles.PfAdmin)) {
          ApiService.userProfile.IsPfAdmin = true;
        }
      } catch (error) {
        await this.handleError(error);
      }
    }
    return ApiService.userProfile;
  }

  setApprovalLevel(parentRequestId: string, subRequestId: number, approvalData: IApprovalData): Observable<IStatusResponse[]> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/${parentRequestId}/subrequests/${subRequestId}/approve`;
    return this.http.post<IStatusResponse[]>(url, approvalData, this.httpOptions).pipe(catchError(this.handleError));
  }

  batchSetApprovalLevel(batchApprovalData: Array<SubRequestBatchApproval>): Observable<IStatusResponse[]> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/batchapprove`;
    return this.http.post<IStatusResponse[]>(url, batchApprovalData, this.httpOptions).pipe(catchError(this.handleError));
  }

  updateSubRequest(parentRequestId: string, subRequestId: number, updateData: ISubRequestUpdateData): Observable<IStatusResponse> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/${parentRequestId}/subrequests/${subRequestId}`;
    return this.http.post<IStatusResponse>(url, updateData, this.httpOptions).pipe(catchError(this.handleError));
  }

  async GetSubRequest(parentRequestId: string, subRequestId: number): Promise<ISubRequest> {
    try {
      const url = `${environment.lionrockApiEndpoint}/api/requests/${parentRequestId}/subrequests/${subRequestId}`;
      const response = await this.http.get(url, this.httpOptions).toPromise();
      return response as ISubRequest;
    } catch (error) {
      await this.handleError(error);
    }
  }

  getSubRequestsOperationHistory(parentRequestId: string, subRequestId: number): Observable<OperationLog[]> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/${parentRequestId}/subrequests/${subRequestId}/history`;
    return this.http.get<OperationLog[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getDiscussions(parentRequestId: string, subRequestId: number): Observable<Discussion[]> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/${parentRequestId}/subrequests/${subRequestId}/discussion`;
    return this.http.get<Discussion[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  appendDiscussion(parentRequestId: string, subRequestId: number, discussion: Discussion) {
    const url = `${environment.lionrockApiEndpoint}/api/requests/${parentRequestId}/subrequests/${subRequestId}/discussion`;
    return this.http.post(url, discussion, this.httpOptions).pipe(catchError(this.handleError));
  }

  getSubRequestsByParentRequestId(parentRequestId: string): Observable<ISubRequest[]> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/${parentRequestId}/subrequests`;
    return this.http.get<ISubRequest[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getDuplicatedSubRequestBySubRequestId(parentReqId: string, subRequestId: number): Observable<ISubRequest[]> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/${parentReqId}/subrequests/${subRequestId}/duplicates`;
    return this.http.get<ISubRequest[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getSubRequestApprovals(parentRequestId: string, subRequestId: number): Observable<ApprovalList> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/${parentRequestId}/subrequests/${subRequestId}/approval`;
    return this.http.get<ApprovalList>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getSubscriptionInfo(request: ISubscriptionInfoInput, cloudType: string): Observable<SubscriptionInfo[]> {
    const url = `${NprConfiguration.getSubscriptionInfoReqUrl}/subscriptionInfo?cloud=${cloudType}`;
    return this.http.post<SubscriptionInfo[]>(url, request, this.httpOptions).pipe(catchError(this.handleError));
  }

  revokeAccessOrQuota(request: ISelfServiceInfoInput): Observable<IStatusResponse> {
    const url = `${NprConfiguration.selfServiceUrl}/revoke`;
    return this.http.post<IStatusResponse>(url, request, this.httpOptions).pipe(catchError(this.handleError));
  }

  getSubscriptionDetails(subscriptionId: string, fetchOfferDetailsOnly: boolean): Observable<ISubscriptionDetails> {
    const url = `${NprConfiguration.getSubscriptionInfoReqUrl}/${subscriptionId}/subscriptionDetails?fetchOfferDetailsOnly=${fetchOfferDetailsOnly}`;
    return this.http.get<ISubscriptionDetails>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getQuotaDetails(subscriptionId: string, regionName: string): Observable<string> {
    const url = `${NprConfiguration.getSubscriptionInfoReqUrl}/${subscriptionId}/quotaDetails?region=${regionName}`;
    return this.http.get<string>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getParentRequest(requestId: string): Observable<IParentRequest> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/${requestId}`;
    return this.http.get<IParentRequest>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getSubRequestByUser(): Observable<ISubRequest[]> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/subrequests/mine`;
    return this.http.get<ISubRequest[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getParentRequestAndWorkItemByUser(incompleteWorkItems: boolean): Observable<MyRequestAndWorkItems> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/mine?incompleteWorkItems=${incompleteWorkItems}`;
    return this.http.get<MyRequestAndWorkItems>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getTickets(requestId: string): Observable<Ticket[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/${requestId}/tickets`;
    return this.http.get<Ticket[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getSubRequestsWithTicketsInfo(searchRequests: SearchRequests): Observable<ISubRequestWithTicket[]> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/withTicketsInfo`;
    return this.http.post<ISubRequestWithTicket[]>(url, searchRequests, this.httpOptions).pipe(catchError(this.handleError));
  }

  getCisJobByRequestId(requestId: string): Observable<CisJob> {
    const url = `${NprConfiguration.getGenericReqUrl}/${requestId}/cisjob`;
    return this.http.get<CisJob>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  setSubRequestTags(requestId: string, tags: string): Observable<IStatusResponse> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/${requestId}/tags`;
    return this.http.post<IStatusResponse>(url, JSON.stringify(tags), this.httpOptions).pipe(catchError(this.handleError));
  }

  getWorkItemById(ticketId: string, region: string): Observable<IWorkItemResponse> {
    const url = `${NprConfiguration.getWorkItemReqUrl}/${ticketId}?regionName=${region}`;
    return this.http.get<IWorkItemResponse>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getIncompleteWorkItems(region: string): Observable<IWorkItemResponse[]> {
    let url = `${NprConfiguration.getWorkItemReqUrl}/status/incomplete`;
    if (region) {
      url += `?selectedRegion=${region}`;
    }
    return this.http.get<IWorkItemResponse[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  updateWorkItemById(ticketId: string, region: string, commentUpdate: CommentUpdate): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getWorkItemReqUrl}/${ticketId}/comments?regionName=${region}`;
    return this.http.post<IStatusResponse>(url, commentUpdate, this.httpOptions).pipe(catchError(this.handleError));
  }

  async WorkItemsBulkUpdate(workitemList: IWorkItemResponse[], comment: string) {
    const results = [] as Observable<IStatusResponse>[];
    workitemList.map((workitem) => {
      try {
        const commentUpdate = {} as CommentUpdate;
        commentUpdate.Comment = comment;
        this.updateWorkItemById(workitem.Id, workitem.ArmRegionName, commentUpdate)
          .toPromise()
          .then(() => {
            console.log(`workitem: ${workitem.Id} in region ${workitem.ArmRegionName} is updated.`);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (error) {
        this.handleError(error);
      }
    });
    return results;
  }

  getErrorParentRequests(): Observable<IParentRequest[]> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/status/error`;
    return this.http.get<IParentRequest[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllParentRequests(includeArchived = false): Observable<IParentRequest[]> {
    const url = `${environment.lionrockApiEndpoint}/api/requests?includeArchived=${includeArchived}`;
    return this.http.get<IParentRequest[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  retryParentRequests(requestId: string, forceRetry: boolean): Observable<IStatusResponse> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/${requestId}/retry?forceRetry=${forceRetry}`;
    return this.http.post<IStatusResponse>(url, null, this.httpOptions).pipe(catchError(this.handleError));
  }

  cancelParentRequests(requestId: string): Observable<IStatusResponse> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/${requestId}/cancel`;
    return this.http.post<IStatusResponse>(url, null, this.httpOptions).pipe(catchError(this.handleError));
  }

  skipDependenciesOfParentRequests(requestId: string): Observable<IStatusResponse> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/${requestId}/skipdependencies`;
    return this.http.post<IStatusResponse>(url, null, this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllSubRequests(showArchived = false): Observable<ISubRequest[]> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/subrequests?includeArchived=${showArchived}`;
    return this.http.get<ISubRequest[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPendingApprovalRequests(): Observable<ISubRequest[]> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/subrequests/pendingapproval`;
    return this.http.get<ISubRequest[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getErrorSubRequests(): Observable<ISubRequest[]> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/subrequests/status/error`;
    return this.http.get<ISubRequest[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  retrySubRequests(requestId: string): Observable<IStatusResponse> {
    const req = ParseRequestId(requestId);
    const url = `${environment.lionrockApiEndpoint}/api/requests/${req.parentRequestId}/subrequests/${req.subRequestId}/retry`;
    return this.http.post<IStatusResponse>(url, JSON.stringify(requestId), this.httpOptions).pipe(catchError(this.handleError));
  }

  skipDependenciesOfSubRequests(requestId: string): Observable<IStatusResponse> {
    const req = ParseRequestId(requestId);
    const url = `${environment.lionrockApiEndpoint}/api/requests/${req.parentRequestId}/subrequests/${req.subRequestId}/skipdependencies`;
    return this.http.post<IStatusResponse>(url, JSON.stringify(requestId), this.httpOptions).pipe(catchError(this.handleError));
  }

  cancelSubRequests(requestId: string, comment: string, sendEmail: boolean): Observable<string> {
    const req = ParseRequestId(requestId);
    const url = `${environment.lionrockApiEndpoint}/api/requests/${req.parentRequestId}/subrequests/${req.subRequestId}/cancel?sendEmail=${sendEmail}`;
    return this.http.post<string>(url, JSON.stringify(comment), this.httpOptions).pipe(catchError(this.handleError));
  }

  getRegionList(includeDisabled = false, includeAirGapped = true): Observable<Region[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/regions?includeDisabled=${includeDisabled}&includeAirGapped=${includeAirGapped}`;
    return this.http.get<Region[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getEdgeZoneList(): Observable<EdgeZone[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/edgezones`;
    return this.http.get<EdgeZone[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getRegionSupportInfo(): Observable<RegionSupportInfo[]> {
    const url = `${NprConfiguration.selfServiceUrl}/info/ARM/regions`;
    return this.http.get<RegionSupportInfo[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getARMComputeSubscriptionSupportInfo(subscriptionId: string, region: string): Observable<SubscriptionSupportInfoARM> {
    const url = `${NprConfiguration.selfServiceUrl}/info/ARM/regions/${region}/services/compute/subscriptions/${subscriptionId}`;
    return this.http.get<SubscriptionSupportInfoARM>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getSubscriptionSupportInfo(subscriptionId: string, region: string): Observable<SubscriptionSupportInfo> {
    const url = `${NprConfiguration.selfServiceUrl}/info/ARM/regions/${region}/subscriptions/${subscriptionId}`;
    return this.http.get<SubscriptionSupportInfo>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getRDFESubscriptionSupportInfo(subscriptionId: string, cloud: string): Observable<SubscriptionSupportInfoRDFE> {
    const url = `${NprConfiguration.selfServiceUrl}/info/RDFE/clouds/${cloud}/subscriptions/${subscriptionId}`;
    return this.http.get<SubscriptionSupportInfoRDFE>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getARMStorageSubscriptionSupportInfo(subscriptionId: string, region: string): Observable<SubscriptionRegionSupportInfo> {
    const url = `${NprConfiguration.selfServiceUrl}/info/ARM/regions/${region}/services/storage/subscriptions/${subscriptionId}`;
    return this.http.get<SubscriptionRegionSupportInfo>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getArmResourceTypeSubscriptionSupportInfo(subscriptionId: string, region: string): Observable<string[]> {
    const url = `${NprConfiguration.selfServiceUrl}/info/ARM/regions/${region}/services/armresourcetype/subscriptions/${subscriptionId}`;
    return this.http.get<SubscriptionRegionSupportInfo>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getVmDiskSubscriptionSupportInfo(subscriptionId: string, region: string): Observable<VmDisk[]> {
    const url = `${NprConfiguration.selfServiceUrl}/info/ARM/regions/${region}/services/vmdisk/subscriptions/${subscriptionId}`;
    return this.http.get<VmDisk[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getRestrictedRegionList(): Observable<Region[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/regions/restricted`;
    return this.http.get<Region[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getRestrictedRegionListForCosmosDB(): Observable<string[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/regions/restricted/cosmosDB`;
    return this.http.get<string[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getRegionConfig(): Observable<RegionConfig[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/regions/config`;
    return this.http.get<RegionConfig[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getTeamConfig(): Observable<TeamConfig[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/teams/config`;
    return this.http.get<TeamConfig[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getDefaultTeamConfig(): Observable<TeamConfig> {
    const url = `${NprConfiguration.getGenericReqUrl}/teams/config/default`;
    return this.http.get<TeamConfig>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  setTeamConfig(data: TeamConfig): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/teams/config`;
    return this.http.put<IStatusResponse>(url, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteTeamConfig(oid: string, region: string): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/teams/${oid}/regions/${region}/config`;
    return this.http.delete<IStatusResponse>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getEdgeZoneConfig(): Observable<EdgeZoneConfig[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/edgezones/config`;
    return this.http.get<EdgeZoneConfig[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getDefaultRegionConfig(): Observable<RegionConfig> {
    const url = `${NprConfiguration.getGenericReqUrl}/regions/config/default`;
    return this.http.get<RegionConfig>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  setRegionConfig(data: RegionConfig): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/regions/config`;
    return this.http.put<IStatusResponse>(url, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  setEdgeZoneConfig(data: EdgeZoneConfig): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/edgezones/config`;
    return this.http.put<IStatusResponse>(url, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  getAzEntity(): Observable<AZ[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/regions/azentity`;
    return this.http.get<AZ[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteRegionConfig(data: RegionConfig): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/regions/${data.RegionName}/config`;
    return this.http.delete<IStatusResponse>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteEdgeZoneConfig(data: EdgeZoneConfig): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/edgezones/${data.EdgeZoneId}/config`;
    return this.http.delete<IStatusResponse>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getUserRoleList(): Observable<UserRole[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/userroles`;
    return this.http.get<UserRole[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  addUserRole(data: UserRole): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/userroles`;
    return this.http.post<IStatusResponse>(url, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  setUserRole(data: UserRole): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/userroles`;
    return this.http.put<IStatusResponse>(url, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteUserRole(data: UserRole): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/userroles/upn/${data.Upn}/role/${data.Role}`;
    return this.http.delete<IStatusResponse>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getApproverRoles(): Observable<string[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/approverroles`;
    return this.http.get<string[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getApproverRoleTypes(): Observable<string[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/approverroletypes`;
    return this.http.get<string[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getApprovers(): Observable<UserRole[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/approvers`;
    return this.http.get<UserRole[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  addApprovers(data: UserRole): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/approvers`;
    return this.http.post<IStatusResponse>(url, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  setApprovers(data: UserRole): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/approvers`;
    return this.http.put<IStatusResponse>(url, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteApprovers(data: UserRole[]): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/approvers/delete`;
    return this.http.put<IStatusResponse>(url, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  getAutoApprovalRuleList(): Observable<AutoApprovalRule[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/autoapprovalrules`;
    return this.http.get<AutoApprovalRule[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getHoboAutoApprovalRuleList(): Observable<HoboSubscriptionAutoApprovalRule[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/autoapprovalrules-hobo`;
    return this.http.get<HoboSubscriptionAutoApprovalRule[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getReqByStatus(): Observable<ISubRequest[]> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/subrequests/status/${status}`;
    return this.http.get<ISubRequest[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  updateAutoApprovalRule(data: AutoApprovalRule): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/autoapprovalrules`;
    return this.http.put<IStatusResponse>(url, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  insertAutoApprovalRule(data: AutoApprovalRule): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/autoapprovalrules`;
    return this.http.post<IStatusResponse>(url, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteAutoApprovalRule(id: number): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/autoapprovalrules/id/${id}`;
    return this.http.delete<IStatusResponse>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  updateHoboAutoApprovalRule(data: HoboSubscriptionAutoApprovalRule): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/autoapprovalrules-hobo`;
    return this.http.put<IStatusResponse>(url, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  insertHoboAutoApprovalRule(data: HoboSubscriptionAutoApprovalRule): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/autoapprovalrules-hobo`;
    return this.http.post<IStatusResponse>(url, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteHoboAutoApprovalRule(id: number): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/autoapprovalrules-hobo/id/${id}`;
    return this.http.delete<IStatusResponse>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getSubscriptionThresholdList(): Observable<SubscriptionThreshold[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/adminView/subCount`;
    return this.http.get<SubscriptionThreshold[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  updateSubscriptionThreshold(data: SubscriptionThreshold): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/adminView/subCount`;
    return this.http.put<IStatusResponse>(url, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  insertSubscriptionThreshold(data: SubscriptionThreshold): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/adminView/subCount`;
    return this.http.post<IStatusResponse>(url, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteSubscriptionThreshold(id: number): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/adminView/subCount/id/${id}`;
    return this.http.delete<IStatusResponse>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getRegionalQuotaLimitList(): Observable<RegionalQuotaLimit[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/adminView/regionalQuota`;
    return this.http.get<RegionalQuotaLimit[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  updateRegionalQuotaLimit(data: RegionalQuotaLimit): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/adminView/regionalQuota`;
    return this.http.put<IStatusResponse>(url, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  insertRegionalQuotaLimit(data: RegionalQuotaLimit): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/adminView/regionalQuota`;
    return this.http.post<IStatusResponse>(url, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteRegionalQuotaLimit(id: number): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/adminView/regionalQuota/${id}`;
    return this.http.delete<IStatusResponse>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getServiceHandlingList(): Observable<Config[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/adminView/serviceHandling`;
    return this.http.get<Config[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  updateServiceHandling(data: Config): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/adminView/serviceHandling`;
    return this.http.put<IStatusResponse>(url, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  insertServiceHandling(data: Config): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/adminView/serviceHandling`;
    return this.http.post<IStatusResponse>(url, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteServiceHandling(id: number): Observable<IStatusResponse> {
    const url = `${NprConfiguration.getGenericReqUrl}/adminView/serviceHandling/id/${id}`;
    return this.http.delete<IStatusResponse>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getVmSkus(): Observable<VmSku[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/vmSkus`;
    return this.http.get<VmSku[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllVmSkuEntities(): Observable<VmSku[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/VmSkuEntities`;
    return this.http.get<VmSku[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getVmSkusByRegion(region?: string): Observable<VmSku[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/vmSkus/availability?region=${region || ""}`;
    return this.http.get<VmSku[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getVmSizes(): Observable<VmSize[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/vmSizes`;
    return this.http.get<VmSize[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getHdiSkus(): Observable<HdiSku[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/hdi/skus`;
    return this.http.get<HdiSku[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getBatchSkus(): Observable<BatchSku[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/batch/skus`;
    return this.http.get<BatchSku[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getAppServiceSkus(): Observable<string[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/appService/skus`;
    return this.http.get<string[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getArmResourceTypeByRegion(region: string): Observable<ArmResourceType[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/armResourceType/regions/${region}`;
    return this.http.get<ArmResourceType[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getVmDiskByRegion(region: string): Observable<VmDisk[]> {
    const url = `${NprConfiguration.baseApiUrl}/vmDisk/regions/${region}`;
    return this.http.get<VmDisk[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getVmDisk(): Observable<VmDisk[]> {
    const url = `${NprConfiguration.baseApiUrl}/vmDisk`;
    return this.http.get<VmDisk[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getTeamList(): Observable<Team[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/teams`;
    return this.http.get<Team[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getEmailsColumnSelection(columnName: string): Observable<string[]> {
    const url = `${NprConfiguration.baseApiUrl}/emails/columns/${columnName}`;
    return this.http.get<string[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getEmailsPagination(startRow: number, endRow: number, sortModel: any, filterModel: any): Observable<EmailsPagination> {
    const url = `${NprConfiguration.baseApiUrl}/emails/pagination`;
    const body = {
      StartRow: startRow,
      EndRow: endRow,
      SortModels: sortModel,
      FilterModels: filterModel,
    };
    return this.http.post<EmailsPagination>(url, body, this.httpOptions).pipe(catchError(this.handleError));
  }

  getServiceStatus(region: string, isFlighting: boolean): Observable<{ [key: string]: string }> {
    const url = `${NprConfiguration.baseApiUrl}/regions/${region}/serviceStatus?isFlighting=${isFlighting}`;
    const response = this.http.get<{ [key: string]: string }>(url, this.httpOptions);
    return response;
  }

  createRequest(request: IUIRequest): Observable<string> {
    return this.http.post<string>(NprConfiguration.newRequestUrl, request, this.httpOptions).pipe(catchError(this.handleError));
  }

  validateRequest(request: IUIRequest, screenNumber: number): Observable<string> {
    const url = `${NprConfiguration.newRequestUrl}/validate${screenNumber === 1 ? "/basicInfo" : ""}`;
    const response = this.http.post<string>(url, request, this.httpOptions).pipe(catchError(this.handleError));
    return response;
  }

  checkParentRequestDuplicates(request: IUIRequest): Observable<IParentRequest[]> {
    return this.http
      .post<IParentRequest[]>(NprConfiguration.newRequestUrl + "/duplicates/parent-request", request, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getAllCapacityOrders(): Observable<CapacityOrder[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/capacity-order`;
    return this.http.get<CapacityOrder[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  refreshCapacityOrder(id: string): Observable<CapacityOrderDetails> {
    const url = `${NprConfiguration.baseApiUrl}/capacityOrders/${id}/refresh`;
    return this.http.post<CapacityOrderDetails>(url, JSON.stringify(id), this.httpOptions).pipe(catchError(this.handleError));
  }

  getServiceTreeIdsByPsl(psl: string): Observable<string[]> {
    const url = `${NprConfiguration.baseApiUrl}/psl/${psl}`;
    return this.http.get<string[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  // Use arrow function so this context is not lost
  private handleError = (error: HttpErrorResponse) => {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `A client-side or network error occurred: ${error.error.message}`;
    } else {
      if (error.status === 0) {
        errorMessage = "AJAX request was cancelled. ";
        return of(null);
      }
      // The backend returned an unsuccessful response code.
      else if (error.status === 404) {
        errorMessage = "Resource not found. ";
        // TODO: handle 404 globally
      } else if (error.status === 403) {
        errorMessage = "Access denied. ";
        // TODO: handle 404 globally
      }

      // The response body may contain clues as to what went wrong.
      if (error.error?.message) {
        // process errors with expected structure. All error response from Lionrock should contain a "message" field (and a "code" field).
        errorMessage += `Server response: ${error.error.message}`;
      } else if (error.error) {
        errorMessage += `Server response: ${error.error}`;
      } else if (error.message) {
        /**
         * process errors with unexpected structure.
         * Maybe the error response is generated by a middleware rather than the code of Lionrock.
         * We should try to handle such cases on the backend, and here is just a fallback.
         */
        errorMessage += `Server response: ${error.message}`;
      }
    }

    // Return an observable with a user-facing error message.
    console.error(errorMessage);
    return throwError(errorMessage);
  };

  getQuotaImplications(team: string, region: string): Observable<IQuotaImplicationsData[]> {
    const url = `${NprConfiguration.getQuotaImplicationsUrl}?teamOid=${team}&region=${region}`;
    return this.http.get<IQuotaImplicationsData[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  setAdminUiGeneralInfo(data: any, apiString: string): Observable<void> {
    const url = `${NprConfiguration.setAdminUiInfo}/${apiString}`;
    const payload = data;
    return this.http.put<void>(url, payload, this.httpOptions).pipe(catchError(this.handleError));
  }

  getMyPlans(): Observable<Plan[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/mine`;
    return this.http.get<Plan[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlans(): Observable<Plan[]> {
    return this.http.get<Plan[]>(PlannedQuotaConfiguration.plansUrl, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlan(serviceTreeId: string): Observable<Plan> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}`;
    return this.http.get<Plan>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlansHistory(serviceTreeId: string): Observable<Plan[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/history`;
    return this.http.get<Plan[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  assignPlanToRegion(payload: PlanSubmission) {
    const url = `${PlannedQuotaConfiguration.plansUrl}/assign?softDeleteOldVersions=false`;
    return this.http.post<AssignPlanToRegion>(url, payload, this.httpOptions);
  }

  assignPlanAndSoftDeleteOldVersions(payload: PlanSubmission) {
    const url = `${PlannedQuotaConfiguration.plansUrl}/assign?softDeleteOldVersions=true`;
    return this.http.post(url, payload, this.httpOptions);
  }

  requeuePlanRegion(payload: PlanSubmission) {
    const url = `${PlannedQuotaConfiguration.plansUrl}/requeue`;
    return this.http.post(url, payload, this.httpOptions).pipe(catchError(this.handleError));
  }

  signOffPlanRegions(payload: PlanSubmission[]) {
    const url = `${PlannedQuotaConfiguration.plansUrl}/signoff`;
    return this.http.post(url, payload, this.httpOptions).pipe(catchError(this.handleError));
  }

  approvePlanRegion(serviceTreeId: string, region: string, planRegionOverallApproval: PlanRegionOverallApproval): Observable<OkMessage> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/regions/${region}/approve`;
    return this.http.post<OkMessage>(url, planRegionOverallApproval, this.httpOptions).pipe(catchError(this.handleError));
  }

  rejectPlanRegion(serviceTreeId: string, region: string, planRejectData: PlanRejectData) {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/regions/${region}/reject`;
    return this.http.post(url, planRejectData, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlanRegion(serviceTreeId: string, region: string, version: number, stage: string): Observable<PlanRegion> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/regions/${region}/versions/${version}/stages/${stage}`;
    return this.http.get<PlanRegion>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlanRegionLatestVersion(serviceTreeId: string, region: string, stage: string): Observable<PlanRegion> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/regions/${region}/stages/${stage}/latest`;
    return this.http.get<PlanRegion>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlanRegionDetail(serviceTreeId: string, region: string, version: number, stage: string): Observable<PlanRegionDetail> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/regions/${region}/versions/${version}/stages/${stage}/detail`;
    return this.http.get<PlanRegionDetail>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  updateValidToDate(serviceTreeId: string, region: string, version: number, stage: string, updateData: PlanRegionUpdateData) {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/regions/${region}/versions/${version}/stages/${stage}`;
    return this.http.put(url, updateData, this.httpOptions).pipe(catchError(this.handleError));
  }

  getRegionalPlans(status?: PlanRegionStatusEnum): Observable<PlanRegion[]> {
    let url = `${PlannedQuotaConfiguration.plansUrl}/regional`;
    if (status) {
      url = url + `?status=${status}`;
    }
    return this.http.get<PlanRegion[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getRegionalPlansByServiceTreeId(serviceTreeId: string): Observable<PlanRegion[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/regional`;
    return this.http.get<PlanRegion[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getApprovableRegionalPlans(): Observable<PlanRegion[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/regional/approvable`;
    return this.http.get<PlanRegion[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getLatestRegionalPlans(): Observable<PlanRegion[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/regional/latest`;
    return this.http.get<PlanRegion[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getRegionalPlanDiff(serviceTreeId: string, region: string, version: number, stage: string): Observable<PlanRegionDiff[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/regions/${region}/versions/${version}/stages/${stage}/diff`;
    return this.http.get<PlanRegionDiff[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getRegionalPlanRelatedCapacityOrders(serviceTreeId: string, region: string, version: number, stage: string): Observable<CapacityOrder[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/regions/${region}/versions/${version}/stages/${stage}/capacity-order`;
    return this.http.get<CapacityOrder[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getRegionalPlanApprovals(serviceTreeId: string, region: string, version: number): Observable<PlanRegionApproval[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/regions/${region}/versions/${version}/approvals`;
    return this.http.get<PlanRegionApproval[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getRegionalQuotaStatistics(region: string): Observable<RegionalQuotaStatistics[]> {
    const url = `${environment.lionrockApiEndpoint}/api/plans/quota-statistics/regions/${region}`;
    return this.http.get<RegionalQuotaStatistics[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getMyRegionalPlans(): Observable<PlanRegion[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/regional/mine`;
    return this.http.get<PlanRegion[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getMyServiceTeamServiceTreeIds(): Observable<string[]> {
    const url = `${environment.lionrockApiEndpoint}/api/services/mine`;
    return this.http.get<string[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getMyServiceTeamUser(): Observable<User[]> {
    const url = `${environment.lionrockApiEndpoint}/api/services/mine/users`;
    return this.http.get<User[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  addMyServiceTeamUser(serviceTreeId: string, user: User[]): Observable<IStatusResponse> {
    const url = `${environment.lionrockApiEndpoint}/api/services/${serviceTreeId}/users`;
    return this.http.post<IStatusResponse>(url, user, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteMyServiceTeamUser(serviceTreeId: string, upn: string): Observable<IStatusResponse> {
    const url = `${environment.lionrockApiEndpoint}/api/services/${serviceTreeId}/users/${upn}`;
    return this.http.delete<IStatusResponse>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlannedQuotaRequests(region: string, subscriptionId: string, serviceTreeId: string): Observable<PlannedQuotaRequest[]> {
    const url = `${PlannedQuotaConfiguration.baseApiUrl}/requests?region=${region}&subscriptionId=${subscriptionId}&serviceTreeId=${serviceTreeId}`;
    return this.http.get<PlannedQuotaRequest[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlannedQuotaRequestById(requestId: string): Observable<PlannedQuotaRequestDetail> {
    const url = `${PlannedQuotaConfiguration.baseApiUrl}/requests/${requestId}`;
    return this.http.get<PlannedQuotaRequestDetail>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlannedQuotaRequestHistoryById(requestId: string): Observable<OperationLog[]> {
    const url = `${PlannedQuotaConfiguration.baseApiUrl}/requests/${requestId}/history`;
    return this.http.get<OperationLog[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  createPlannedQuotaRequest(request: PlannedQuotaRequest) {
    const url = `${PlannedQuotaConfiguration.baseApiUrl}/requests/create`;
    return this.http.post<PlannedQuotaRequest[]>(url, request, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlannedQuotaRequestsPagination(
    startRow: number,
    endRow: number,
    sortModel: any,
    filterModel: any
  ): Observable<PlannedQuotaRequestPagination> {
    const url = `${PlannedQuotaConfiguration.baseApiUrl}/requests/pagination`;
    const body = {
      StartRow: startRow,
      EndRow: endRow,
      SortModels: sortModel,
      FilterModels: filterModel,
    };
    return this.http.post<PlannedQuotaRequestPagination>(url, body, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlannedQuotaRequestsColumnSelection(columnName: string): Observable<string[]> {
    const url = `${PlannedQuotaConfiguration.baseApiUrl}/requests/columns/${columnName}`;
    return this.http.get<string[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getMyPlannedQuotaRequests(): Observable<PlannedQuotaRequest[]> {
    const url = `${PlannedQuotaConfiguration.baseApiUrl}/requests/mine`;
    return this.http.get<PlannedQuotaRequest[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getMyServicesPlannedQuotaRequests(): Observable<PlannedQuotaRequest[]> {
    const url = `${PlannedQuotaConfiguration.baseApiUrl}/requests/services/mine`;
    return this.http.get<PlannedQuotaRequest[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  retryPlannedQuotaRequest(requestId: string): Observable<IStatusResponse> {
    const url = `${PlannedQuotaConfiguration.baseApiUrl}/requests/${requestId}/retry`;
    return this.http.post<IStatusResponse>(url, JSON.stringify(requestId), this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlanFiles(serviceTreeId: string, version: number): Observable<PlanFile[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/files/versions/${version}`;
    return this.http.get<PlanFile[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getLatestPlanFiles(serviceTreeId: string): Observable<PlanFile[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/files/latest`;
    return this.http.get<PlanFile[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlanFileTemplate(serviceTreeId: string): Observable<PlanFile> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/files/template`;
    return this.http.get<PlanFile>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  softDeletePlanRegion(serviceTreeId: string, region: string, version: number, stage: string) {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/regions/${region}/versions/${version}/stages/${stage}/softDelete`;
    return this.http.post(url, {}, this.httpOptions).pipe(catchError(this.handleError));
  }

  refreshCapacityOrdersByPlanRegionAsync(serviceTreeId: string, region: string, version: number, stage: string): Observable<CapacityOrderRefreshResult[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/regions/${region}/versions/${version}/stages/${stage}/refresh`;
    return this.http.post(url, {}, this.httpOptions).pipe(catchError(this.handleError));
  }

  savePlanFile(serviceTreeId: string, planFile: PlanFile) {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/files`;
    return this.http.post(url, planFile, this.httpOptions).pipe(catchError(this.handleError));
  }

  deletePlanFile(serviceTreeId: string, fileName: string) {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/files/${fileName}`;
    return this.http.delete(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  commitPlan(serviceTreeId: string, payload: PlanCommit): Observable<CommitPlan> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/commit`;
    return this.http.post(url, payload, this.httpOptions).pipe(catchError(this.handleError));
  }

  onboardNewPlan(serviceTreeId: string) {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/onboard`;
    return this.http.post(url, {}, this.httpOptions).pipe(catchError(this.handleError));
  }

  undoOnboardPlan(serviceTreeId: string) {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/delete`;
    return this.http.delete(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlanJsonSchema(): Observable<PlanJsonSchema> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/schema/get`;
    return this.http.get<PlanJsonSchema>(url, this.httpOptions);
  }

  getPlannedQuotaSubscriptionBindings(): Observable<PlannedQuotaSubscriptionBinding[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/bindings/subscriptions`;
    return this.http.get<PlannedQuotaSubscriptionBinding[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlannedQuotaPropertyBindings(): Observable<PlannedQuotaPropertyBinding[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/bindings/properties`;
    return this.http.get<PlannedQuotaPropertyBinding[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlannedQuotaSubscriptionBindingsByServiceTreeId(serviceTreeId: string): Observable<PlannedQuotaSubscriptionBinding[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/bindings/subscriptions`;
    return this.http.get<PlannedQuotaSubscriptionBinding[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlannedQuotaPropertyBindingsByServiceTreeId(serviceTreeId: string): Observable<PlannedQuotaPropertyBinding[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/bindings/properties`;
    return this.http.get<PlannedQuotaPropertyBinding[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  addPlannedQuotaSubscriptionBindings(payload: PlannedQuotaSubscriptionBinding): Observable<IStatusResponse> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${payload.ServiceTreeId}/regions/${payload.Region}/bindings/subscriptions`;
    return this.http.post<PlannedQuotaSubscriptionBinding>(url, payload, this.httpOptions).pipe(catchError(this.handleError));
  }

  addPlannedQuotaPropertyBindings(payload: PlannedQuotaPropertyBinding): Observable<IStatusResponse> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${payload.ServiceTreeId}/regions/${payload.Region}/bindings/properties`;
    return this.http.post<PlannedQuotaPropertyBinding>(url, payload, this.httpOptions).pipe(catchError(this.handleError));
  }

  deletePlannedQuotaSubscriptionBindings(payload: PlannedQuotaSubscriptionBinding): Observable<IStatusResponse> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/regions/${payload.Region}/bindings/subscriptions/${payload.SubscriptionId}`;
    return this.http.delete<PlannedQuotaSubscriptionBinding>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  deletePlannedQuotaPropertyBindings(payload: PlannedQuotaPropertyBinding): Observable<IStatusResponse> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${payload.ServiceTreeId}/regions/${payload.Region}/bindings/subKey/${payload.SubscriptionKey}/key/${payload.BindingKey}/type/${payload.BindingType}/value/${payload.BindingValue}`;
    return this.http.delete<PlannedQuotaSubscriptionBinding>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlanRegions(serviceTreeId: string, region: string, stage: string): Observable<PlanRegion[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/regions/${region}/stages/${stage}`;
    return this.http.get<PlanRegion[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlanRegionStatus(region: string, serviceTreeId: string, stage: string): Observable<PlanRegionStatus> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/regions/${region}/stages/${stage}/status`;
    return this.http.get<PlanRegion[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlannedQuotaSubscriptionBindingsByRegionAndTeam(region: string, serviceTreeId: string): Observable<PlannedQuotaSubscriptionBinding[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/regions/${region}/bindings/subscriptions`;
    return this.http.get<PlannedQuotaSubscriptionBinding[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlannedQuotaPropertyBindingsByRegionAndTeam(region: string, serviceTreeId: string): Observable<PlannedQuotaPropertyBinding[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/services/${serviceTreeId}/regions/${region}/bindings/properties`;
    return this.http.get<PlannedQuotaPropertyBinding[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlannedQuotaStatusByRegion(region: string): Observable<PlanRegionStatus[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/regions/${region}/status`;
    return this.http.get<PlanRegionStatus[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPlansRisks(): Observable<PlansRisks> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/risks`;
    return this.http.get<PlansRisks>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getIcmNotifications(parentRequestId: string, requestSerivceType: string): Observable<IcmNotification[]> {
    const url = `${environment.lionrockApiEndpoint}/api/requests/${parentRequestId}/icmnotification/${requestSerivceType}`;
    return this.http.get<IcmNotification[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getServiceReadinesses(): Observable<ServiceReadiness[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/adminView/serviceReadiness`;
    return this.http.get<ServiceReadiness[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getServiceReadinessStages(): Observable<ServiceReadinessStage[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/adminView/serviceReadinessStage`;
    return this.http.get<ServiceReadinessStage[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getSqls(): Observable<Sql[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/adminView/sql`;
    return this.http.get<Sql[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getArmResourceTypes(): Observable<ArmResourceType[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/armResourceType`;
    return this.http.get<ArmResourceType[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getRegions(): Observable<RegionTable[]> {
    const url = `${NprConfiguration.getGenericReqUrl}/adminView/region`;
    return this.http.get<RegionTable[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getRegionalPlansAllVmSkus(): Observable<PlanDetail[]> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/regional/details`;
    return this.http.get<PlanDetail[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  addCapacityOrderMessage(orderId: string, subOrderId: string, message: Message): Observable<string> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/capacity-order/orders/${orderId}/subOrders/${subOrderId}/message`;
    return this.http.post<string>(url, message, this.httpOptions).pipe(catchError(this.handleError));
  }

  getCapacitySubOrderDetails(orderId: string, subOrderId: string): Observable<CapacitySubOrderDetails> {
    const url = `${PlannedQuotaConfiguration.plansUrl}/capacity-order/orders/${orderId}/subOrders/${subOrderId}`;
    return this.http.get<CapacitySubOrderDetails>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  // FFP plan related functions
  getAllFfpPlans(): Observable<FfpPlan[]> {
    return this.http.get<FfpPlan[]>(FfpConfiguration.ffpPlansUrl, this.httpOptions).pipe(catchError(this.handleError));
  }

  getMyFfpPlans(): Observable<FfpPlan[]> {
    const url = `${FfpConfiguration.ffpPlansUrl}/mine`;
    return this.http.get<FfpPlan[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getFfpPlan(serviceTreeId: string): Observable<FfpPlan> {
    const url = `${FfpConfiguration.ffpPlansUrl}/plans/services/${serviceTreeId}`;
    return this.http.get<FfpPlan>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getFfpPlansHistory(serviceTreeId: string): Observable<FfpPlan[]> {
    const url = `${FfpConfiguration.ffpPlansUrl}/plans/services/${serviceTreeId}/history`;
    return this.http.get<FfpPlan[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  onboardNewFfpPlan(serviceTreeId: string) {
    const url = `${FfpConfiguration.ffpPlansUrl}/plans/services/${serviceTreeId}/onboard`;
    return this.http.post(url, {}, this.httpOptions).pipe(catchError(this.handleError));
  }

  undoOnboardFfpPlan(serviceTreeId: string) {
    const url = `${FfpConfiguration.ffpPlansUrl}/plans/services/${serviceTreeId}/delete`;
    return this.http.delete(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getFfpPlanFiles(serviceTreeId: string, version: number): Observable<FfpPlanFile[]> {
    const url = `${FfpConfiguration.ffpPlansUrl}/plans/services/${serviceTreeId}/files/versions/${version}`;
    return this.http.get<FfpPlanFile[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getLatestFfpPlanFiles(serviceTreeId: string): Observable<FfpPlanFile[]> {
    const url = `${FfpConfiguration.ffpPlansUrl}/plans/services/${serviceTreeId}/files/latest`;
    return this.http.get<FfpPlanFile[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getFfpPlanFileTemplate(serviceTreeId: string): Observable<FfpPlanFile> {
    const url = `${FfpConfiguration.ffpPlansUrl}/plans/services/${serviceTreeId}/files/template`;
    return this.http.get<FfpPlanFile>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  saveFfpPlanFile(serviceTreeId: string, ffpPlanFile: FfpPlanFile) {
    const url = `${FfpConfiguration.ffpPlansUrl}/plans/services/${serviceTreeId}/files`;
    return this.http.post(url, ffpPlanFile, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteFfpPlanFile(serviceTreeId: string, fileName: string) {
    const url = `${FfpConfiguration.ffpPlansUrl}/plans/services/${serviceTreeId}/files/${fileName}`;
    return this.http.delete(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  commitFfpPlan(serviceTreeId: string, payload: PlanCommit): Observable<CommitFfpPlan> {
    const url = `${FfpConfiguration.ffpPlansUrl}/plans/services/${serviceTreeId}/commit`;
    return this.http.post(url, payload, this.httpOptions).pipe(catchError(this.handleError));
  }

  getFfpPlanJsonSchema(): Observable<PlanJsonSchema> {
    const url = `${FfpConfiguration.ffpPlansUrl}/plans/schema/get`;
    return this.http.get<PlanJsonSchema>(url, this.httpOptions);
  }

  getLatestFfpRegionalPlans(): Observable<FfpPlanRegion[]> {
    const url = `${FfpConfiguration.ffpPlansUrl}/plans/regional/latest`;
    return this.http.get<FfpPlanRegion[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  assignFfpPlanToRegion(payload: FfpPlanSubmission) {
    const url = `${FfpConfiguration.ffpPlansUrl}/plans/assign`;
    return this.http.post<FfpAssignPlanToRegion>(url, payload, this.httpOptions);
  }

  getFfpPlanRegion(serviceTreeId: string, region: string, version: number): Observable<FfpPlanRegion> {
    const url = `${FfpConfiguration.ffpPlansUrl}/plans/services/${serviceTreeId}/regions/${region}/versions/${version}`;
    return this.http.get<FfpPlanRegion>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getFfpPlanRegionLatestVersion(serviceTreeId: string, region: string): Observable<FfpPlanRegion> {
    const url = `${FfpConfiguration.ffpPlansUrl}/plans/services/${serviceTreeId}/regions/${region}/latest`;
    return this.http.get<FfpPlanRegion>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getFfpRegionalPlanApprovals(serviceTreeId: string, region: string, version: number): Observable<FfpPlanRegionApproval[]> {
    const url = `${FfpConfiguration.ffpPlansUrl}/plans/services/${serviceTreeId}/regions/${region}/versions/${version}/approvals`;
    return this.http.get<FfpPlanRegionApproval[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  approveFfpPlanRegion(serviceTreeId: string, region: string, ffpPlanRegionOverallApproval: FfpPlanRegionOverallApproval): Observable<OkMessage> {
    const url = `${FfpConfiguration.ffpPlansUrl}/plans/services/${serviceTreeId}/regions/${region}/approve`;
    return this.http.post<OkMessage>(url, ffpPlanRegionOverallApproval, this.httpOptions).pipe(catchError(this.handleError));
  }

  rejectFfpPlanRegion(serviceTreeId: string, region: string, ffpPlanRejectData: FfpPlanRejectData) {
    const url = `${FfpConfiguration.ffpPlansUrl}/plans/services/${serviceTreeId}/regions/${region}/reject`;
    return this.http.post(url, ffpPlanRejectData, this.httpOptions).pipe(catchError(this.handleError));
  }

  getFfpMyRegionalPlans(): Observable<FfpPlanRegionDetail[]> {
    const url = `${FfpConfiguration.ffpPlansUrl}/plans/regional/mine`;
    return this.http.get<FfpPlanRegionDetail[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getFfpRegionBuildouts(): Observable<RegionBuildout[]> {
    const url = `${FfpConfiguration.ffpPlansUrl}/buildout`;
    return this.http.get<RegionBuildout[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  newFfpRegionBuildout(region: string) {
    const url = `${FfpConfiguration.ffpPlansUrl}/buildout/regions/${region}`;
    return this.http.post(url, {}, this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllLatestFfpPlansByRegion(region: string): Observable<FfpPlanRegion[]> {
    const url = `${FfpConfiguration.ffpPlansUrl}/buildout/regions/${region}`;
    return this.http.get<FfpPlanRegion[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  lockFfpRegionBuildout(region: string) {
    const url = `${FfpConfiguration.ffpPlansUrl}/buildout/regions/${region}/lock`;
    return this.http.post(url, {}, this.httpOptions).pipe(catchError(this.handleError));
  }

  getLatestApprovedFfpPlanFiles(serviceTreeId: string, region: string): Observable<FfpPlanFile[]> {
    const url = `${FfpConfiguration.ffpPlansUrl}/plans/services/${serviceTreeId}/regions/${region}/files/latestApproved`;
    return this.http.get<FfpPlanFile[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  // PF plan related functions
  getAllPfPlans(): Observable<PfPlan[]> {
    return this.http.get<PfPlan[]>(PfConfiguration.pfUrl, this.httpOptions).pipe(catchError(this.handleError));
  }

  getMyPfPlans(): Observable<PfPlan[]> {
    const url = `${PfConfiguration.pfUrl}/mine`;
    return this.http.get<PfPlan[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPfPlan(serviceTreeId: string, version: number): Observable<PfPlan> {
    const url = `${PfConfiguration.pfUrl}/services/${serviceTreeId}/version/${version}`;
    return this.http.get<PfPlan>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPfPlanLatestVersion(serviceTreeId: string): Observable<PfPlan> {
    const url = `${PfConfiguration.pfUrl}/services/${serviceTreeId}`;
    return this.http.get<PfPlan>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPfPlansHistory(serviceTreeId: string): Observable<PfPlan[]> {
    const url = `${PfConfiguration.pfUrl}/services/${serviceTreeId}/history`;
    return this.http.get<PfPlan[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  onboardNewPfPlan(serviceTreeId: string) {
    const url = `${PfConfiguration.pfUrl}/services/${serviceTreeId}/onboard`;
    return this.http.post(url, {}, this.httpOptions).pipe(catchError(this.handleError));
  }

  undoOnboardPfPlan(serviceTreeId: string) {
    const url = `${PfConfiguration.pfUrl}/services/${serviceTreeId}/delete`;
    return this.http.delete(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPfPlanFiles(serviceTreeId: string, version: number): Observable<PfPlanFile[]> {
    const url = `${PfConfiguration.pfUrl}/services/${serviceTreeId}/files/versions/${version}`;
    return this.http.get<PfPlanFile[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getLatestApprovedPfPlanFiles(serviceTreeId: string): Observable<PfPlanFile[]> {
    const url = `${PfConfiguration.pfUrl}/services/${serviceTreeId}/files/latestApproved`;
    return this.http.get<PfPlanFile[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }
  getLatestPfPlanFiles(serviceTreeId: string): Observable<PfPlanFile[]> {
    const url = `${PfConfiguration.pfUrl}/services/${serviceTreeId}/files/latest`;
    return this.http.get<PfPlanFile[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPfPlanFileTemplate(serviceTreeId: string): Observable<PfPlanFile> {
    const url = `${PfConfiguration.pfUrl}/services/${serviceTreeId}/files/template`;
    return this.http.get<PfPlanFile>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  getApprovablePfPlans(): Observable<PfPlan[]> {
    const url = `${PfConfiguration.pfPlansUrl}/approvable`;
    return this.http.get<PfPlan[]>(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  savePfPlanFile(serviceTreeId: string, pfPlanFile: PfPlanFile) {
    const url = `${PfConfiguration.pfUrl}/services/${serviceTreeId}/files`;
    return this.http.post(url, pfPlanFile, this.httpOptions).pipe(catchError(this.handleError));
  }

  deletePfPlanFile(serviceTreeId: string, fileName: string) {
    const url = `${PfConfiguration.pfUrl}/services/${serviceTreeId}/files/${fileName}`;
    return this.http.delete(url, this.httpOptions).pipe(catchError(this.handleError));
  }

  commitPfPlan(serviceTreeId: string, payload: PlanCommit): Observable<CommitPfPlan> {
    const url = `${PfConfiguration.pfUrl}/services/${serviceTreeId}/commit`;
    return this.http.post(url, payload, this.httpOptions).pipe(catchError(this.handleError));
  }

  approvePfPlan(serviceTreeId: string, version: number): Observable<OkMessage> {
    const url = `${PfConfiguration.pfPlansUrl}/services/${serviceTreeId}/approve`;
    return this.http.post(url, version, this.httpOptions).pipe(catchError(this.handleError));
  }

  rejectPfPlan(serviceTreeId: string, pfpLanRejectData: PfPlanRejectData) {
    const url = `${PfConfiguration.pfPlansUrl}/services/${serviceTreeId}/reject`;
    return this.http.post(url, pfpLanRejectData, this.httpOptions).pipe(catchError(this.handleError));
  }

  getPfPlanJsonSchema(): Observable<PlanJsonSchema> {
    const url = `${PfConfiguration.pfUrl}/schema/get`;
    return this.http.get<PlanJsonSchema>(url, this.httpOptions);
  }

  async setEditModalData(data: any, func: (data: any) => IStatusResponse) {
    try {
      const response = func(data);
      return response;
    } catch (error) {
      this.handleError(error);
    }
  }
}

export class Guid {
  constructor(public guid: string) { }

  // Static member
  static MakeNew(): Guid {
    let result: string;
    let i: string;
    let j: number;

    result = "";
    for (j = 0; j < 32; j++) {
      if (j === 8 || j === 12 || j === 16 || j === 20) {
        result = result + "-";
      }
      i = Math.floor(Math.random() * 16)
        .toString(16)
        .toUpperCase();
      result = result + i;
    }
    return new Guid(result);
  }

  public ToString(): string {
    return this.guid;
  }
}
