import { environment } from "../environments/environment";

export const NprConfiguration = {
  // Enable/disable support on Customer Query
  allowCustomerQuery: false,

  // API Urls
  baseApiUrl: environment.lionrockApiEndpoint + "/api",
  getProfileUrl: environment.lionrockApiEndpoint + "/api/profile",
  newRequestUrl: environment.lionrockApiEndpoint + "/api/requests",
  getGenericReqUrl: environment.lionrockApiEndpoint + "/api/nprrequest",
  getWorkItemReqUrl: environment.lionrockApiEndpoint + "/api/nprrequest/workitems",
  getSubscriptionInfoReqUrl: environment.lionrockApiEndpoint + "/api/subscription",
  selfServiceUrl: environment.lionrockApiEndpoint + "/api/selfService",
  getQuotaImplicationsUrl: environment.lionrockApiEndpoint + "/api/nprrequest/quotaImplications", // team={team}&region={region}
  setAdminUiInfo: environment.lionrockApiEndpoint + "/api/nprrequest", //regions/data={data}

  // Regular expression for validation
  guidRegExpression: "^([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})$",
  subsRegExpression:
    "^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}(,[ ]*[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})*$",
  emailRegExpression: "^[A-Z0-9a-z._%+-]+@[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)+$",
  quotaRegExpression: "^[0-9]+$",
  nameRegExpression: "^[A-Z0-9a-z._-]+([,]+[\\s]*[A-Z0-9a-z._-]+)*$",
};

export const BetConfiguration = {
  betCheckPermissionUrl: environment.betApiEndpoint + "/api/checkPermission",
};

export const PlannedQuotaConfiguration = {
  baseApiUrl: environment.lionrockApiEndpoint + "/api/planned",
  plansUrl: environment.lionrockApiEndpoint + "/api/plans",
};

export const FfpConfiguration = {
  ffpPlansUrl: environment.lionrockApiEndpoint + "/api/ffp",
}

export const PfConfiguration = {
  pfUrl: environment.lionrockApiEndpoint + "/api/pf",
  pfPlansUrl: environment.lionrockApiEndpoint + "/api/pf/plans",
}