<div class="title" #title>
  <div class="container container-max-width">
    <div>
      <h4>Review FFP Plan</h4>
      <p>Review the diff between approved and new plan versions</p>
    </div>
    <div class="action-container">
      <button type="submit" class="btn btn-success" (click)="showApproveFfpPlanDialog()" [disabled]="!canApprove">Approve</button>&nbsp;
      <button type="submit" class="btn btn-danger" (click)="showRejectFfpPlanDialog()" [disabled]="!canReject">Reject</button>
    </div>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
    <div class="landing-main">
        <div class="top-panel" style="height: fit-content">
            <ul class="list-container col-md-12" *ngIf="ffpPlanRegion">
                <li class="flex-box mb-3">
                    <div class="col-md-2 label">Service Team</div>
                    <div class="col-md-10 value">{{ ffpPlanRegion.ServiceTeam }}&nbsp;({{ ffpPlanRegion.ServiceTreeId }})</div>
                </li>
                <li class="flex-box mb-3">
                    <div class="col-md-2 label">Region</div>
                    <div class="col-md-10 value">{{ ffpPlanRegion.Region }}</div>
                </li>
                <li class="flex-box mb-3">
                    <div class="col-md-2 label">Ring</div>
                    <div class="col-md-10 value">{{ ffpPlanRegion.Ring }}</div>
                </li>
                <li class="flex-box mb-3">
                    <div class="col-md-2 label">Version</div>
                    <div class="col-md-10 value">
                        {{ ffpPlanRegion.Version }} &nbsp;&nbsp;(
                        <b *ngIf="ffpPlanRegion.Version != latestVersion; else userTemplate">
                            Latest version is
                            <a href="{{ ffpPlanLatestVersionReviewLink }}"
                               class="topbar-item"
                               target="_blank">
                                {{ latestVersion }}
                            </a>
                        </b>
                        <ng-template #userTemplate>Latest version</ng-template>)
                    </div>
                </li>
                <li class="flex-box mb-3">
                    <div class="col-md-2 label">Status</div>
                    <div class="col-md-10 value">
                        {{ showStatus(ffpPlanRegion) }}
                        <!--<span *ngIf="showRejectComment" [ngbTooltip]="ffpPlanRegion.RejectComment"><i class="fa fa-info-circle"></i></span>-->
                    </div>
                </li>
                <li class="flex-box mb-3">
                    <div class="col-md-2 label">Submitted</div>
                    <div class="col-md-10 value">{{ showSubmit(ffpPlanRegion) }}</div>
                </li>
            </ul>
        </div>

        <div class="card mb-4">
            <div class="card-header">
                <div>
                    <span class="bold-font">Approvals</span>
                </div>
            </div>
            <div class="card-block p-3">
                <ag-grid-angular #agGrid
                                 id="ffpPlanRegionApprovalsGrid"
                                 style="width: 100%; height: 320px"
                                 class="ag-theme-balham"
                                 [masterDetail]="true"
                                 [gridOptions]="ffpPlanRegionApprovalsGridOptions"
                                 [rowData]="ffpPlanRegionApprovalsRowData"
                                 pagination="true"
                                 (gridReady)="onGridReady($event)">
                </ag-grid-angular>
            </div>
        </div>

        <div class="card mb-2 mt-4" style="clear: both">
            <div class="card-header flex-row">
                <div class="float-left">
                    <span class="bold-font">default.json Diff</span>
                </div>
            </div>
            <div class="modal-body editor-container">
                <monaco-diff-editor theme="vs" (ready)="onEditorReady($event)"></monaco-diff-editor>
            </div>
        </div>
    </div>
</div>
