<div class="title" #title>
    <div class="container container-max-width">
      <div>
        <h4>FFP Buildouts</h4>
        <p>Region Buildout</p>
      </div>
      <div class="action-container">
        <button type="submit" class="btn btn-primary" (click)="showRegionBuildoutDialog()" *ngIf="isFfpAdmin" data-test="RegionBuildout">
          New FFP Buildout
        </button>
      </div>
    </div>
  </div>
  <div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
    <div class="landing-main mt-3">
      <ag-grid-angular
        #agGrid
        id="ViewGrid"
        style="width: 100%; height: 540px"
        class="ag-theme-balham"
        [masterDetail]="true"
        [gridOptions]="gridOptions"
        [rowData]="rowData"
        pagination="true"
        (gridReady)="onGridReady($event)"
        (filterChanged)="onFilterChanged($event)"
      >
      </ag-grid-angular>
    </div>
  </div>
