/// <reference path="../../../../../node_modules/monaco-editor/monaco.d.ts" />
import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../../../shared/base.component";
import { ApiService } from "src/app/services";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import { Message } from "../../../generated-models/Message";
import { IUserProfile } from "../../../npr-request.model";
import { CapacitySubOrderDetails } from "../../../generated-models/CapacitySubOrderDetails";

@Component({
  templateUrl: "./capacity-suborder-detail.component.html",
  styleUrls: ["../../../styles.scss", "../plan.scss"],
})
export class CapacitySubOrderDetailComponent extends BaseComponent implements OnInit {
  orderId: string;
  subOrderId: string;
  subOrderDetail: CapacitySubOrderDetails;
  newMessage: string;
  messages: Message[];
  placeHolder: string;
  userProfile: IUserProfile = null;

  constructor(
    private readonly route: ActivatedRoute,
    private apiService: ApiService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService
  ) {
    super();
  }

  async ngOnInit() {
    this.loadingService.setLoading(true);
    this.userProfile = await this.apiService.getUserProfile();
    this.orderId = this.route.snapshot.params["capacityOrderId"] ?? "TestOrderId";
    this.subOrderId = this.route.snapshot.params["subOrderId"] ?? "TestSubOrderId";
    this.apiService.getCapacitySubOrderDetails(this.orderId, this.subOrderId).subscribe(
      (response) => {
        if (response) {
          this.subOrderDetail = response;
          // test data
          if (!this.subOrderDetail.Messages) {
            this.messages = [
              {
                Content: "Hello, this is a test message!",
                CreatedBy: "User1",
                CreatedTime: "2023-04-01 12:00:00"
              },
              {
                Content: "Here's another message for testing purposes.",
                CreatedBy: "User2",
                CreatedTime: "2023-04-01 13:00:00 "
              },
              {
                Content: "And here is a third test message.",
                CreatedBy: "User3",
                CreatedTime: "2023-04-01 14:00:00"
              }
            ];
          }
          else {
            this.messages = this.subOrderDetail.Messages;
          }

          this.placeHolder = this.messages.length > 0 ? "Add a comment here." : "If you have any questions, you can add a comment here.";
        }
        else {
          this.notificationService.error(`The subOrder(${this.orderId}-${this.subOrderId}) does not exist.`);
        }
      },
      (error) => {
        const message = `Failed to get capacity subOrder details for order ${this.orderId}-${this.subOrderId}, error: ${error}.`;
        this.notificationService.error(message);
      }
    );
    this.loadingService.setLoading(false);
  }

  isValidMessage() {
    if (this.newMessage) {
      return this.newMessage.trim().length != 0;
    }
    // undefined return false
    return false;
  }

  saveNewMessage(): void {
    const message = {} as Message;
    message.Content = this.newMessage;
    message.CreatedBy = this.userProfile?.Name;
    message.CreatedTime = new Date().toISOString();
    this.apiService.addCapacityOrderMessage(this.orderId, this.subOrderId, message).subscribe(
      () => {
        this.messages.push(message);
        this.newMessage = "";
      },
      (error: unknown) => {
        const message = `Failed to update the new message for order ${this.orderId}-${this.subOrderId}, error: ${error}.`;
        this.notificationService.error(message);
      }
    );
  }
}
