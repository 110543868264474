import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Region } from "src/app/generated-models";
import { ApiService } from "src/app/services";
import { RegionStatusEnum } from "../../../../shared/enums/plan-enums";
import { allowedFfpRegionStatuses } from "../../../../npr-request.model";

@Component({
  templateUrl: "./ffp-region-buildout-modal.component.html",
  styleUrls: ["../../ffp.scss"],
})
export class FfpRegionBuildoutModalComponent implements OnInit {
  regions: Region[] = [];
  selectedRegion: Region;
  regionEnum = RegionStatusEnum;

  constructor(public activeModal: NgbActiveModal, private apiService: ApiService, private notificationService: ToastrService) {}

  async ngOnInit() {
    // Region List
    // includeDisabled = false, includeAirGapped = false
    const regionListResponse = await this.apiService.getRegionList(false, false).toPromise();

    if (!regionListResponse) {
      console.warn("No region obtained from server.");
    } else {
      this.regions = regionListResponse
        .filter((region) => allowedFfpRegionStatuses.includes(region.Status))
        .sort((region1, region2) => {
          return this.regionEnum[region1.Status.replace(/\s/g, "")] - this.regionEnum[region2.Status.replace(/\s/g, "")];
        })
        .filter((r) => r.ArmRegionName && !r.RegionName.endsWith("STG") && !r.RegionName.endsWith("EUAP") && !r.RegionName.endsWith("SLV"));
    }
  }

  async submit() {
    if (!this.selectedRegion) {
      this.notificationService.warning(`Please select a region.`);
      return;
    } else {
      try {
        this.activeModal.close({
          selectedRegion: this.selectedRegion.RegionName,
        });
      } catch (e) {
        this.notificationService.error(e);
      }
    }
  }
}
