import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { TableName } from "src/app/npr-request.model";
import { ApiService } from "src/app/services";
import { ModalService } from "src/app/shared/modal.service";
import { TeamConfig } from "src/app/generated-models/TeamConfig";
import { SharedDataService } from "../../services/sharedDataService";
import { DataTypes } from "./admin-view-data-types";
import { AdminViewComponent } from "./admin-view.component";

@Component({
  selector: "admin-view/team",
  templateUrl: "./admin-view.component.html",
  styleUrls: ["../../styles.scss"],
})
export class AdminViewTeamConfigComponent extends AdminViewComponent<TeamConfig> implements OnInit {
  type = "TeamConfig";
  tableName = TableName.TeamConfig;
  columnDef = [
    {
      headerName: "Service Team Name",
      field: "Oid",
      valueType: "Teams",
      cellRenderer: (params) => {
        return `${this.dataTypes.teamNameOidMap[params.data.Oid]}(${params.data.Oid})`;
      },
    },
    {
      headerName: "Region Name",
      field: "RegionName",
      default: "*",
      valueType: "region",
    },
    {
      headerName: "Enabled in UI",
      field: "EnabledInUI",
      isEditable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["true", "false"],
      },
      valueType: "boolean",
      default: true,
    },
    {
      headerName: "AllowHoboCapacityOrders",
      field: "AllowHoboCapacityOrders",
      isEditable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["true", "false"],
      },
      valueType: "boolean",
      default: false,
    },
    {
      headerName: "AutoApproveRegionalPlansForBuildout",
      field: "AutoApproveRegionalPlansForBuildout",
      isEditable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["true", "false"],
      },
      valueType: "boolean",
      default: false,
    },
  ];

  keyFilterStats = "filter:admin-view-team-region-config";

  constructor(
    apiService: ApiService,
    modalService: ModalService,
    notificationService: ToastrService,
    sharedDataService: SharedDataService,
    dataTypes: DataTypes
  ) {
    super(apiService, modalService, notificationService, sharedDataService, dataTypes);
    this.addRowEnable = true;
    this.addRowFunc = (data) => apiService.setTeamConfig(data as TeamConfig).toPromise();
    this.getDefaultValueFunc = () => apiService.getDefaultTeamConfig().toPromise();
    this.updateRowFunc = (data) => apiService.setTeamConfig(data as TeamConfig).toPromise();
    this.deleteRowEnable = true;
    this.deleteRowFunc = (data: TeamConfig) => apiService.deleteTeamConfig(data.Oid, data.RegionName).toPromise();
  }

  refreshData(): void {
    this.apiService.getTeamConfig().subscribe((response) => {
      this.rowData = response;
      super.setupFilterByLocalData();
    });
  }
}
