<div class="title container container-max-width btn-toolbar justify-content-between">
  <h4>Sub Request Details</h4>
  <div class="btn-group">
    <button type="submit" class="btn btn-primary mr-1" [disabled]="IsUpdateButtonDisabled()" (click)="SubmitChanges()">Update</button>
    <button *ngIf="canBeApproved" type="submit" class="btn btn-primary mr-1" data-test="ApproveButton" (click)="ApproveRequest()">
      Approve
    </button>
    <button *ngIf="isAnyTicketIncomplete" type="submit" class="btn btn-danger mr-1" (click)="showTicketComment()">
      Respond to Unblock
    </button>
    <button *ngIf="isAdmin" type="submit" class="btn btn-primary mr-1" (click)="retry()" [disabled]="!IsRetryable()">Retry</button>
    <div [ngbTooltip]="cancelTooltip">
      <button *ngIf="isAdmin || isSubmitter" [disabled]="disableCancel" type="submit" class="btn btn-danger mr-1"
        (click)="cancelSubRequest()">Cancel</button>
    </div>
    <button *ngIf="canSkipDenpendencies" type="submit" class="btn btn-primary mr-1" (click)="skipDependencies()">Skip Dependencies</button>
  </div>
</div>
<div class="container container-content container-max-width">
  <ng-container *ngIf="!(isNotFound || isForbidden || isError || isLoading)">
    <form novalidate class="form-horizontal mb-5" role="form">
      <fieldset class="fieldsetstyle">
        <div class="d-flex justify-content-between">
          <h5 class="font-weight-bold">
            {{ subReq.ParentRequestId }}-{{ subReq.SubRequestId }} [<label [ngClass]="subReq.Status" data-test="SubRequestStatus">{{
              requestStatusName(subReq.Status)
              }}</label
            >]
            <i *ngIf="requestStatusDescription(subReq.Status)" class="fa fa-info-circle" placement="right" [ngbPopover]="Popover"> </i>
            <ng-template #Popover>{{ requestStatusDescription(subReq.Status) }}</ng-template>
          </h5>
          <a [href]="parentRequestUrl">View Parent</a>
        </div>
        <hr class="mt-0" />

        <table>
          <tbody>
            <tr>
              <td class="col-form-label col-sm-3">Subscription Id</td>
              <td class="col-sm-6 form-control-plaintext">
                <a href="javascript:void(0)" class="topbar-item" (click)="getSubscriptionDetails(subReq.SubscriptionId)" container="body">
                  {{ subReq.SubscriptionId }}
                </a>
                <span *ngIf="subReq.IsExternal" class="ml-2">| External</span>
                <span *ngIf="subReq.IsHobo" class="ml-2">| Hobo</span>
              </td>
            </tr>
            <tr>
              <td class="col-form-label col-sm-3">Region Name</td>
              <td class="col-sm-6 form-control-plaintext">
                <span>{{ subReq.Region }}</span>
                <span class="ml-2">| {{ subReq.RegionStatus }}</span>
              </td>
            </tr>
            <tr>
              <td class="col-form-label col-sm-3">Requestor</td>
              <td class="col-sm-6 form-control-plaintext">
                <span>{{ subReq.Requestor }}</span>
                <span *ngIf="subReq.RingLevel" class="ml-2">| {{ subReq.RingLevel }}</span>
              </td>
            </tr>
            <tr>
              <td class="col-form-label col-sm-3">Submitter</td>
              <td class="col-sm-6 form-control-plaintext">{{ subReq.Submitter }}</td>
            </tr>
            <tr *ngIf="subReq.TeamOid">
              <td class="col-form-label col-sm-3">Team Oid</td>
              <td class="col-sm-6 form-control-plaintext">{{ subReq.TeamOid }}</td>
            </tr>
            <tr>
              <td class="col-form-label col-sm-3">Request Detail</td>
              <td class="col-sm-6 form-control-plaintext">
                <span>{{ subReq.RequestDisplayServiceType }}</span>
                <span *ngIf="subReq.SKU" class="ml-2">| {{ subReq.SKU }}</span>
                <span class="ml-2" *ngIf="haveQuota && subReq.RequestServiceType !== 'AppService'">| {{ subReq.Quota }}</span>
                <div *ngIf="subReq.RequestServiceType === 'AppService'" id="appserviceQuotaInfo">
                  <span *ngIf="haveQuota">Windows</span>
                  <span class="ml-2" *ngIf="haveQuota">| {{ subReq.Quota }}</span>
                  <span *ngIf="hasAppServiceLinuxQuota">Linux</span>
                  <span class="ml-2" *ngIf="hasAppServiceLinuxQuota">| {{ subReq.ServiceParams['LinuxVmQuota'] }}</span>
                </div>
              </td>
            </tr>
            <tr *ngIf="subReq.DeploymentScope && subReq.DeploymentScope !== deploymentScope.Empty">
              <td class="col-form-label col-sm-3">Deployment Scope</td>
              <td class="col-sm-6 form-control-plaintext">{{ subReq.DeploymentScope }}</td>
            </tr>
            <tr>
              <td class="col-form-label col-sm-3">Source</td>
              <td class="col-sm-6 form-control-plaintext">{{ subReq.RequestSource }}</td>
            </tr>
            <tr>
              <td class="col-form-label col-sm-3">Fulfill Channel</td>
              <td class="col-sm-6 form-control-plaintext">{{ subReq.FulfillChannel }}</td>
            </tr>
            <tr>
              <td class="col-form-label col-sm-3">Created Time</td>
              <td class="col-sm-6 form-control-plaintext">{{ subReq.CreatedTime }}</td>
            </tr>
            <tr *ngIf="subReq.CompletedTime">
              <td class="col-form-label col-sm-3">Completed Time</td>
              <td class="col-sm-6 form-control-plaintext">{{ subReq.CompletedTime }}</td>
            </tr>
            <tr *ngIf="subReq.Dependencies">
              <td class="col-form-label col-sm-3">Dependencies</td>
              <td class="col-sm-6 form-control-plaintext">
                <span [innerHtml]="GetDependencyHtmls(subReq.Dependencies)"></span>
                <span *ngIf="subReq.IgnoreDependencies" style="font-style: italic"> (Ignored) </span>
              </td>
            </tr>
            <tr>
              <td class="col-form-label col-sm-3">Priority</td>
              <td class="col-sm-6 form-control-plaintext">
                <select name="selectedPriority"
                        title="selectedPriority"
                        [disabled]="!canUpdatePriority"
                        [(ngModel)]="selectedPriority"
                        (change)="UpdatePriority($event.target.value)">
                  <option *ngFor="let p of priorities" [value]="p">
                    {{ GetPriorityString(p) }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="col-form-label col-sm-3">Utilize Date</td>
              <td class="col-sm-6 form-control-plaintext">
                  <em-datepicker class="col-md-5 pl-0" [disabled]="!canUpdateUtilizeDate" [(ngModel)]="subReq.UtilizeDate" name="UtilizeDate">
                  </em-datepicker>
              </td>
            </tr>
            <tr *ngIf="subReq.Notes">
              <td class="col-form-label col-sm-3">Notes</td>
              <td class="col-sm-6 form-control-plaintext">{{ subReq.Notes }}</td>
            </tr>
            <tr *ngIf="subReq.IsFlighting">
              <td class="col-form-label col-sm-3">Is Flighting</td>
              <td class="col-sm-6 form-control-plaintext">{{ subReq.IsFlighting }}</td>
            </tr>
            <tr *ngIf="subReq.FulfillChannel === 'RDQuota'">
              <td class="col-form-label col-sm-3">Support Contact</td>
              <td class="col-sm-6 form-control-plaintext">This request is fulfilled via RDQuota ticket. Please use the links in the <b>Ticket</b> section below to interact with capacity managers if necessary.</td>
            </tr>
          </tbody>
        </table>
      </fieldset>

      <fieldset class="fieldsetstyle" *ngIf="approvals?.length > 0 || icmNotifications?.length > 0">
        <h5>Approval Status</h5>
        <hr />
        <table>
          <ng-container *ngFor="let approval of approvals">
            <tr *ngIf="approval">
              <td class="col-form-label col-sm-3">{{ approval.Type }} {{ approval.Type === "Reject" ? "" : "Approval" }}</td>
              <td class="col-sm-6 form-control-plaintext">
                <ng-container *ngIf="!approval.By; else ApprovedTemplate"> (Pending)
                  <br />
                  <div *ngIf="contacts[approval.Type]; else prevApprovalTemplate">
                    Contact {{ contacts[approval.Type] }} if pending for more than 1 business day
                  </div>
                  <ng-template #prevApprovalTemplate>
                    <span *ngIf="previousApprovals.has(approval.Type)"> Same type of requests were previously approved by
                      <span *ngFor="let prevApproval of previousApprovals.get(approval.Type).slice(0, 5); let prevIndex=index">
                        <span class="no-left-space" *ngIf="prevIndex !== previousApprovals.get(approval.Type).length - 1; else lastApprovalTemplate">{{prevApproval.By}},&nbsp;</span>
                        <ng-template #lastApprovalTemplate>{{ prevApproval.By }}</ng-template>
                      </span>
                    </span>
                  </ng-template>
                </ng-container>
                <ng-template #ApprovedTemplate>by {{ approval.By }} at {{ formatDateTime(approval.At) }} </ng-template>
                <ng-container *ngIf="!approval.Comments"> {{ approval.Comments }} </ng-container>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngFor="let icmNotification of this.icmNotifications">
            <tr>
              <td class="col-form-label col-sm-3">Notified Approver</td>
              <td class="col-sm-6 form-control-plaintext">
                <a *ngIf="icmNotification.Url && icmNotification.CreatedTime" href="{{ icmNotification.Url }}" target="_blank">{{ icmNotification.IcmId }} </a>
                Created at {{ formatDateTime(icmNotification.CreatedTime) }}
              </td>
            </tr>
          </ng-container>
        </table>
      </fieldset>

      <fieldset class="fieldsetstyle" *ngIf="this.tickets || this.cisJob">
        <h5>Fulfillment</h5>
        <hr />
        <table *ngIf="this.cisJob">
          <tr *ngIf="!isCisTask">
            <td class="col-form-label col-sm-3">CIS Job</td>
            <td class="col-sm-6 form-control-plaintext">
              <a href="{{ cisJob.JobLink }}" target="_blank">{{ cisJob.JobId }}</a>
            </td>
          </tr>
          <tr *ngIf="isCisTask">
            <td class="col-form-label col-sm-3">CIS Task</td>
            <td class="col-sm-6 form-control-plaintext">
              <a href="{{ cisJob.JobLink }}" target="_blank">{{ cisJob.JobId }};{{ cisJob.TaskId }}</a>
            </td>
          </tr>
          <tr *ngIf="cisJob.LastUpdatedTime">
            <td class="col-form-label col-sm-3"></td>
            <td class="col-sm-6 form-control-plaintext">Last updated at {{ formatDateTime(cisJob.LastUpdatedTime) }}</td>
          </tr>
          <tr *ngIf="cisJob.CreatedTime">
            <td class="col-form-label col-sm-3"></td>
            <td class="col-sm-6 form-control-plaintext">Created at {{ formatDateTime(cisJob.CreatedTime) }}</td>
          </tr>
          <tr *ngIf="cisJob.CompletedTime">
            <td class="col-form-label col-sm-3"></td>
            <td class="col-sm-6 form-control-plaintext">Completed at {{ formatDateTime(cisJob.CompletedTime) }}</td>
          </tr>
        </table>
        <table *ngIf="this.tickets">
          <ng-container *ngFor="let ticket of this.tickets">
            <tr>
              <td class="col-form-label col-sm-3">Ticket</td>
              <td class="col-sm-6 form-control-plaintext">
                <a *ngIf="ticket.Url" href="{{ ticket.Url }}" target="_blank">{{ ticket.TicketId }}</a>
                <span *ngIf="!ticket.Url">{{ ticket.TicketId }}</span>
                <span [ngClass]="{Ticket: ticket.Channel !== icmTicketChannel, 'ml-2': true}" (click)="onShowTicketCommentClicked(ticket)">
                  [<span *ngIf="IsTicketIncompleted(ticket)" class="text-danger">!</span>{{ ticket.State }}]
                </span>
              </td>
            </tr>
            <tr *ngIf="ticket.CreatedTime">
              <td class="col-form-label col-sm-3"></td>
              <td class="col-sm-6 form-control-plaintext">Created at {{ formatDateTime(ticket.CreatedTime) }}</td>
            </tr>
            <tr *ngIf="ticket.FinishedTime">
              <td class="col-form-label col-sm-3"></td>
              <td class="col-sm-6 form-control-plaintext">Completed at {{ formatDateTime(ticket.FinishedTime) }}</td>
            </tr>
          </ng-container>
        </table>
      </fieldset>

      <fieldset class="fieldsetstyle" *ngIf="rowData && rowData.length > 0">
        <h5>History</h5>
        <hr />
        <ag-grid-angular
          class="ag-theme-balham"
          [gridOptions]="gridOptions"
          [rowData]="rowData"
          [masterDetail]="true"
          pagination="true"
          rowSelection="multiple"
          (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </fieldset>

      <fieldset class="fieldsetstyle">
        <h5>Discussion</h5>
        <hr />
        <div class="col-md-10 form-inline" style="display:flex;align-items:start;">
          <textarea style="vertical-align:top;" class="form-control col-md-10" name="inputDiscussion" required [(ngModel)]="newDiscussion" placeholder="Add a comment here.">
          </textarea> &nbsp;
          <button style="vertical-align:top;" type="submit" class="btn btn-primary form-control col-md-1" [disabled]="!HasNewDiscussion()" (click)="saveNewDiscussion()">Save</button>
        </div>
        <div *ngFor="let discussion of discussions" class="pt-3">
          <b>{{ discussion.Submitter }}</b> &nbsp; {{ formatDateTime(discussion.SubmitTime) }}
          <div [innerHTML]="discussion.Content"></div>
        </div>
      </fieldset>
    </form>
  </ng-container>
  <h5 class="font-weight-bold" *ngIf="isNotFound">Not Found</h5>
  <h5 class="font-weight-bold" *ngIf="isForbidden">Forbidden</h5>
  <h5 class="font-weight-bold" *ngIf="isError">Error: {{ errorMessage }}</h5>
</div>
