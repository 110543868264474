import { AfterViewInit, Component, OnInit } from "@angular/core";
import { BaseComponent } from "../../shared/base.component";
import { ApiService } from "src/app/services";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";
import { ModalService } from "src/app/shared/modal.service";
import { ColumnApi, GridOptions } from "ag-grid-community";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import { FfpPlanRegionStatusEnum } from "src/app/shared/enums/plan-enums";
import { IUserProfile } from "src/app/npr-request.model";
import { FfpPlanRegionApproval } from "../../generated-models/FfpPlanRegionApproval";
import { FfpPlanRegion } from "../../generated-models/FfpPlanRegion";
import { PlanRegionApprovalsColumnDefinition } from "../plans/ag-grid-column-definition";
import { FfpPlanRegionOverallApproval } from "../../generated-models/FfpPlanRegionOverallApproval";
import { FfpPlanRejectData } from "../../generated-models/FfpPlanRejectData";
import { FfpPlanFile } from "../../generated-models/FfpPlanFile";

@Component({
  templateUrl: "./ffp-plan-review.component.html",
  styleUrls: ["../../styles.scss", "./ffp.scss"],
})
export class FfpPlanReviewComponent extends BaseComponent implements AfterViewInit, OnInit {
  gridOptions: GridOptions;
  gridColumnApi: ColumnApi;
  editor: monaco.editor.IStandaloneDiffEditor;
  ffpPlanRegionApprovalsGridOptions: GridOptions;
  ffpPlanRegionApprovalsColumnApi: ColumnApi;
  ffpPlanRegionApprovalsRowData: FfpPlanRegionApproval[] = [];

  serviceTreeId: string;
  region: string;
  version: number;
  ffpPlanRegion: FfpPlanRegion;
  latestVersion: number = null;
  ffpPlanLatestVersionReviewLink: string;
  message: string;
  canApprove = false;
  canReject = false;
  showRejectComment = false;
  userProfile: IUserProfile;

  currentFfpPlanFile: FfpPlanFile;
  lastApprovedFfpPlanFile: FfpPlanFile;

  constructor(
    private readonly route: ActivatedRoute,
    private apiService: ApiService,
    protected modalService: ModalService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService,
    private title: Title
  ) {
    super();
  }

  async ngOnInit() {
    this.ffpPlanRegionApprovalsGridOptions = extendDefaultOptions({
      columnDefs: PlanRegionApprovalsColumnDefinition,
      enableRangeSelection: false,
      rowSelection: "single",
      animateRows: true,
      suppressPaginationPanel: true,
      sideBar: null,
    });

    this.serviceTreeId = this.route.snapshot.params["serviceTreeId"];
    this.region = this.route.snapshot.params["region"];
    this.version = this.route.snapshot.params["version"];
    this.title.setTitle(`${this.route.snapshot.data.title} for ${this.serviceTreeId} on ${this.region} v${this.version}`);

    this.loadingService.setLoading(true);
    this.userProfile = await this.apiService.getUserProfile();
    await this.loadFfpPlan();
    await this.loadFfpPlanRegionApprovals();
    this.loadingService.setLoading(false);

    setTimeout(() => {
      this.gridColumnApi.autoSizeAllColumns();
      this.ffpPlanRegionApprovalsColumnApi.autoSizeAllColumns();
    }, 100);
  }

  onGridReady(params: GridOptions) {
    this.gridColumnApi = params.columnApi;
  }

  onPlanRegionApprovalsGridReady(params: GridOptions) {
    this.ffpPlanRegionApprovalsColumnApi = params.columnApi;
  }

  async loadFfpPlan() {
    await this.apiService
      .getFfpPlanRegion(this.serviceTreeId, this.region, this.version)
      .toPromise()
      .then((response) => {
        this.ffpPlanRegion = response;
        if (this.ffpPlanRegion.Status === FfpPlanRegionStatusEnum.Approvable) {
          this.canApprove = this.userProfile?.IsApprover;
          this.canReject = true;
        }
      })
      .catch((e) => {
        this.notificationService.error(e);
      });

    const latestVersionFfpPlanRegion = await this.apiService
      .getFfpPlanRegionLatestVersion(this.serviceTreeId, this.region)
      .toPromise();
    this.latestVersion = latestVersionFfpPlanRegion.Version;
    if (this.latestVersion != null) {
      this.ffpPlanLatestVersionReviewLink = `/quota/ffp/services/${this.serviceTreeId}/regions/${this.region}/versions/${this.latestVersion}/review`
    }
  }

  async loadFfpPlanRegionApprovals() {
    return await this.apiService
      .getFfpRegionalPlanApprovals(this.serviceTreeId, this.region, this.version)
      .toPromise()
      .then((response) => {
        this.ffpPlanRegionApprovalsRowData = response;
      })
      .catch((e) => {
        this.notificationService.error(e);
      });
  }

  async showApproveFfpPlanDialog() {
    this.approveFfpPlan();
  }

  approveFfpPlan() {
    const ffpPlanRegionOverallApproval: FfpPlanRegionOverallApproval = {
      ApprovedVersion: this.ffpPlanRegion.Version,
    } as FfpPlanRegionOverallApproval;

    this.loadingService.setLoading(true);

    this.apiService.approveFfpPlanRegion(this.ffpPlanRegion.ServiceTreeId, this.ffpPlanRegion.Region, ffpPlanRegionOverallApproval).subscribe(
      (response) => {
        if (response) {
          this.message = response.Message;
        } else {
          this.message = `Ffp Plan have been approved successfully.`;
        }

        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);
      },
      (err) => {
        this.message = `Failed to approve Ffp plan. Server response: ${err}`;
        this.notificationService.error(this.message);
        this.loadingService.setLoading(false);
      },
      async () => {
        this.loadingService.setLoading(true);
        await this.loadFfpPlan();
        await this.loadFfpPlanRegionApprovals();
        this.loadingService.setLoading(false);
      }
    );
  }

  async showRejectFfpPlanDialog() {
    await this.rejectFfpPlan();
  }

  rejectFfpPlan() {
    const ffpPlanRejectData: FfpPlanRejectData = {
      Version: this.ffpPlanRegion.Version,
    } as FfpPlanRejectData;

    this.loadingService.setLoading(true);

    this.apiService.rejectFfpPlanRegion(this.ffpPlanRegion.ServiceTreeId, this.ffpPlanRegion.Region, ffpPlanRejectData).subscribe(
      () => {
        this.message = `Ffp Plan have been rejected.`;
        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);
      },
      (err) => {
        this.message = `Failed to reject ffp plan. Server response: ${err}`;
        this.notificationService.error(this.message);
        this.loadingService.setLoading(false);
      },
      async () => {
        this.loadingService.setLoading(true);
        await this.loadFfpPlan();
        await this.loadFfpPlanRegionApprovals();
        this.loadingService.setLoading(false);
      }
    );
  }

  showStatus(ffpPlanRegion: FfpPlanRegion) {
    return ffpPlanRegion.Status;
  }

  showSubmit(ffpPlanRegion: FfpPlanRegion) {
    if (ffpPlanRegion.Submitter && ffpPlanRegion.SubmitTime) {
      return `by ${ffpPlanRegion.Submitter} at ${PreprocessDateTime(ffpPlanRegion.SubmitTime)}`;
    } else {
      return `N/A`;
    }
  }

  async onEditorReady(editor: monaco.editor.IStandaloneDiffEditor) {
    await this.getFfpPlanFiles();
    this.editor = editor;
    var originalModel = monaco.editor.createModel(this.lastApprovedFfpPlanFile?.Content, "application/json");
    var modifiedModel = monaco.editor.createModel(this.currentFfpPlanFile?.Content, "application/json");

    this.editor.setModel({
      original: originalModel,
      modified: modifiedModel,
    });

    // delay 1sec for formatting the content
    setTimeout(() => {
      this.editor
        .getModifiedEditor()
        .getAction("editor.action.formatDocument")
        .run()
        .then(() => {
          this.editor.updateOptions({ readOnly: true });
        });
    }, 1000);
  }

  async getFfpPlanFiles() {
    this.loadingService.setLoading(true);

    await this.apiService.getFfpPlanFiles(this.serviceTreeId, this.version)
      .toPromise()
      .then((response) => {
        response.forEach((plan) => {
          if (plan.FileName === "default.json") {
            this.currentFfpPlanFile = plan;
          }
        });
      })
      .catch((e: unknown) => {
        this.notificationService.error(e as string);
      });

    await this.apiService.getLatestApprovedFfpPlanFiles(this.serviceTreeId, this.region)
      .toPromise()
      .then((response) => {
        if (response != null) {
          response.forEach((plan) => {
            if (plan.FileName === "default.json") {
              this.lastApprovedFfpPlanFile = plan;
            }
          });
        }
        this.loadingService.setLoading(false);
      })
      .catch((e: unknown) => {
        this.loadingService.setLoading(false);
        this.notificationService.error(e as string);
      });
  }
}
