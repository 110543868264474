import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/services";
import { FfpPlanRegion } from "../../../../generated-models/FfpPlanRegion";
import { FfpPlanRegionOverallApproval } from "../../../../generated-models/FfpPlanRegionOverallApproval";

@Component({
  templateUrl: "./ffp-plan-batch-approval.component.html",
  styleUrls: ["../../../../styles.scss"],
})
export class FfpPlanBatchApprovalComponent implements OnInit {
  @Input() title: string;
  @Input() ffpPlanRegions: FfpPlanRegion[];

  public aggregatedResult: string[] = [];
  public isCompleted = false;

  constructor(public activeModal: NgbActiveModal, protected apiService: ApiService) { }
  async ngOnInit(): Promise<void> {
    await this.batchApprovalFfpPlanRegions();
  }

  close(): void {
    this.activeModal.close(true);
  }

  async batchApprovalFfpPlanRegions() {
    await Promise.all(
      this.ffpPlanRegions.map(async (planRegion) => {
        try {
          const ffpPlanRegionOverallApproval: FfpPlanRegionOverallApproval = {
            ApprovedVersion: planRegion.Version,
          } as FfpPlanRegionOverallApproval;

          await this.apiService.approveFfpPlanRegion(planRegion.ServiceTreeId, planRegion.Region, ffpPlanRegionOverallApproval).toPromise();
          this.aggregatedResult.push(`Approve plan region <b>${planRegion.ServiceTreeId}</b> successfully`);
        } catch (ex) {
          this.aggregatedResult.push(`Approve plan region <b>${planRegion.ServiceTreeId}</b> failed with error: ${ex}`);
        }
      })
    );

    this.isCompleted = true;
  }
}
