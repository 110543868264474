import { IUserProfile, LionrockUserRoles, Roles } from "../npr-request.model";

export function IsServiceAdmin(userProfile: IUserProfile, serviceTreeId = ""): boolean {
  if (
    userProfile && (
      userProfile.IsAdmin ||
      userProfile.UserRoles.some(
        (_) =>
          _.Role == LionrockUserRoles.RolePrefix + LionrockUserRoles.Administrator &&
          (serviceTreeId !== "" ? _.Services.includes(serviceTreeId) : _.Services.length > 0)
      ))
  ) {
    return true;
  }
  return false;
}

export function IsServiceUser(userProfile: IUserProfile, serviceTreeId = ""): boolean {
  if (
    userProfile && (
      userProfile.IsAdmin ||
      userProfile.UserRoles.some(
        (_) =>
          (_.Role == LionrockUserRoles.RolePrefix + LionrockUserRoles.Administrator ||
            _.Role == LionrockUserRoles.RolePrefix + LionrockUserRoles.User) &&
          (serviceTreeId !== "" ? _.Services.includes(serviceTreeId) : _.Services.length > 0)
      ))
  ) {
    return true;
  }
  return false;
}

export function IsFfpAdmin(userProfile: IUserProfile): boolean {
  if (
    userProfile && (
      userProfile.IsAdmin ||
      userProfile.UserRoles?.some((role) => role.Role == Roles.FfpAdmin)
    )
  ) {
    return true;
  }
  return false;
}

export function IsPfAdmin(userProfile: IUserProfile): boolean {
  if (
    userProfile && (
      userProfile.IsAdmin ||
      userProfile.UserRoles?.some((role) => role.Role == Roles.PfAdmin)
    )
  ) {
    return true;
  }
  return false;
}
