export function JsonCellRenderer(params) {
  if (params.value) {
    const strRep = JSON.stringify(params.value);
    if (strRep !== "{}") {
      return strRep;
    }
  }
}

export function QuotaCellRenderer(params) {
  return (params.value > 0 || params.value.length) > 0 ? params.value : "";
}
