<div class="title" #title>
  <div class="container container-max-width">
    <h4>{{ this.mainTitle || this.tableName + " Data Management" }}</h4>
    <p>{{ this.subTitle || "Use this page to update " + this.tableName + " info" }}</p>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main">
    <br />
    <div class="hint">
      <label>{{ this.rowData.length }} row(s)</label>
      <a
        href="javascript:void(0)"
        class="topbar-item"
        (click)="exportExcel()"
        container="body"
        ngbTooltip="Export to Excel"
        aria-label="Export to Excel"
      >
        <span class="far fa-file-excel fa-lg"></span>
      </a>
      <div class="nofloat">
        <p *ngIf="message" class="message">{{ this.message }}</p>
        <a href="javascript:void(0)" class="topbar-item" (click)="refreshData()" container="body"> Refresh </a>
        &nbsp;&nbsp;
        <a *ngIf="addRowEnable" href="javascript:void(0)" class="topbar-item" (click)="addRow()" container="body"> Insert Row </a>
        &nbsp;&nbsp;
        <a *ngIf="selectedRowsCount === 1" href="javascript:void(0)" class="topbar-item" (click)="editRow()" container="body"> Edit Row </a>
        &nbsp;&nbsp;
        <a
          *ngIf="selectedRowsCount === 1 && deleteRowEnable"
          href="javascript:void(0)"
          class="topbar-item"
          (click)="deleteRow()"
          container="body"
        >
          Delete Row
        </a>
      </div>
    </div>

    <grid-filter-pills [gridApi]="gridApi" *ngIf="gridApi"></grid-filter-pills>
    <form #operatorForm="ngForm" autocomplete="off" novalidate>
      <div class="nofloat">
        <ag-grid-angular
          style="height: 540px"
          class="ag-theme-balham"
          [gridOptions]="gridOptions"
          [rowData]="rowData"
          [excelStyles]="headerStyle"
          [masterDetail]="true"
          [detailRowHeight]="145"
          pagination="true"
          (gridReady)="onGridReady($event)"
          (selectionChanged)="onSelectionChanged($event)"
          (dblclick)="editRow()"
          (filterChanged)="onFilterChanged($event)"
        >
        </ag-grid-angular>
      </div>
    </form>
    <div>
      &nbsp;&nbsp;
      <p *ngIf="additionalMessage">
        {{ this.additionalMessage }}
      </p>
      <p *ngIf="selectedRowsCount === 0">* Double click or select a single row to start editing. Select a single row to enable delete.</p>
    </div>
  </div>
</div>
