import * as CDs from "src/app/utility/commonColDef";

export const SubscriptionInfoColumnDefinition = [
  {
    headerName: "Request Info",
    children: [
      CDs.SubscriptionId("SubscriptionId", { filter: "agSetColumnFilter" }),
      CDs.RegionColDef("RegionName", { filter: "agSetColumnFilter" }),
      {
        headerName: "AZ AFEC",
        field: "AZAFEC",
        resizable: true,
        sortable: true,
        filter: "agSetColumnFilter",
      },
    ],
  },
  {
    headerName: "Subscription Info",
    children: [
      {
        headerName: "Is AZ Registered",
        field: "IsAZRegistered",
        resizable: true,
        sortable: true,
        filter: "agSetColumnFilter",
      },
      {
        width: 300,
        headerName: "Zone Mapping",
        field: "ZoneMapping",
        sortable: true,
        cellRenderer: (param) => {
          let res = "";
          param.data.ZoneMapping.forEach((element: string) => {
            res += element + "<br/>";
          });
          return res;
        },
      },
      {
        headerName: "ARM Region Access",
        field: "HasRegionAccess",
        resizable: true,
        sortable: true,
        filter: "agSetColumnFilter",
      },
      {
        headerName: "Offer Id",
        field: "OfferId",
        resizable: true,
        sortable: true,
        filter: "agSetColumnFilter",
      },
      {
        headerName: "Offer Type",
        field: "OfferType",
        resizable: true,
        sortable: true,
        filter: "agSetColumnFilter",
      },
      {
        headerName: "Offer Name",
        field: "OfferName",
        resizable: true,
        sortable: true,
        filter: "agSetColumnFilter",
      },
      {
        headerName: "Quota Details",
        field: "QuotaDetails",
        resizable: true,
        sortable: true,
        filter: "agSetColumnFilter",
        tooltipComponentParams: { type: "quotaDetail" },
        tooltipField: "QuotaDetails",
      },
    ],
  },
];
