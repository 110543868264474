<div class="title" #title>
  <div class="container container-max-width">
    <div>
      <h4>Assign FFP Plan to Region</h4>
      <p>Assign services to one region or assign a psl to one region</p>
    </div>
    <div class="action-container">
        <div class="form-check form-check-inline ml-4 toggle">
            <input type="checkbox"
                   class="form-check-input"
                   id="hideAlreadyAssignedSwitch"
                   [(ngModel)]="hideAlreadyAssigned"
                   (change)="onHideAlreadyAssignedChanged()" />
            <label class="form-check-label" for="hideAlreadyAssignedSwitch"> Hide non-assignable</label>
        </div>
        <button type="button"
                class="btn btn-primary"
                [disabled]="!canAssign"
                (click)="showFfpPlanAssignmentDialog()"
                data-test="AssignFfpPlan">
            Assign
        </button>
        &nbsp;
        <button type="button"
                class="btn btn-primary"
                [disabled]="!selectedRegion"
                (click)="showFfpPlanAssignmentWithPSLDialog()"
                data-test="AssignFfpPlan">
            Assign With PSL
        </button>
    </div>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main" *ngIf="assignPlansToOneRegion">
    <div class="top-panel" style="width: 100%">
      <ng-select
        name="Region"
        bindLabel="Region"
        [(ngModel)]="selectedRegion"
        (ngModelChange)="onRegionChanged()"
        placeholder="Select a region"
        [items]="regions"
        groupBy="Status"
        required
        data-test="AssignPlansToOneRegion">
      </ng-select>
    </div>

    <div>
        <ag-grid-angular #agGrid
                         id="ViewGrid"
                         style="width: 100%; height: 540px"
                         class="ag-theme-balham"
                         [masterDetail]="true"
                         [gridOptions]="gridOptionsForPlan"
                         [rowData]="rowDataForPlan"
                         pagination="true"
                         (gridReady)="onGridReady($event)"
                         (selectionChanged)="onSelectionChanged($event)"
                         (filterChanged)="onFilterChangedRegion($event)">
        </ag-grid-angular>
    </div>
  </div>
</div>
