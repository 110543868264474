import { Component, HostListener, OnInit } from "@angular/core";
import { ColDef, ColumnApi, FilterChangedEvent, GridApi, GridOptions, RowNode } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { IUserProfile, PlanListRecord } from "src/app/npr-request.model";
import { RegionStatusEnum } from "src/app/shared/enums/plan-enums";
import { ColParams } from "src/app/shared/grid/ag-grid";
import { ModalService } from "src/app/shared/modal.service";
import * as CDs from "src/app/utility/commonColDef";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";
import { FfpPlanRegion } from "../../generated-models/FfpPlanRegion";
import { ApiService } from "../../services";
import { BaseComponent } from "../../shared/base.component";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { agDateColumnFilter, cacheFilterStatsLocal, extendDefaultOptions, setupFilterByLocalData } from "../../utility/gridHelper";
import { FfpPlanSubmission } from "../../generated-models/FfpPlanSubmission";
import { RegionBuildout } from "../../generated-models/RegionBuildout";

interface RowDataForPlan {
  ServiceTeam: string;
  ServiceTreeId: string;
  Ring: string;
  Version: number;
  CreatedTime: Date;
  CreatedBy: string;
  RegionStatus: string;
  AssignedVersion?: number;
  AlreadyAssigned?: string;
  AssignedBy?: string;
  AssignedTime?: Date | null;
  RegionalPlanStatus?: string;
}

@Component({
  templateUrl: "./ffp-plans-assignment.component.html",
  styleUrls: ["../../styles.scss", "./ffp.scss"],
})
export class FfpPlansAssignmentComponent extends BaseComponent implements OnInit {
  regions: RegionBuildout[] = [];
  selectedRegion: RegionBuildout;
  regionEnum = RegionStatusEnum;
  userProfile: IUserProfile = null;

  rowDataForPlan: RowDataForPlan[] = [];

  gridOptionsForPlan: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize = 15;

  ffpPlans: PlanListRecord[];
  ffpPlanRegions: FfpPlanRegion[] = [];

  hideAlreadyAssigned = false;
  hasRowSelected = false;

  assignPlansToOneRegion = true;
  keyFilterStatusOneRegion = "filter:multiple-to-one";
  keyFilterStatusMultiRegions = "filter:one-to-multiple";
  canAssign = false;


  constructor(
    private apiService: ApiService,
    private modalService: ModalService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService
  ) {
    super();
  }

  async ngOnInit() {
    this.hasRowSelected = false;

    const FfpPlansColumnDefinition: ColDef[] = [
      {
        colId: "customFilter",
        valueGetter: (params: ColParams<RowDataForPlan, void>) => {
          if (this.hideAlreadyAssigned) {
            return this.isAssignable(params.data);
          }

          return true;
        },
        filterParams: {
          values: ["true", "false"],
          newRowsAction: "keep",
        },
        hide: true,
      },
      {
        ...CDs.ServiceTreeId,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
      },
      CDs.ServiceTeam,
      CDs.Ring,
      CDs.LatestVersion,
      CDs.CreatedBy,
      {
        headerName: "Submit Time",
        field: "CreatedTime",
        cellRenderer: (params) => PreprocessDateTime(params.value),
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => agDateColumnFilter(filterLocalDateAtMidnight, cellValue),
        },
      },
      CDs.AlreadyAssigned,
      CDs.AssignedVersion,
      CDs.AssignedBy,
      CDs.AssignedTime,
      CDs.RegionalPlanStatus,
    ];

    this.gridOptionsForPlan = extendDefaultOptions({
      columnDefs: FfpPlansColumnDefinition,
      paginationPageSize: this.initPageSize,
      isRowSelectable: (node: RowNode) => {
        return this.isAssignable(node.data);
      },
      enableRangeSelection: false,
      rowSelection: "multiple",
      animateRows: true,
    });

    this.apiService.getUserProfile().then(async (response) => {
      this.userProfile = response;
      if (response) {
        this.gridApi?.showLoadingOverlay();

        // Region List
        const regionListResponse = await this.apiService.getFfpRegionBuildouts().toPromise();
        if (!regionListResponse) {
          console.warn("No region obtained from server.");
        } else {
          this.regions = regionListResponse;
        }
      }
    });

    this.loadingService.setLoading(true);

    // Plan List
    const ffpPlanListResponse = await this.apiService.getAllFfpPlans().toPromise();
    if (!ffpPlanListResponse) {
      console.warn("No plan obtained from server.");
    } else {
      this.ffpPlans = ffpPlanListResponse
        .filter((p) => p.Version > 0)
        .map((p) => ({ ...p, Label: `${p.ServiceTeam} (${p.ServiceTreeId})` } as PlanListRecord));
    }

    await this.getLatestRegionalPlans();

    this.loadingService.setLoading(false);
  }

  onGridReady(params: GridOptions): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setFilterModel({
      customFilter: ["true"],
    });
  }

  async getLatestRegionalPlans(): Promise<void> {
    return this.apiService
      .getLatestFfpRegionalPlans()
      .toPromise()
      .then(
        (response) => {
          this.ffpPlanRegions = response;
          setupFilterByLocalData(this.gridApi, this.gridColumnApi, this.keyFilterStatusOneRegion);
          setupFilterByLocalData(this.gridApi, this.gridColumnApi, this.keyFilterStatusMultiRegions);
        },
        (e: any) => {
          this.ffpPlanRegions = [];
          this.notificationService.error(e);
        }
      );
  }

  onRegionChanged(): void {
    this.rowDataForPlan = [];
    if (this.selectedRegion) {
      const rowData: RowDataForPlan[] = [];

      this.ffpPlans.forEach((plan) => {
        const row: RowDataForPlan = {
          ServiceTeam: plan.ServiceTeam,
          ServiceTreeId: plan.ServiceTreeId,
          Ring: plan.Ring,
          Version: plan.Version,
          RegionStatus: this.selectedRegion.Status,
          CreatedTime: plan.CreatedTime,
          CreatedBy: plan.CreatedBy
        };
        rowData.push(row);
      });

      rowData.forEach((row) => {
        row.AlreadyAssigned = "No";

        const ffpPlanRegions = this.ffpPlanRegions.find((p) => p.Region === this.selectedRegion.Region && p.ServiceTreeId === row.ServiceTreeId);
        if (ffpPlanRegions) {
          if (ffpPlanRegions.Version === row.Version) {
            row.AlreadyAssigned = "Yes";
          }
          row.AssignedVersion = ffpPlanRegions.Version;
          row.AssignedBy = ffpPlanRegions.Submitter;
          row.AssignedTime = ffpPlanRegions.SubmitTime;
          row.RegionalPlanStatus = ffpPlanRegions.Status;
        }
      });
      this.rowDataForPlan = rowData;
      this.hasRowSelected = false;
      setTimeout(() => {
        this.gridColumnApi.autoSizeAllColumns();
      }, 100);
      setupFilterByLocalData(this.gridApi, this.gridColumnApi, this.keyFilterStatusOneRegion);
    }
  }

  isAssignable(row: RowDataForPlan): boolean {
    return row.AlreadyAssigned === "No";
  }

  onHideAlreadyAssignedChanged(): void {
    this.gridApi.onFilterChanged();
  }

  async showFfpPlanAssignmentDialog(): Promise<void> {
    try {
      await this.assignFfpPlans("");
    } catch {
      // For the model dialog dismiss
      return;
    }
  }

  async showFfpPlanAssignmentWithPSLDialog(): Promise<void> {
    try {
      const result = await this.modalService.ffpPlanAssignmentModal();
      if (result.psl) {
        await this.assignFfpPlans(result.psl);
      }
    } catch {
      // For the model dialog dismiss
      return;
    }
  }

  async assignFfpPlans(psl: string): Promise<void> {
    let ffpPlanSubmissions: FfpPlanSubmission[];

    if (psl.length > 0) {
      var services = await this.apiService.getServiceTreeIdsByPsl(psl).toPromise();
      ffpPlanSubmissions = services.map((r) => {
        return {
          ServiceTreeId: r,
          Region: this.selectedRegion.Region,
        } as FfpPlanSubmission;
      })
    } else {
      const selectedRows = this.gridApi.getSelectedRows() as RowDataForPlan[];
      ffpPlanSubmissions = selectedRows.map((r) => {
        return {
          ServiceTreeId: r.ServiceTreeId,
          ServiceTeam: r.ServiceTeam,
          Version: r.Version,
          Region: this.selectedRegion.Region,
        } as FfpPlanSubmission;
      });
    }

    await this.modalService.ffpPlanAssignmentResponseModal(ffpPlanSubmissions);
    await this.getLatestRegionalPlans();

    // reload grid
    this.onRegionChanged();
  }

  onSelectionChanged(params: GridOptions): void {
    var rows = params.api.getSelectedRows().length;
    this.hasRowSelected = rows > 0;
    if (!this.hasRowSelected) {
      this.canAssign = false;
    }
    else {
      this.canAssign = true;
    }
  }

  onAssignModeChanged(_params: any): void {
    this.hasRowSelected = false;
  }

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    setTimeout(() => {
      this.gridColumnApi.autoSizeAllColumns();
    }, 100);
  }

  onFilterChangedRegion(event: FilterChangedEvent): void {
    cacheFilterStatsLocal(event, this.keyFilterStatusOneRegion);
  }

  onFilterChangedPlan(event: FilterChangedEvent): void {
    cacheFilterStatsLocal(event, this.keyFilterStatusMultiRegions);
  }
}
