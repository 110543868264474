import { environment } from "../environments/environment";
import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, NgModule, Injectable, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  NgbModalModule,
  NgbDatepickerModule,
  NgbDateParserFormatter,
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"; // ToastrModule has dependency on BrowserAnimationsModule
import "./vendor";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { Observable, from, throwError } from "rxjs";
import { catchError, filter, map, mergeMap, tap } from "rxjs/operators";
import { AgsCoreModule, AgsAuthService, AgsCoreConfig, AgsAppInsightsService } from "@ags/core";
import { ErrorTelemetryService, GlobalErrorHandler } from "./services";
import { LoadingScreenService } from "./shared/loading-screen.service";
import { LoadingScreenComponent } from "./shared/loading-screen/loading-screen.component";
import { ModalService } from "./shared/modal.service";
import { ConfirmationModalComponent } from "./shared/confirmation-modal/confirmation-modal.component";
import { CreateRequestModalComponent } from "./quota/create-request/modals/create-request-modal.component";
import { InformationModalComponent } from "./shared/information-modal/information-modal.component";
import { SelectOptionModalComponent } from "./shared/select-option-modal/select-option-modal.component";
import { SelectOptionAndInputModalComponent } from "./shared/select-option-and-input-modal/select-option-and-input-modal.component";
import { RequestInputModalComponent } from "./shared/request-input-modal/request-input-modal.component";
import { QuotaImplicationsModalComponent } from "./shared/quota-implications-modal/quota-implications-modal.component";
import { DatepickerComponent } from "./shared/datepicker-component/datepicker.component";
import { DateParserFormatter } from "./shared/datepicker-component/date-parser-formatter";

import { AgsCommonUiModule } from "@ags/common-ui";
import { AgGridModule } from "ag-grid-angular";
import { NgSelectModule } from "@ng-select/ng-select";

import { HomeComponent } from "./home/home.component";
// TODO, move all custom-component to a main.explore.ts
import { CustomTooltipComponent } from "./shared/auxillary-components/custom-tooltip.component";
import { CustomPageSizeSettingBarComponent } from "./shared/auxillary-components/custom-pagesize-setting-bar.component";
import { CustomToolingPanel } from "./shared/auxillary-components/custom-tool-panel.component";
import { CreateRequestComponent } from "./quota/create-request/create-request.component";
import { ApproverReviewComponent } from "./quota/approver-review/approver-review.component";
import { RequestDetailComponent } from "./quota/request-detail/request-detail.component";
import { ApproverAdminComponent } from "./quota/approver-admin/approver-admin.component";
import { OperatorReviewComponent } from "./quota/operator-review/operator-review.component";
import { RequestStatusComponent } from "./quota/request-status/request-status.component";
import { ApiService } from "./services";
import { SharedDataService } from "./services/sharedDataService";
import { DataTypes } from "./quota/admin-view/admin-view-data-types";
import { AppInsightService } from "./shared/appinsight.service";
import { SupportInformationComponent } from "./quota/region-information/region-information.component";
import { SubscriptionInfoComponent } from "./quota/subscription-info/subscription-info.component";
import { AdminViewRegionComponent } from "./quota/admin-view/admin-view-region.component";
import { AdminViewEdgeZoneComponent } from "./quota/admin-view/admin-view-edgezone.component";
import { AdminViewUserRoleComponent } from "./quota/admin-view/admin-view-userrole.component";
import { AdminViewAutoApprovalComponent } from "./quota/admin-view/admin-view-autoapprovalrule.component";
import { EditModalComponent } from "./shared/edit-modal/edit-modal.component";
import { ApproverEditModalComponent } from "./shared/approver-edit-modal/approver-edit-modal.component";
import { TicketCommentsModalComponent } from "./shared/ticket-comments-modal/ticket-comments-modal.component";
import { AppModule as BetAppModule } from "./bet/app.module";
import { ActiveParentRequestsComponent } from "./quota/incidents-view/error-requests/active-parentrequests.component";
import { IncompleteWorkItemsComponent } from "./quota/incidents-view/incomplete-tickets/incomplete-workitems.component";
import { WorkitemBulkModalComponent } from "./shared/workitem-bulk-modal/workitem-bulk-modal.component";
import { SubscriptionDetailsModalComponent } from "./shared/subscription-details-modal/subscription-details-modal.component";
import { SubscriptionRendererComponent } from "./quota/approver-review/subscription-renderer.component";
import { CreateRequestResponseModalComponent } from "./quota/create-request/modals/create-request-response-modal.component";
import { ServiceTypeRendererComponent } from "./shared/az-mapping-modal/service-type-renderer.component";
import { AZMappingsModalComponet } from "./shared/az-mapping-modal/az-mappings-modal.component";
import { FilterModalComponent } from "./shared/filter-modal";
import { DatepickerModalComponent } from "./shared/datepicker-modal/datepicker-modal.component";
import { BasicInfoComponent } from "./quota/create-request/components/basic-info.component";
import { DetailsInfoComponent } from "./quota/create-request/components/details-info.component";
import { SummaryInfoComponent } from "./quota/create-request/components/summary-info.component";
import { ActiveSubrequestsComponent } from "./quota/incidents-view/active-subrequests/active-subrequests.component";
import { SubrequestResponseModalComponent } from "./quota/incidents-view/active-subrequests/subrequest-response-modal.component";
import { AdminViewServiceHandlingComponent } from "./quota/admin-view/admin-view-service-handling.component";
import { AdminViewSubscriptionLimitComponent } from "./quota/admin-view/admin-view-subscription-limit.component";
import { AdminViewTableViewComponent } from "./quota/admin-view/admin-view-table-view.component";
import { PromptModalComponent } from "./shared/prompt-modal/prompt-modal.component";
import { GridFilterPillsComponent } from "./shared/grid-filter-pills/grid-filter-pills.component";
import { ParentRequestDetailComponent } from "./quota/parent-request-detail/parent-request-detail.component";
import { PreviousApprovalsModalComponent } from "./shared/previous-approvals-modal/previous-approvals-modal.component";
import { StatusTooltipComponent } from "./quota/request-status/status-tooltip.component";
import { ApproveRequestService } from "./services/approveRequest.service";
import { ValidateRequestModalComponent } from "./quota/create-request/modals/validate-request-modal.component";
import { PlannedQuotaRequestsComponent } from "./quota/planned-quota-requests/planned-quota-requests.component";
import { ParentRequestsResponseModalComponent } from "./quota/incidents-view/error-requests/parentrequests-response-modal.component";
import { HoboSubscriptionAutoApprovalRulesComponent } from "./quota/admin-view/admin-view-hobo-subscription-autoapprovalrule.component";
import { SelfServiceComponent } from "./quota/self-service/self-service.component";
import { RequestsWithTicketsInfoComponent } from "./quota/requests-tickets-info/requestsWithTicketsInfo";
import { AdminViewRegionalQuotaLimitComponent } from "./quota/admin-view/admin-view-regional-quota.component";
import { PlansApprovableComponent } from "./quota/plans/plans-approvable.component";
import { LinkCellComponent } from "./shared/grid/link-cell.component";
import { PlanReviewComponent } from "./quota/plans/plan-review.component";
import { PlanDetailComponent } from "./quota/plans/plan-detail.component";
import { MonacoEditorComponent } from "./shared/monaco-editor/monaco-editor.component";
import { PlanApprovalModalComponent } from "./quota/plans/modals/plan-approval-modal/plan-approval-modal.component";
import { PlanEditorComponent } from "./quota/plans/plan-editor.component";
import { PlanHistoryComponent } from "./quota/plans/plan-history.component";
import { PlanNewFileModalComponent } from "./quota/plans/modals/plan-new-file-modal/plan-new-file-modal.component";
import { PlanEditorModalComponent } from "./quota/plans/modals/plan-editor-modal/plan-editor-modal.component";
import { EditDeleteCellComponent } from "./shared/grid/edit-delete-cell.component";
import { PlansComponent } from "./quota/plans/plans.component";
import { PlanOnboardingModalComponent } from "./quota/plans/modals/plan-onboarding-modal/plan-onboarding-modal.component";
import { PlansAssignmentComponent } from "./quota/plans/plans-assignment.component";
import { PlanAssignmentModalComponent } from "./quota/plans/modals/plan-assignment-modal/plan-assignment-modal.component";
import { ErrorRequestsComponent } from "./quota/incidents-view/error-requests/error-requests.component";
import { AdminViewComponent } from "./quota/admin-view/admin-view.component";
import { PlansMyRegionalPlansComponent } from "./quota/plans/plan-my-regional-plans.component";
import { PlansSignOffComponent } from "./quota/plans/plans-signoff.component";
import { ServiceTeamUserManagement } from "./shared/service-team-user-management/service-team-user-management.component";
import { ArmResourceTypeEnablement } from "./shared/arm-resource-type-details/arm-resource-type-details.component";
import { AddUserComponent } from "./shared/add-user-modal/add-user.component";
import { EmailDetailModalComponent } from "./shared/email-detail-modal/email-detail-modal.component";
import { ViewCellComponent } from "./shared/grid/view-cell.component";
import { PlansRegionalQuotaInformationComponent } from "./quota/plans/plans-regional-quota-information.component";
import { PlanCommitModalComponent } from "./quota/plans/modals/plan-commit-modal/plan-commit-modal.component";
import { PlanHistoryModalComponent } from "./quota/plans/modals/plan-history-modal/plan-history-modal.component";
import { PlansAllRegionalPlansComponent } from "./quota/plans/plan-all-regional-plans.component";
import { TooltipHeaderComponent } from "./shared/grid/tooltip-header.component";
import { DynamicHeaderComponent } from "./shared/grid/dynamic-header.component";
import { CreatePlannedQuotaRequestComponent } from "./quota/planned-quota-requests/create-planned-quota-request.component";
import { PlansBindingsComponent } from "./quota/plans/plan-bindings.component";
import { PlansBindingsSearchComponent } from "./quota/plans/plan-bindings-search.component";
import { PlannedQuotaFulfillmentStatusComponent } from "./quota/plans/plan-status.component";
import { PlannedQuotaRequestDetailComponent } from "./quota/planned-quota-request-detail/planned-quota-request-detail.component";
import { PlannedQuotaRegionStatusComponent } from "./quota/plans/plan-region-status.component";
import { PlanRejectModalComponent } from "./quota/plans/modals/plan-reject-modal/plan-reject-modal.component";
import { PlanBatchApprovalComponent } from "./quota/plans/modals/plan-batch-approval-modal/plan-batch-approval-component";
import { PlanAssignmentResponseModalComponent } from "./quota/plans/modals/plan-assignment-response-modal/plan-assignment-response-modal.component";
import { PlanRemoveModalComponent } from "./quota/plans/modals/plan-remove-modal/plan-remove-modal.component";
import { PlanAssignmentReconfirmModalComponent } from "./quota/plans/modals/plan-assignment-response-modal/plan-assignment-reconfirm-modal.component";
import { PlannedQuotaMyRequestsComponent } from "./quota/planned-quota-requests/planned-quota-my-requests.component";
import { PlansAssignmentServiceOwnerComponent } from "./quota/plans/plans-assignment-service-owner.component";
import { PlanDiffModalComponent } from "./quota/plans/modals/plan-diff-modal/plan-diff-modal.component";
import { MonacoDiffEditorComponent } from "./shared/monaco-diff-editor/monaco-diff-editor.component";
import { PlansInformationVmSizesComponent } from "./quota/plans-information/plans-information-vm-sizes.component";
import { AdminViewEmailsViewComponent } from "./quota/admin-view/admin-view-emails-view.component";
import { PlanCommitResponseModalComponent } from "./quota/plans/modals/plan-commit-response-modal/plan-commit-response-modal.component";
import { AdminViewSubscriptionBindingsComponent } from "./quota/admin-view/admin-view-bindings-sub";
import { AdminViewPropertyBindingsComponent } from "./quota/admin-view/admin-view-bindings-prop";
import { PlannedQuotaRequestConfirmationModal } from "./quota/planned-quota-request-detail/planned-quota-request-confirmation-modal.component";
import { AdminViewCapacityOrderViewComponent } from "./quota/admin-view/admin-view-capacity-order-view.component";
import { CapacityOrderDetailComponent } from "./quota/plans/capacity-order-detail/capacity-order-detail.component";
import { RefreshRendererComponent } from "./quota/admin-view/refresh-renderer.component";
import { AdminViewTeamConfigComponent } from "./quota/admin-view/admin-view-team-config.component";
import { PlanAssignmentCSVModalComponent } from "./quota/plans/modals/plan-assignment-csv-modal/plan-assignment-csv-modal.component";
import { PlansRisksComponent } from "./quota/risks/plans-risks.component";
import { PlanReprocessModalComponent } from "./quota/plans/modals/plan-reprocess-modal/plan-reprocess-modal.component";
import { FfpPlansComponent } from "./quota/ffp/ffp-plans.component";
import { FfpPlanEditorModalComponent } from "./quota/ffp/modals/ffp-plan-editor-modal/ffp-plan-editor-modal.component";
import { FfpPlanEditorComponent } from "./quota/ffp/ffp-plan-editor.component";
import { FfpPlanDiffModalComponent } from "./quota/ffp/modals/plan-diff-modal/plan-diff-modal.component";
import { FfpPlanHistoryModalComponent } from "./quota/ffp/modals/ffp-plan-history-modal/ffp-plan-history-modal.component";
import { FfpPlanHistoryComponent } from "./quota/ffp/ffp-plan-history.component";
import { PfPlanEditorModalComponent } from "./quota/pf/modals/pf-plan-editor-modal/pf-plan-editor-modal.component";
import { PfPlanHistoryModalComponent } from "./quota/pf/modals/pf-plan-history-modal/pf-plan-history-modal.component";
import { PfPlanEditorComponent } from "./quota/pf/pf-plan-editor.component";
import { PfPlanHistoryComponent } from "./quota/pf/pf-plan-history.component";
import { PfPlansComponent } from "./quota/pf/pf-plans.component";
import { PfPlanDiffModalComponent } from "./quota/pf/modals/pf-plan-diff-modal/pf-plan-diff-modal.component";
import { PfPlansApprovableComponent } from "./quota/pf/pf-plans-approvable.component";
import { PfPlanBatchApprovalModalComponent } from "./quota/pf/modals/pf-plan-batch-approval-modal/pf-plan-batch-approval-modal.component";
import { PfPlanReviewComponent } from "./quota/pf/pf-plan-review.component";
import { FfpPlansAssignmentServiceOwnerComponent } from "./quota/ffp/ffp-plans-assignment-service-owner.component";
import { FfpPlanAssignmentResponseModalComponent } from "./quota/ffp/modals/ffp-plan-assignment-response-modal/ffp-plan-assignment-response-modal.component";
import { FfpPlanReviewComponent } from "./quota/ffp/ffp-plan-review.component";
import { FfpPlanAssignmentModalComponent } from "./quota/ffp/modals/ffp-plan-assignment-modal/ffp-plan-assignment-modal.component";
import { FfpPlansAssignmentComponent } from "./quota/ffp/ffp-plans-assignment.component";
import { FfpMyRegionalPlansComponent } from "./quota/ffp/ffp-plan-my-regional-plans.component";
import { FfpRegionBuildoutComponent } from "./quota/ffp/ffp-buildout.component";
import { FfpRegionBuildoutModalComponent } from "./quota/ffp/modals/ffp-region-buildout-modal/ffp-region-buildout-modal.component";
import { FfpBuildoutPlansComponent } from "./quota/ffp/ffp-buildout-plans.component";
import { FfpPlanBatchApprovalComponent } from "./quota/ffp/modals/ffp-plan-batch-approval-modal/ffp-plan-batch-approval-component";
import { CapacityOrderDetailV2Component } from "./quota/plans/capacity-order-detail/capacity-order-detail-v2.component";
import { CapacitySubOrderDetailComponent } from "./quota/plans/capacity-suborder-detail/capacity-suborder-detail.component";

export function agsCoreConfigFactory(): AgsCoreConfig {
  const baseSegs = document.getElementsByTagName("base")[0].href.split("/");
  const origin = baseSegs[0] + "//" + baseSegs[2];
  return {
    appInsightsInstrumentationKey: environment.appInsightsInstrumentationKey,
    clientId: environment.clientId,
    authority: environment.instance + environment.tenantId,
    redirectUri: new URL(environment.siteRootPath + "/aad-callback", origin).href,
    setAuthenticatedUserContext: true,
    defaultScope: environment.backEndScope,
  };
}

@Injectable()
export class LionrockAuthHttpInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AgsAuthService) { }

  private toLogin = false;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken$ = from(this.authService.acquireToken());
    return accessToken$.pipe(
      tap(() => {
        if (this.toLogin) {
          throwError("block useless requests");
        }
      }),
      filter((x) => !!x),
      map((x) => request.clone({ setHeaders: { Authorization: `Bearer ${x}` } })),
      mergeMap((x) => next.handle(x)),
      catchError((e: HttpErrorResponse) => {
        if (e.status === 401) this.loginRedirect();
        return throwError(e);
      })
    );
  }

  private loginRedirect(prompt = "") {
    if (this.toLogin) {
      return;
    }
    if (prompt) {
      alert(prompt);
    }
    this.toLogin = true;
    this.authService.loginRedirect("select_account");
  }
}

@NgModule({
  declarations: [
    ...BetAppModule.declarations,
    ...[
      AppComponent,
      HomeComponent,
      CustomTooltipComponent,
      CustomPageSizeSettingBarComponent,
      CustomToolingPanel,
      CreateRequestComponent,
      ApproverReviewComponent,
      ApproverAdminComponent,
      OperatorReviewComponent,
      RequestStatusComponent,
      RequestDetailComponent,
      LoadingScreenComponent,
      ConfirmationModalComponent,
      CapacitySubOrderDetailComponent,
      CreateRequestResponseModalComponent,
      CreateRequestModalComponent,
      CreatePlannedQuotaRequestComponent,
      ValidateRequestModalComponent,
      SubscriptionInfoComponent,
      SelfServiceComponent,
      InformationModalComponent,
      PromptModalComponent,
      SelectOptionModalComponent,
      DatepickerModalComponent,
      SelectOptionAndInputModalComponent,
      RequestInputModalComponent,
      DatepickerComponent,
      SupportInformationComponent,
      QuotaImplicationsModalComponent,
      AdminViewRegionComponent,
      AdminViewEdgeZoneComponent,
      AdminViewUserRoleComponent,
      AdminViewAutoApprovalComponent,
      AdminViewRegionalQuotaLimitComponent,
      HoboSubscriptionAutoApprovalRulesComponent,
      AdminViewServiceHandlingComponent,
      AdminViewSubscriptionLimitComponent,
      AdminViewTableViewComponent,
      AdminViewCapacityOrderViewComponent,
      AdminViewEmailsViewComponent,
      AdminViewTeamConfigComponent,
      EditModalComponent,
      ApproverEditModalComponent,
      TicketCommentsModalComponent,
      ActiveParentRequestsComponent,
      WorkitemBulkModalComponent,
      IncompleteWorkItemsComponent,
      SubscriptionDetailsModalComponent,
      SubscriptionRendererComponent,
      ServiceTypeRendererComponent,
      AZMappingsModalComponet,
      FilterModalComponent,
      BasicInfoComponent,
      DetailsInfoComponent,
      SummaryInfoComponent,
      ActiveSubrequestsComponent,
      SubrequestResponseModalComponent,
      GridFilterPillsComponent,
      ParentRequestDetailComponent,
      PreviousApprovalsModalComponent,
      StatusTooltipComponent,
      LinkCellComponent,
      ViewCellComponent,
      EditDeleteCellComponent,
      DynamicHeaderComponent,
      TooltipHeaderComponent,
      PlannedQuotaRequestsComponent,
      PlansComponent,
      PlansSignOffComponent,
      PlansAssignmentComponent,
      PlansAssignmentServiceOwnerComponent,
      PlansMyRegionalPlansComponent,
      PlansAllRegionalPlansComponent,
      ServiceTeamUserManagement,
      ArmResourceTypeEnablement,
      PlansApprovableComponent,
      PlanReviewComponent,
      PlanDetailComponent,
      RequestsWithTicketsInfoComponent,
      PlanApprovalModalComponent,
      PlanRejectModalComponent,
      PlanAssignmentModalComponent,
      PlanAssignmentCSVModalComponent,
      PlanAssignmentResponseModalComponent,
      PlanAssignmentReconfirmModalComponent,
      PlanCommitModalComponent,
      PlanHistoryModalComponent,
      PlanReprocessModalComponent,
      PlanEditorComponent,
      PlanHistoryComponent,
      PlanNewFileModalComponent,
      PlanEditorModalComponent,
      PlanDiffModalComponent,
      PlanOnboardingModalComponent,
      PlanRemoveModalComponent,
      PlansRegionalQuotaInformationComponent,
      ParentRequestsResponseModalComponent,
      MonacoEditorComponent,
      MonacoDiffEditorComponent,
      ErrorRequestsComponent,
      AdminViewComponent,
      AddUserComponent,
      EmailDetailModalComponent,
      PlansBindingsComponent,
      PlansBindingsSearchComponent,
      PlannedQuotaFulfillmentStatusComponent,
      PlannedQuotaRequestDetailComponent,
      PlannedQuotaRegionStatusComponent,
      PlanBatchApprovalComponent,
      PlannedQuotaMyRequestsComponent,
      PlannedQuotaRequestConfirmationModal,
      PlansInformationVmSizesComponent,
      PlanCommitResponseModalComponent,
      AdminViewSubscriptionBindingsComponent,
      AdminViewPropertyBindingsComponent,
      CapacityOrderDetailComponent,
      CapacityOrderDetailV2Component,
      RefreshRendererComponent,
      PlansRisksComponent,
      FfpBuildoutPlansComponent,
      FfpPlansAssignmentServiceOwnerComponent,
      FfpPlanAssignmentResponseModalComponent,
      FfpPlanAssignmentModalComponent,
      FfpPlansAssignmentComponent,
      FfpPlanBatchApprovalComponent,
      FfpPlansComponent,
      FfpPlanEditorComponent,
      FfpPlanEditorModalComponent,
      FfpPlanDiffModalComponent,
      FfpPlanHistoryModalComponent,
      FfpPlanHistoryComponent,
      FfpPlanReviewComponent,
      FfpRegionBuildoutComponent,
      FfpRegionBuildoutModalComponent,
      FfpMyRegionalPlansComponent,
      PfPlansComponent,
      PfPlanEditorComponent,
      PfPlanEditorModalComponent,
      PfPlanDiffModalComponent,
      PfPlanHistoryModalComponent,
      PfPlanHistoryComponent,
      PfPlansApprovableComponent,
      PfPlanReviewComponent,
      PfPlanBatchApprovalModalComponent,
    ],
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    MatProgressBarModule,
    FormsModule,
    NgbModalModule,
    NgbDatepickerModule,
    AgsCommonUiModule.forRoot({
      webApp: "lionrock",
      configUrl: environment.agsConfigUrl,
      // notificationsScope: null, // TODO: enable notification later(should use access token)
      homePageApi: environment.homePageApi,
    }),
    AgsCoreModule.forRoot(agsCoreConfigFactory, false),
    AgGridModule.withComponents([
      SubscriptionRendererComponent,
      ServiceTypeRendererComponent,
      CustomTooltipComponent,
      CustomPageSizeSettingBarComponent,
      CustomToolingPanel,
      StatusTooltipComponent,
      LinkCellComponent,
      ViewCellComponent,
      EditDeleteCellComponent,
      DynamicHeaderComponent,
      TooltipHeaderComponent,
    ]),
    NgSelectModule,
    ToastrModule.forRoot({
      closeButton: true,
      positionClass: "toast-top-full-width",
      preventDuplicates: true,
    }),
    BrowserAnimationsModule,
    NgbModule,
    NgbTooltipModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LionrockAuthHttpInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: DateParserFormatter },
    ApiService,
    DataTypes,
    ErrorTelemetryService,
    GlobalErrorHandler,
    LoadingScreenService,
    ModalService,
    AppInsightService,
    ApproveRequestService,
    SharedDataService,
    { provide: AgsAppInsightsService, useClass: AppInsightService },
  ],
  entryComponents: [
    ...BetAppModule.entryComponents,
    ...[
      ConfirmationModalComponent,
      CreateRequestModalComponent,
      ValidateRequestModalComponent,
      InformationModalComponent,
      PromptModalComponent,
      SelectOptionModalComponent,
      DatepickerModalComponent,
      SelectOptionAndInputModalComponent,
      RequestInputModalComponent,
      QuotaImplicationsModalComponent,
      EditModalComponent,
      ApproverEditModalComponent,
      TicketCommentsModalComponent,
      WorkitemBulkModalComponent,
      SubscriptionDetailsModalComponent,
      CreateRequestResponseModalComponent,
      AZMappingsModalComponet,
      FilterModalComponent,
      SubrequestResponseModalComponent,
      PreviousApprovalsModalComponent,
      PlanApprovalModalComponent,
      PlanRejectModalComponent,
      PlanAssignmentModalComponent,
      PlanAssignmentCSVModalComponent,
      PlanAssignmentResponseModalComponent,
      PlanAssignmentReconfirmModalComponent,
      PlanReprocessModalComponent,
      PlanEditorComponent,
      PlanHistoryComponent,
      PlanNewFileModalComponent,
      PlanEditorModalComponent,
      PlanDiffModalComponent,
      PlanOnboardingModalComponent,
      PlanRemoveModalComponent,
      PlanCommitModalComponent,
      PlanHistoryModalComponent,
      ParentRequestsResponseModalComponent,
      PlannedQuotaRequestConfirmationModal,
      PlanBatchApprovalComponent,
      FfpBuildoutPlansComponent,
      FfpPlanAssignmentModalComponent,
      FfpPlanBatchApprovalComponent,
      FfpPlansAssignmentComponent,
      FfpPlansComponent,
      FfpPlanEditorComponent,
      FfpPlanEditorModalComponent,
      FfpPlanDiffModalComponent,
      FfpPlanHistoryModalComponent,
      FfpPlanHistoryComponent,
      FfpRegionBuildoutComponent,
      FfpRegionBuildoutModalComponent,
      PfPlansComponent,
      PfPlanEditorComponent,
      PfPlanEditorModalComponent,
      PfPlanDiffModalComponent,
      PfPlanHistoryModalComponent,
      PfPlanHistoryComponent,
      PfPlanBatchApprovalModalComponent,
    ],
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
